<template>
  <div id="bullfight">
    <div class="bull_sec_1">
      <img class="bull_main" src="../assets/img/bullfight/bull_main.gif" alt="">
      <img class="bull_main_mo" src="../assets/img/bullfight/bull_main_mo.gif" alt="">
    </div>
    <div class="bull_wrap">
      <div id="bull_infi_lol">
      <div class="bull_infi_img">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol1.png" alt="Image 1">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol2.png" alt="Image 2">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol3.png" alt="Image 3">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol4.png" alt="Image 4">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol5.png" alt="Image 5">
        <!--infi-->
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol1.png" alt="Image 1">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol2.png" alt="Image 2">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol3.png" alt="Image 3">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol4.png" alt="Image 4">
        <img class="bull_infi" src="../assets/img/bullfight/bull_lol5.png" alt="Image 5">
      </div>
    </div>
    <div class="bull_align">
      <img class="bull_img_center" src="../assets/img/bullfight/123123.png" alt="">
      <img class="cowname" src="../assets/img/bullfight/cowname.png" alt="">
    </div>
    <!-------------->
    <div class="bsu-container">
    <div class="bsu-row">
        <img class="bsu-image" src="../assets/img/bullfight/cow1.png" data-title="신리키베이비" data-content="성적: 헤비급 챔피언, 13전 전승 2회 방어.소 주인: 히라타 히토미(이시카와 투우조합) 980kg.헤비급 챔피언 13전 전승 2회 방어.신예 Baby는 오키나와 투우계에서 주목받는 헤비급 챔피언이다. 데뷔 이후 13전 전승이라는 무패 기록을 자랑하며, 특히 2023년 11월의 전섬 투우대회에서는 실력파 버블과의 왕좌 결정전에서 단 6분 21초 만에 승리하며 당당히 챔피언의 자리에 올랐다.2024년 5월에는 체중 1300kg의 거구를 자랑하는 귀화대수왕과의 방어전에서 300kg의 체중 차이를 극복하고 단 17초 만에 압도적인 승리를 거두며 그 막강한 힘을 증명했다. 신리키베이비의 주인인 히타카 히토미 씨는 번식농가의 남편으로부터 생일 선물로 신리키베이비를 물려받은 후 애정을 쏟아 부어 키우고 있다. 신리키베이비의 특징은 경기 시작 직후부터 전력을 다해 공격하는 '스피드'와 '파워'이다. 체격차나 체급 차이를 불문하고 상대를 단숨에 밀어붙이는 그 투지는 오키나와 투우계를 대표하는 실력자로서 많은 팬들을 매료시키고 있습니다. 또한, 신리키 Baby의 성격은 매우 온화하고 온화하여 관광 투우 사진 촬영에서도 그 매력을 발휘하여 관중들에게도 사랑받고 있습니다. 앞으로 더욱 주목해야 할 '신리키 베이비'. 다음 경기에서는 또 어떤 전설을 써내려갈지 기대가 더욱 커진다! 다음 경기를 가까이에서 응원해주시길 바랍니다!">
        <img class="bsu-image" src="../assets/img/bullfight/cow2.png" data-title="귀화대주왕" data-content="소의 주인: 이바 모리아키(이바 투우조합) 1,300kg. 오키나와 최고 중량급인 1,300kg의 압도적인 체격을 자랑하는 '귀화대수왕'은 그 이름에 걸맞는 거한소입니다. 산지는 이와테에서 태어나자마자 오키나와로 거래되어 오키나와 투우계에 일찌감치 두각을 나타냈습니다. 그 거대한 몸집과 힘으로 데뷔 초기부터 주목을 받으며 오키나와 투우 팬들을 매료시켜 왔습니다. 과거에는 헤비급 타이틀 매치에 두 번 도전해 투세 앰버, 신예 베이비와 격투를 벌였지만 아쉽게도 왕좌에 오르지 못했습니다. 그럼에도 불구하고 그 강인함과 투지는 결코 식지 않아 항상 주목의 대상이 되고 있다. 체급 차이로 인한 싸움은 보는 이들을 압도하며 관객들에게 강한 인상을 남겼다. 귀화대수왕의 특징은 역시 거대한 몸집에 걸맞은 압도적인 힘이다. 상대를 압도하는 그 힘은 다른 소들을 단숨에 밀어붙일 만큼 그 존재감은 단연 독보적이다. 하지만 그 거대한 몸집에 안주하지 않고 재빠른 움직임과 교묘한 전술을 보여주기도 해, 힘에만 의존하지 않는 그의 투지에 매료된 팬들도 많다. 아직 왕좌에는 오르지 못했지만, 그 잠재력과 앞으로의 성장에 대한 기대가 커지는 '귀화대마왕'. 다음 경기에서는 어떤 경기를 보여줄지 귀화대수왕의 행보에 귀추가 주목된다.">
    </div>
    <div class="bsu-row">
        <img class="bsu-image" src="../assets/img/bullfight/cow3.png" data-title="제2대 비아오수왕" data-content="성적: 중량급 챔피언 7전 전승.소 주인: 당산 비아오인(남부 투우조합) 950kg.2024년 11월에 심비화형(心美花形)을 꺾고 중체급 챔피언에 등극하여 5세 1개월의 나이로 중체급 역사상 최연소 챔피언이 된 비아아오수왕(彪獣王)은 그 힘과 무패 기록으로 점점 더 많은 팬들을 매료시키고 있습니다. 팬들을 매료시키고 있습니다.2023년 5월에 데뷔해 6전 전승의 전적을 자랑하며 모든 경기를 승리로 장식했다. 데뷔 전, 2021년 투우 후보 심사위원회에서 최고 등급인 우등소로 선정되며 그 재능을 인정받았다. 아직 어린 나이지만, 그의 힘과 침착한 투우 방식은 관중을 끌어당기는 매력이 있다. 앞으로도 계속 성장하는 '2대 비아아오수왕'의 경기는 계속 지켜봐야 할 것이다. 다음 경기에서는 어떤 퍼포먼스를 보여줄지 기대가 된다. 그의 앞으로의 활약에 많은 응원 부탁드립니다!">
        <img class="bsu-image" src="../assets/img/bullfight/cow4.png" data-title="투세코박" data-content="소 주인: 투우회(이시카와 투우조합) 1,050kg.오키나와의 중량급 챔피언 '투세 호박'은 그 실력과 힘으로 오키나와 투우계를 대표하는 명우로, 2021년 5월 이글왕과의 챔피언 결정전에서 승리하며 오키나와 제일의 타이틀을 거머쥐었다. 그 후 강적들을 차례로 격파하며 3번의 방어에 성공했습니다. 이로써 오키나와 투우계에서는 그 이름을 떨치는 존재가 되었다.또한 2023년에는 도쿠노시마 챔피언 '우시와카 아카마루기노완호'와의 일본 제일 결정전에서 11분 48초라는 장렬한 싸움 끝에 승리하여 일본 제일의 칭호를 획득했다. 그 후, 도쿠노시마의 주인에게 인도되어 도쿠노시마에서도 챔피언에 오르는 위업을 달성하여 오키나와와 도쿠노시마에서 모두 타이틀을 획득한 명소가 되었습니다.사실 투세 앰버는 현 중량급 챔피언 '신리키 베이비'와 이복형제로, 형제 모두 오키나와 투우계의 강자임을 증명하고 있다. 그 혈통에는 남다른 힘과 투지(闘志)가 이어져, 형제는 각자 다른 길을 걷고 있지만 투우계에서 함께 명성을 떨치고 있습니다.이후 도쿠노시마에서의 첫 방어전에서 패배한 후, 현재는 오키나와로 돌아와 재기의 기회를 노리고 있다. 투세 앰버가 가진 압도적인 힘과 투지는 아직 식지 않았으며, 다음번 전투에서 다시 한 번 그 실력을 입증할 것으로 기대된다.">
        <img class="bsu-image" src="../assets/img/bullfight/cow5.png" data-title="신짱" data-content="실적: 12연승 중 2회 방어.소의 주인: 쿠보타 리키히토・쿠보타 카이쿠(이시카와 투우조합) 810kg 경량급 챔피언 '신짱'은 오키나와 투우계에서 스피드와 기술을 구사하는 싸움으로 많은 팬들에게 사랑받고 있는 소이다. 별명은 '스피드 스타'. 그 이름처럼 경기에서는 압도적인 스피드를 자랑하며, 최근 경기는 대부분 1분대에 승리하는 등, 그 스피드로 상대를 압도하고 있다.신짱은 12연승이라는 놀라운 기록을 가지고 있으며, 2번의 방어에 성공해 안정된 실력과 힘으로 경량급을 대표하는 존재가 되었다. 2025년이면 12살을 맞이하는 베테랑 소이지만, 나이를 무색하게 하는 기세와 투지를 보여주며 여전히 투우계에서 활약하고 있다.또한, 신짱은 사랑스러운 성격으로 유명해 가족이나 아이들에게도 큰 사랑을 받고 있다. 팬들 사이에서는 '귀엽고 강하다'는 그 갭이 매력적이며, 경기 후 보여주는 다정한 모습에 팬들이 힐링을 받고 있다. 앞으로도 '신짱'의 싸움에서 눈을 뗄 수 없다. 스피드와 경험을 무기로 다음 도전에 도전하는 모습을 계속 응원해 보자!">
    </div>
</div>

<div class="bsu-overlay" id="bsu-overlay"></div>
<div class="bsu-popup" id="bsu-popup" style="box-sizing: border-box;">
    <h2 id="bsu-popup-title" style="font-weight: bold;"></h2>
    <br>
    <p id="bsu-popup-content"></p>
    <br>
    <button id="bsu-close-btn">닫기</button>
</div>
<!-------------->

    <div class="bull_time">
      <img class="bull_time1" src="../assets/img/bullfight/bull_time.gif" alt="">
      <img class="bull_time2" src="../assets/img/bullfight/bull_time2.gif" alt="">
    </div>
    <div class="bull_last">
      <img src="../assets/img/bullfight/ssad.png" alt="">
    </div>
  </div>
  <div class="bull_white">
    <div class="bull_place">
      <img src="../assets/img/bullfight/place.png" alt="">
    </div>
    <div class="bull_reser_button">
      <a href="javascript:void(0)" @click="goToDetail('L-20241230-1735524054398','L')">예약하기</a>
    </div>
  </div>
  </div>
    
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    this.chkSize = window.innerWidth
    //alert(this.chkSize) 화면 사이즈 체크
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }   
  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
    
  },
  mounted() {
    const images = document.querySelectorAll('.bsu-image');
    const popup = document.getElementById('bsu-popup');
    const overlay = document.getElementById('bsu-overlay');
    const title = document.getElementById('bsu-popup-title');
    const content = document.getElementById('bsu-popup-content');
    const closeBtn = document.getElementById('bsu-close-btn');

    images.forEach(image => {
        image.addEventListener('click', () => {
            title.textContent = image.getAttribute('data-title');
            content.textContent = image.getAttribute('data-content');
            popup.style.display = 'block';
            overlay.style.display = 'block';
        });
    });

    closeBtn.addEventListener('click', () => {
        popup.style.display = 'none';
        overlay.style.display = 'none';
    });

    overlay.addEventListener('click', () => {
        popup.style.display = 'none';
        overlay.style.display = 'none';
    });
}}

</script>

<style>

#bullfight{
  margin-top: -56px;
}

.bull_main{
  width: 100%;
}
.bull_main_mo{
  display: none;
  width: 100%;
}
.bull_wrap{
  padding-top: 80px;
  padding-bottom: 100px;
  background: url(../assets/img/bullfight/bull_bg.jpg)no-repeat ;
  background-size: 100%;
  background-position: 0 -300px !important;
}
.bull_white{
  background: url(../assets/img/bullfight/bull_bg2.jpg)top no-repeat !important;
  padding-top: 80px;
  padding-bottom: 100px;

}
#bull_infi_lol{
  position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
}
.bull_infi_img{
  display: flex;
    position: absolute;
    animation: slide 20s linear infinite;
}
.bull_infi{
  width: 500px; /* 화면 너비에 맞춤 */
  height: auto;
}

@keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } /* 원래 이미지의 절반만 이동 */
}
.bull_align{
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.bull_img_center{
  margin-bottom: 150px;
}
.bsu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 200px;
}
.bsu-row {
  display: flex;
  justify-content: center;
}
.bsu-image {
 margin: 10px;
 cursor: pointer;
 width: 390px; /* 이미지 크기 조정 */
 height: auto; /* 이미지 크기 조정 */
}
.bsu-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px 30px;
  z-index: 1000;
  border-radius: 15px;
  
}
.bsu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
#bsu-close-btn{
            padding: 10px 40px;
            background-color: #9e1600; /* 버튼 배경색 */
            color: white; /* 버튼 글자색 */
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px; /* 버튼 글자 크기 */
            position: relative; /* 위치 조정 */
            top: 10px; /* 위쪽 위치 조정 */
            left: 50%;
            right: 50%;
            transform: translate(-50%,-50%);
        }
.bull_time{
  width: 100%;
}
.bull_time2{
  display:none;
}
.bull_time img{
  width: 100%;
}
.bull_last{
  width: 1200px;
  margin: 300px auto 0 auto;
  padding: 0px 0 200px 0px;
}
.bull_place{
  width: 1218px;
  margin: 200px auto 0 auto;
}
.bull_reser_button{
  width: 400px;
  height: 100px;
  margin: 0 auto 100px auto;
  background-color: #9e1600;
  border-radius: 17px;

}
.bull_reser_button >a{
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  padding-top: 28px;
  color: #fff;
  font-size: 25pt;
  font-weight: bold;
}
@media (max-width:1920px) and (min-width:1024px){
  .bull_wrap{
    background-position:50% -800px  !important;
    background-size: 115%;
    background: cover;
}
.bull_align{
  margin-top: 50px;
}
.bull_img_center{
  margin-bottom: 50px;
}
#bull_infi_lol{
  position: relative;
    width: 100%;
    height: 56vh;
    overflow: hidden;
}
.bull_place{
 width: 1000px;
 margin: 250px auto 0 auto;
}
.bull_place img{
  width: 100%;
}
.bull_last{
  width: 1000px;
  margin: 150px auto 0 auto;
  padding: 0px 0 150px 0px;
}
.bull_last img{
  width: 100%;
}
.bull_align{
  width: 1000px;
  margin: 0 auto;
  text-align: center;
}
.bull_align img{
  width: 100%;
}
.cowname{
  width:100% !important;
}
.bsu-image {
 margin: 10px;
 cursor: pointer;
 width: 300px; /* 이미지 크기 조정 */
}
}
@media (max-width:1023px) and (min-width:777px){
  .bull_white{
  padding-top: 80;
  padding-bottom: 0;

}
  .bull_align{
    width: 100%;
  }
  .bull_img_center{
    width: 80%;
  }
  .cowname{
    width: 80%;
  }
  .bsu-container{
    width: 100%;
  }
  .bsu-row{
    width: 80%;
  }
  .bsu-row img{
    width: 30%;
  }
  .bull_last{
    width: 100%;
    text-align: center;
    margin: 100px auto 50px auto;
  }
  .bull_last img{
    width: 80%;
  }
  .bull_place{
    width: 100%;
    text-align: center;
    margin: 200px auto 50px auto;
  }
  .bull_place img{
    width: 80%;
  }
  .bull_reser_button{
  width: 200px;
  height: 70px;
  margin: 0 auto 100px auto;
  background-color: #9e1600;
  border-radius: 12px;

}
.bull_reser_button >a{
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  padding-top: 20px;
  color: #fff;
  font-size: 15pt;
  font-weight: bold;
}
.bull_wrap{
  padding-top: 80px;
  padding-bottom: 100px;
  background: url(../assets/img/bullfight/bull_bg.jpg)no-repeat ;
  background-size: 190%;
  background-position: 50% -650px !important;
}
}
@media (max-width:776px) and (min-width:300px){
  .bsu-popup{
    width: 98%;
  font-size: 13px;
  }
  .bull_main{
  display: none;
}
.bull_main_mo{
  width: 100%;
  display: block;
}

  .bull_align{
    width: 100%;
  }
  .bull_img_center{
    width: 90%;
  }
  .cowname{
    width: 90%;
  }
  .bull_wrap{
    padding-top: 30px;
  background: url(../assets/img/bullfight/bull_bg.jpg)no-repeat ;
  background-size: 190%;
  background-position: 50% -200px !important;
  padding-bottom: 30px;
  }
  #bull_infi_lol{
    height: 15vh;
  }
  .bull_infi{
    width: 100px;
  }
  .bull_img_center{
    margin-bottom: 50px;
  }
  .bsu-container{
    width: 100%;
  }
  .bsu-row{
    width: 80%;
  }
  .bsu-row img{
    width: 30%;
  }
  .bull_last{
    width: 100%;
    text-align: center;
    margin: 100px auto 50px auto;
    padding: 0;
  }
  .bull_last img{
    width: 80%;
  }
  .bull_place{
    width: 100%;
    text-align: center;
    margin: 200px auto 50px auto;
  }
  .bull_place img{
    width: 80%;
  }
  .bull_reser_button{
  width: 200px;
  height: 70px;
  margin: 0 auto 100px auto;
  background-color: #9e1600;
  border-radius: 12px;

}
.bull_reser_button >a{
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  padding-top: 20px;
  color: #fff;
  font-size: 15pt;
  font-weight: bold;
}
.bsu-container{
  margin-bottom: 50px;
}
#bullfight{
  margin-top: 0;
}
.bull_time1{
  display: none;
}
.bull_time2{
  display:block;
  width: 100%;
}
}
</style>

