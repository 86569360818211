<template>
  <div id="Marukoma_bsu">
    <div class="bsu_slider">
    <img class="bsu_marukoma_title_img" src="../assets/img/marukoma/Marukoma_title.png" alt="">
    <div class="bsu_slides">
        <img class="bsu_slide" src="../assets/img/marukoma/main_img1.jpg" alt="Image 1">
        <img class="bsu_slide" src="../assets/img/marukoma/main_img2.jpg" alt="Image 2">
        <img class="bsu_slide" src="../assets/img/marukoma/main_img3.jpg" alt="Image 3">
    </div>
</div>


<div class="bsu_marukoma_body_bg">
  <div class="marukoma_var">
    <img src="../assets/img/marukoma/var.jpg" alt="">
  </div>

  <!----------------video---------->
  
  <div id="marukoma_video_bg">
    <video playsinline autoplay muted loop style="border-radius: 15px;">
        <source src="../assets/img/marukoma/marukoma_video.mp4" type="video/mp4">
    </video>
    <img src="../assets/img/marukoma/video_title.png" alt="Background Image">
</div>
  

 
  
  <!----------------video---------->
  <div class="marukoma_var">
    <img src="../assets/img/marukoma/var.jpg" alt="">
  </div>
<div class="bsu_marukoma_productimg">
<img src="../assets/img/marukoma/experience.png" alt="">
</div>
<div class="marukoma_var">
    <img src="../assets/img/marukoma/var.jpg" alt="">
  </div>
  <div class="bsu_marukoma_productimg_product">
<img src="../assets/img/marukoma/product.jpg" alt="">
</div>
<div class="marukoma_var">
    <img src="../assets/img/marukoma/var.jpg" alt="">
  </div>
  <a class="marukoma_button" href="javascript:void(0) " @click="goToDetail('L-24111959ZCC','L')">신청하기</a>
</div>


  </div>
</template>

<script>

import defaultPath from '@/assets/img/taiwan/back.jpg';

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      imagePath: defaultPath,
      animateImage: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
  mounted() {
    let currentIndex = 0;
    const slides = document.querySelectorAll('.bsu_slide');
    const totalSlides = slides.length;

    function showSlide(index) {
      slides.forEach((slide, i) => {
        slide.classList.remove('bsu_active');
        if (i === index) 
        {
          slide.classList.add('bsu_active');
        }
      });
    }
    
    function nextSlide() {
      currentIndex = (currentIndex + 1) % totalSlides;
      showSlide(currentIndex);
    }
    
    showSlide(currentIndex);
    setInterval(nextSlide, 5000);
  },

  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  }
}

</script>

<style>
#Marukoma_bsu{
  margin-top: -56px;
}

.bsu_marukoma_title_img{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%,-50%);
  width: 12%;
  height: auto;
}

.bsu_slider {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 1300px;
    }
    .bsu_slides {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .bsu_slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
    .bsu_active {
        opacity: 1;
    }
    .bsu_marukoma_productimg{
      width: 100%;
      display: flex;
      justify-content: center;
      background: url(../assets/img/marukoma/maru_bg.png)no-repeat center;
      background-size: 100%;
      margin: 0 auto;
      height: auto;
    }
    .bsu_marukoma_productimg img{
      width: 40%;
      
    }
.marukoma_button{
display: block;
margin: 50px auto;
width: 300px;
height: 70px;
background-color: rgba(248, 248, 248, 1);
text-align: center;
padding-top: 20px;
font-size: large;
font-weight: bold;
border-radius: 30px;
border: rgba(6, 58, 83, 1)1px solid; 
transition: all 1s;
}
.marukoma_button:hover{
  border: rgb(125, 127, 128) 1px solid;
  background-color: rgba(6, 58, 83, 1);
  color: #fff;
  transition: all 1s;
}
.marukoma_var{
  overflow: auto;
  display:block;
}
.marukoma_var img{
  width: 100%;
}

#marukoma_video_bg {
    position: relative;
    width: 100%; /* 부모 요소에 맞게 너비 조정 */ /* 최대 너비 설정 */
    height: 1100px; /* 높이는 비율에 따라 자동 조정 */
    overflow: hidden; /* 내용이 넘칠 경우 숨김 */
    background: url(../assets/img/marukoma/video_bg.jpg)center no-repeat;
}

#marukoma_video_bg img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 30%; /* 이미지가 부모 요소에 맞게 조정 */
    height: auto; /* 비율 유지 */
}

#marukoma_video_bg video {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
    width: 800px; /* 비디오가 부모 요소에 맞게 조정 */
    height: 455px; /* 비율 유지 */
    z-index: 0; /* 이미지 아래에 표시 */
    object-fit: cover; /* 비디오가 부모 요소를 가득 채우도록 설정 */
    z-index: 1;
}
.bsu_marukoma_productimg_product{
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      height: auto;
    }
    .bsu_marukoma_productimg_product img{
      width: 40%;
      
    }
@media (min-width:768px) and (max-width:1920px){
  #marukoma_video_bg img {
    width: 40%; /* 이미지가 부모 요소에 맞게 조정 */
}
.bsu_marukoma_title_img{
  width: 13%;
}
.bsu_marukoma_productimg img{
      width: 50%;
}
.bsu_marukoma_productimg{
      background-size: 110%;
}
}



/*-----------------------------------------------------------mobile---------------------------------------------*/



@media (max-width:767px){ 
  .bsu_marukoma_title_img {
    width: 20%;
    top: 55%;
  }

.bsu_slider {
        width: 100%;
        height: 500px;
    }
    .bsu_slides {
        position: absolute;
        width: 100%;
        height: 500px;
    }
    .bsu_slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
    .bsu_active {
        opacity: 1;
    }
    .bsu_marukoma_productimg{
      width: 100%;
      display: flex;
      justify-content: center;
      background: url(../assets/img/marukoma/maru_bg.png)no-repeat center;
      margin: 0 auto;
      height: 100%;
    }
    .bsu_marukoma_productimg img{
      width: 90%;
      
    }
.marukoma_button{
display: block;
margin: 50px auto;
width: 150px;
height: 50px;
background-color: rgba(248, 248, 248, 1);
text-align: center;
padding-top: 15px;
font-size: 15px;
font-weight: bold;
border-radius: 15px;
border: rgba(6, 58, 83, 1)1px solid; 
transition: all 1s;
}
.marukoma_button:hover{
  border: rgb(125, 127, 128) 1px solid;
  background-color: rgba(6, 58, 83, 1);
  color: #fff;
  transition: all 1s;
}
.marukoma_var{
  overflow: auto;
  display:block;
  height: 70px;
}
.marukoma_var img{
  width: 100%;
  height: 100%;
}

#marukoma_video_bg {
    position: relative;
    width: 100%; /* 부모 요소에 맞게 너비 조정 */ /* 최대 너비 설정 */
    height: 500px; /* 높이는 비율에 따라 자동 조정 */
    overflow: hidden; /* 내용이 넘칠 경우 숨김 */
    background: url(../assets/img/marukoma/video_bg.jpg)center no-repeat;
}

#marukoma_video_bg img {
    width: 86%; /* 이미지가 부모 요소에 맞게 조정 */
    height: auto; /* 비율 유지 */
}

#marukoma_video_bg video {
    width: 90%; /* 비디오가 부모 요소에 맞게 조정 */
    height: auto; /* 비율 유지 */
}
.bsu_marukoma_productimg_product{
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      height: auto;
    }
    .bsu_marukoma_productimg_product img{
      width: 90%;
      
    }
}
</style>

