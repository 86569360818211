
<template>

  <div id="wg_blog_page">
    <!-- main -->
      <div class="wg_slider">
      <div class="wg_main_tit">
        <p class="wg_sub_tit">여행 인플루언서 <strong>와그잡, 곤과 여행 전문 매거진 피치 바이 피치</strong>가 함께한</p>
        <p class="wg_sub_tit2">일본 소도시 렌터카 추천여행</p>
        <strong class="yko_tit">야마구치&#183;기타큐슈&#183;오이타</strong>
        <p class="wg_sub_tit3">A코스: 와그잡 님의 여자들만의 여행 / B코스: 곤 님의 인스타 인생샷 명소 여행</p>
        <img class="mtit_mo_767" src="../assets/img/Bloggertrip/767size.png" alt="">
        <img src="../assets/img/Bloggertrip/car.png" alt="">
      </div><!-- wg_main_tit -->
    <div class="wg_slides">
        <img class="wg_slide" src="../assets/img/Bloggertrip/mainback1.jpg" alt="Image 1">
        <img class="wg_slide" src="../assets/img/Bloggertrip/mainback2.jpg" alt="Image 2">
        <img class="wg_slide" src="../assets/img/Bloggertrip/mainback3.jpg" alt="Image 3">
    </div>
      </div><!-- wg_slider -->

    <!-- reason -->
      <div class="wg_reason">
        <p>일본 <span>렌트카 여행</span>을 <br> 추천하는 이유</p>
        <div class="reason_img">
          <div class="reasonlist1">
            <img src="../assets/img/Bloggertrip/reason1.png" alt="">
            <img src="../assets/img/Bloggertrip/reason2.png" alt="">
          </div>
          <div class="reasonlist2">
          <img class="rentg" src="../assets/img/Bloggertrip/reason3.png" alt="">
          <img src="../assets/img/Bloggertrip/reason4.png" alt="">
        </div>
        <img class="rs_mo" src="../assets/img/Bloggertrip/reason5.png" alt="">
        </div>
      </div><!-- wg_reason -->

    <!-- yko hotspot -->
      <div class="wg_yko_spot">
        <img class="wg_yko_mtit" src="../assets/img/Bloggertrip/spotcam.png" alt="">
        <div class="yko_spot_img">
          <img class="yko_spot1" src="../assets/img/Bloggertrip/spot1.png" alt="">
          <img class="yko_spot2" src="../assets/img/Bloggertrip/spot2.png" alt="">
          <img class="yko_spot3" src="../assets/img/Bloggertrip/spot4.png" alt="">
          <img class="yko_spot4" src="../assets/img/Bloggertrip/spot5.png" alt="">
          <img class="yko_spot5" src="../assets/img/Bloggertrip/spot7.png" alt="">
        </div>
      </div><!-- wg_yko_spot -->

    <!-- A/B course -->

      <!-- 타이틀 -->
      <div class="wg_ab_cs_tit">
        <img src="../assets/img/Bloggertrip/A_pointname.png" alt="">
      </div>

      <div class="wg_ab_course">
          <!-- 탭메뉴 -->          

            <div class="ab_menubar_hj">
              <div class="cs_tab-menu">
                <button class="cs_tab-button active" data-tab="tab1">A코스 <br> <span>와그잡 님의 여자들만의 여행</span></button>
                <button class="cs_tab-button" data-tab="tab2">B코스 <br> <span>곤 님의 인스타그램 인생샷 명소</span></button>
              </div><!-- cs_tab-menu -->
            </div> <!-- ab_menubar_hj -->

            <div class="ab_cs_tabcont">
              <div class="cs-tab-panel active" id="tab1">
                <div class="acs_spot">
                  <p>코스 별 스팟</p>
                  <div class="acs_spot_img">
                    <div class="apoint1">
                      <img src="../assets/img/Bloggertrip/Apoint1.png" alt="">
                      <img src="../assets/img/Bloggertrip/Apoint2.png" alt="">
                    </div>
                    <div class="apoint2">
                      <img src="../assets/img/Bloggertrip/Apoint3.png" alt="">
                      <img src="../assets/img/Bloggertrip/Apoint4.png" alt="">
                    </div>
                    <div class="apoint3">
                      <img src="../assets/img/Bloggertrip/Apoint5.png" alt="">
                      <img src="../assets/img/Bloggertrip/Apoint6.png" alt="">
                    </div>
                    <img class="apointlast" src="../assets/img/Bloggertrip/Apoint7.png" alt="">
                  </div><!-- acs_spot_img -->
                </div><!-- acs_spot -->
                <div class="aplanname">
                  <img src="../assets/img/Bloggertrip/A_planname.png" alt="">
                </div><!-- aplanname -->
                <div class="acs_plan">
                  <div class="acs_ezplan_img">
                    <img src="../assets/img/Bloggertrip/A_ezplan1.png" alt="">
                    <img src="../assets/img/Bloggertrip/A_ezplan2.png" alt="">
                    <img src="../assets/img/Bloggertrip/A_ezplan3.png" alt="">
                    <img src="../assets/img/Bloggertrip/A_ezplan4.png" alt="">
                  </div><!-- acs_ezplan_img -->
                  <button class="wg-show-btn">A코스 여심저격 상세일정 자세히 보기</button>
                  <div class="hdplan">
                    <div class="hdplan_img">
                    <img src="../assets/img/Bloggertrip/A_hdplan1.png" alt="">
                  </div>
                  </div><!-- hdplan -->
                </div><!-- acs_plan -->
              </div>

              <div class="cs-tab-panel" id="tab2">
                <div class="bcs_spot">
                  <p>코스 별 스팟</p>
                  <div class="bcs_spot_img">
                    <div class="bpoint1">
                      <img src="../assets/img/Bloggertrip/Bpoint1.png" alt="">
                      <img src="../assets/img/Bloggertrip/Bpoint2.png" alt="">
                    </div>
                    <div class="bpoint2">
                      <img src="../assets/img/Bloggertrip/Bpoint3.png" alt="">
                      <img src="../assets/img/Bloggertrip/Bpoint4.png" alt="">
                    </div>
                    <div class="bpoint3">
                      <img src="../assets/img/Bloggertrip/Bpoint5.png" alt="">
                      <img src="../assets/img/Bloggertrip/Bpoint6.png" alt="">
                    </div>
                    <img class="bpointlast" src="../assets/img/Bloggertrip/Bpoint7.png" alt="">
                  </div><!-- bcs_spot_img -->
                </div><!-- bcs_spot -->
                <div class="bplanname">
                  <img src="../assets/img/Bloggertrip/B_planname.png" alt="">
                </div><!-- bplanname -->
                <div class="bcs_plan">
                  <div class="bcs_ezplan_img">
                    <img src="../assets/img/Bloggertrip/B_ezplan1.png" alt="">
                    <img src="../assets/img/Bloggertrip/B_ezplan2.png" alt="">
                    <img src="../assets/img/Bloggertrip/B_ezplan3.png" alt="">
                    <img src="../assets/img/Bloggertrip/B_ezplan4.png" alt="">
                  </div><!-- bcs_ezplan_img -->
                  <button class="b-show-btn">B코스 여심저격 상세일정 자세히 보기</button>
                  <div class="b-hdplan">
                    <div class="hdplan_img">
                    <img src="../assets/img/Bloggertrip/B_hdplan1.png" alt="">
                    </div>
                  </div><!-- hdplan -->
                  
                </div> <!-- bcs_plan -->
              </div>

            </div><!-- ab_cs_tabcont -->
            <div class="wg_lastcard">
              <img src="../assets/img/Bloggertrip/lastcardA.png" @click="goToDetail('T-20241122-1732260357471', 'T')" alt="">
              <img src="../assets/img/Bloggertrip/lastcardB.png" @click="goToDetail('T-24112207URC', 'T')" alt="">
            </div>
            <p class="wg_last_main_tit_hj">일본 렌터카 자유여행도 <br> 엔타비글로벌과 함께 하세요!</p>
            <p class="wg_last_sub_tit_hj">*본 기획전은 인플루언서 와그잡,곤(GON), 여행 전문 매거진 피치 바이 피치의 이미지와 정보를 제공받아 제작되었습니다.</p>

      </div> <!-- wg_ab_course -->

</div><!-- wg_blog_page -->
</template>

<script>

/* ---탭메뉴--- */
document.addEventListener('DOMContentLoaded', function() {
    const tabButtons = document.querySelectorAll('.cs_tab-button');
    const tabPanels = document.querySelectorAll('.cs-tab-panel');

    tabButtons.forEach(button => {
        button.addEventListener('click', () => {
            const targetTab = button.getAttribute('data-tab');

            // 모든 탭 버튼에서 'active' 클래스 제거
            tabButtons.forEach(btn => btn.classList.remove('active'));

            // 클릭한 탭 버튼에 'active' 클래스 추가
            button.classList.add('active');

            // 모든 탭 콘텐츠에서 'active' 클래스 제거
            tabPanels.forEach(panel => panel.classList.remove('active'));

            // 선택된 탭 콘텐츠에 'active' 클래스 추가
            document.getElementById(targetTab).classList.add('active');
        });
    });
});



export default {
  // DB에서 가져온 데이타

  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },

  mounted() {
    let currentIndex = 0;
    const slides = document.querySelectorAll('.wg_slide');
    const totalSlides = slides.length;
    function showSlide(index) {
    slides.forEach((slide, i) => {
    slide.classList.remove('wg_active');
    if (i === index) {
    slide.classList.add('wg_active');}});}
    function nextSlide() {
    currentIndex = (currentIndex + 1) % totalSlides;
    showSlide(currentIndex);}
    showSlide(currentIndex);
    setInterval(nextSlide, 3000)


    const button = document.querySelector('.wg-show-btn');
        const hdplan = document.querySelector('.hdplan');

        button.addEventListener('click', function() {
            if (hdplan.style.display === 'block') {
                // 요소가 보일 때
                hdplan.style.opacity = '0'; // 투명도 변경
                setTimeout(() => {
                    hdplan.style.display = 'none'; // 숨김
                }, 500); // 애니메이션 시간과 일치
            } else {
                // 요소가 숨겨져 있을 때
                hdplan.style.display = 'block'; // 보이도록 설정
                setTimeout(() => {
                    hdplan.style.opacity = '1'; // 투명도 변경
                    hdplan.style.top = '0'; // 최종 위치로 이동
                }, 10); // 잠시 대기 후 애니메이션 시작
            }
        })


        const buttons = document.querySelector('.b-show-btn');
        const hdplans = document.querySelector('.b-hdplan');

        buttons.addEventListener('click', function() {
            if (hdplans.style.display === 'block') {
                // 요소가 보일 때
                hdplans.style.opacity = '0'; // 투명도 변경
                setTimeout(() => {
                    hdplans.style.display = 'none'; // 숨김
                }, 500); // 애니메이션 시간과 일치
            } else {
                // 요소가 숨겨져 있을 때
                hdplans.style.display = 'block'; // 보이도록 설정
                setTimeout(() => {
                    hdplans.style.opacity = '1'; // 투명도 변경
                    hdplans.style.top = '0'; // 최종 위치로 이동
                }, 10); // 잠시 대기 후 애니메이션 시작
            }
        });

  }
}

</script>


<style>

@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#wg_blog_page{
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: -56px;
  overflow: hidden;
  font-family: 'GmarketSansMedium';
}

/* --- main ----- */


.wg_slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1300px;
    }

.wg_main_tit {
  position: absolute;
  width: 100%;
  z-index: 99;
  height: 100%;
  color: #fff;
  text-align: center;
  transform: translate(0%,10%);
  padding-top: 100px;
}

.wg_main_tit p.wg_sub_tit {
  font-size: 2.5rem;
}
.wg_main_tit p.wg_sub_tit2 {
  font-size: 3rem;
  font-weight: bold;
}
.wg_main_tit p.wg_sub_tit3 {
  font-size: 2.6rem;
  font-weight: bold;
}
.wg_main_tit p.wg_sub_tit strong {
  color: #ffd971;
  background-color: #133476;
  padding: 5px;
}
.wg_main_tit .yko_tit {
  font-size: 8.5rem;
  text-shadow: 2px 2px 4px gray;
}
.wg_main_tit img {
  width: 70%;
  padding-top: 150px;
}
.mtit_mo_767 {
  display: none;
}
.wg_slides {
  position: absolute;
  width: 100%;
  height: 100%;
    }
.wg_slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.wg_active {
  opacity: 1;
}

/* -----------------wg_reason----------------------- */

.wg_reason {
  width: 100%;
  height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #133476;
    text-align: center;
    justify-content:center;
    gap: 150px;
}

.wg_reason p {
  font-size: 4rem;
  color: #fff;
  font-weight: bolder;
}
.wg_reason p span {
  color: #6fde72;
}

.wg_reason .reason_img {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.reasonlist1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.reasonlist1 img {
  width: 300px;
}
.reasonlist2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.reasonlist2 img {
  width: 300px;
}
.reasonlist2 .rentg{
  width: 200px;
}

.rs_mo {
  display: none;
}
/* -----------------wg_yko_spot----------------------- */

.wg_yko_spot {
  width: 100%;
  background-image: url(../assets/img/Bloggertrip/DSCF1363-1.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;
  padding-bottom: 150px;
}

.wg_yko_spot .wg_yko_mtit {
width: 35%;
margin-top: 150px;
}

.wg_yko_spot .yko_spot_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.wg_yko_spot .yko_spot_img img {
  width: 50%;
}

/* -----------------wg_ab_course----------------------- */

.wg_ab_cs_tit {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wg_ab_cs_tit img {
  width: 40%;
}

/* -----------------ab_menubar_hj----------------------- */

.ab_menubar_hj {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.cs_tab-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs_tab-menu button {
  padding: 50px;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  border: none;
}
.cs_tab-menu button:first-child {
  background-color: #fcf6cd;
  color: #333333;
  font-size: 1.5rem;
  font-weight: bold;
}

.cs_tab-menu button:last-child {
  background-color: #dff2eb;
  color: #133476;
  font-size: 1.5rem;
  font-weight: bold;
}

.cs-tab-panel.active {
      display: block;
    }
.cs-tab-panel {
  display: none;
  }

/* -----------------A_course----------------------- */


.acs_spot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(../assets/img/Bloggertrip/Apointbg.jpg) ;
  background-size: cover;
  padding-bottom: 150px; 
}

.acs_spot p {
  color: #333333;
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  margin-block: 80px;
}

.acs_spot_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.apoint1{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.apoint1 img{
  width: 25%;
}

.apoint2{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.apoint2 img{
  width: 25%;
}

.apoint3{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.apoint3 img{
  width: 25%;
}

.apointlast {
  width: 52.1%;
}

/* Aplan */

.aplanname {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.aplanname img {
  width: 40%;
}

.acs_plan {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/img/Bloggertrip/A_planbg.jpg) ;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;

}

.acs_ezplan_img {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.acs_ezplan_img img {
  width: 12%;
}

.wg-show-btn {
  width: 40%;
  margin-block: 100px;
  border: none;
  padding-block: 20px;
  font-size: 2rem;
  border-radius: 50px;
}

.hdplan{
  width: 100%;
  display: none;
  position: relative;
  top: -20px;
  opacity: 0;
  padding-bottom: 300px;
  transition: top 0.5s ease, opacity 0.5s ease;
}


.hdplan_img{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  transition: max-height 0.5s ease-in-out;
}

.hdplan_img img {
  width: 40%;
}





/* -----------------B_course----------------------- */

.bcs_spot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(../assets/img/Bloggertrip/Bpointbg.jpg) ;
  background-size: cover;
  padding-bottom: 100px; 
}

.bcs_spot p {
  color: #333333;
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  margin-block: 80px;
}

.bcs_spot_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.bpoint1{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.bpoint1 img{
  width: 25%;
}

.bpoint2{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.bpoint2 img{
  width: 25%;
}

.bpoint3{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.bpoint3 img{
  width: 25%;
}

.bpointlast {
  width: 52.1%;
}

/* Bplan */

.bplanname {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bplanname img {
  width: 40%;
}

.bcs_plan {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/img/Bloggertrip/B_planbg.jpg) ;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 300px;

}

.bcs_ezplan_img {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bcs_ezplan_img img {
  width: 12%;
}

.b-show-btn {
  width: 40%;
  margin-block: 100px;
  border: none;
  padding-block: 20px;
  font-size: 2rem;
  border-radius: 50px;
}


.b-hdplan{
  width: 100%;
  display: none;
  position: relative;
  top: -20px;
  opacity: 0;
  transition: top 0.5s ease, opacity 0.5s ease;
}


.hdplan_img{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  transition: max-height 0.5s ease-in-out;
}

.hdplan_img img {
  width: 40%;

}





.wg_last_main_tit_hj {
  font-size: 3rem;
  color: #333;
  text-align: center;
  margin-top: 150px;
}

.wg_last_sub_tit_hj {
  margin-top: 20px;
  color: #333;
  font-size: 20px;
  text-align: center;
}

.wg_lastcard{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-block: 50px;
  gap: 30px;
  cursor: pointer;
}

.wg_lastcard img {
  width: 22%;
}


/* ------------------------------------------미디어 쿼리 */

@media (max-width: 1720px) and (min-width: 1401px){


}

@media (max-width: 1400px) and (min-width:1200px){


  .wg_main_tit {
    position: absolute;
    width: 80%;
    z-index: 99;
    height: 100%;
    color: #fff;
    text-align: center;
    transform: translate(10%, 10%);
    padding-top: 100px;
}

.wg_main_tit p.wg_sub_tit {
    font-size: 1.9rem;
}
.wg_main_tit .yko_tit {
    font-size: 7.0rem;
    text-shadow: 2px 2px 4px gray;
}




.wg_yko_spot .wg_yko_mtit {
    width: 57%;
    margin-top: 150px;
}

.wg_yko_spot .yko_spot_img img {
  width: 80%;
}

.apoint1 img {
  width: 30%;
}

.apoint2 img {
  width: 30%;
}

.apoint3 img {
  width: 30%;
}

.apointlast {
    width: 64.2%;
}

.acs_ezplan_img img{
  width: 20%;
}

.acs_plan button {
    width: 60%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 2rem;
    border-radius: 50px;
}

.hdplan_img img {
    width: 50%;
}

.wg_last_main_tit_hj {
    font-size: 3rem;
    color: #333;
    text-align: center;
    margin-top: 150px;
}
.wg_last_sub_tit_hj {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
    text-align: center;
}
}

@media(max-width: 1199px) and (min-width: 1024px){

  .wg_main_tit p.wg_sub_tit {
    font-size: 1.8rem;
}
.wg_main_tit .yko_tit {
    font-size: 5.2rem;
    text-shadow: 2px 2px 4px gray;
}
.wg_main_tit p.wg_sub_tit3 {
    font-size: 1.8rem;
    font-weight: bold;
}
.wg_reason {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #133476;
    text-align: center;
    justify-content: center;
    gap: 50px;
}
.wg_reason p {
    font-size: 3rem;
    color: #fff;
    font-weight: bolder;
}
.reasonlist1 img {
  width: 200px;
}
.reasonlist2 img {
  width: 200px;
}

.reasonlist2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.reasonlist2 .rentg {
    width: 150px;
    margin-inline: 50px;
}
.wg_yko_spot .wg_yko_mtit {
    width: 50%;
    margin-top: 150px;
}
.wg_yko_spot .yko_spot_img img {
    width: 80%;
}
.wg_ab_cs_tit img {
    width: 60%;
}
#tab1.active {
  display: flex;
  flex-direction: column;
}
#tab2.active {
  display: flex;
  flex-direction: column;
}

.apoint1 img {
    width: 40%;
}

.apoint2 img {
    width: 40%;
}

.apoint3 img {
    width: 40%;
}
.apointlast {
    width: 85.1%;
}

.aplanname img {
    width: 60%;
}
.acs_ezplan_img img {
    width: 20%;
}

.bcs_plan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/img/Bloggertrip/B_planbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 150px;
}

.bpoint1 img {
    width: 40%;
}

.bpoint2 img {
    width: 40%;
}

.bpoint3 img {
    width: 40%;
}
.bpointlast {
    width: 85.1%;
}


.bplanname img {
    width: 60%;
}
.bcs_ezplan_img img {
    width: 20%;
}

.b-show-btn {
  width: 62%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 2rem;
    border-radius: 50px;
}
.wg-show-btn {
    width: 62%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 2rem;
    border-radius: 50px;
}
.wg_last_main_tit_hj {
    font-size: 2.7rem;
    color: #333;
    text-align: center;
    margin-top: 100px;
    margin-inline: 20px;
}
.wg_last_sub_tit_hj {
    margin-top: 20px;
    color: #333;
    font-size: 19px;
    text-align: center;
}
.hdplan_img img {
    width: 70%;
}

.wg_lastcard img {
  width: 25%;
}

}

@media(max-width: 1023px) and (min-width: 767px){

#wg_blog_page{
position: relative;
width: 100%;
margin: 0 auto;
margin-top: 0px;
overflow: hidden;
}
.wg_main_tit {
    position: absolute;
    width: 100%;
    z-index: 99;
    height: 0%;
    color: #fff;
    text-align: center;
    padding-top: 0;
}

.wg_main_tit p.wg_sub_tit {
    font-size: 1.3rem;
}
.wg_main_tit .yko_tit {
    font-size: 5.5rem;
    text-shadow: 2px 2px 4px gray;
}

.wg_main_tit p.wg_sub_tit3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-inline: 130px;
}

.wg_main_tit img {
    width: 70%;
    margin: 0 auto;
}
.wg_main_tit img:last-child {
    width: 100%;
    margin: 0 auto;
    transform: translate(-5%);
}
.wg_sub_tit{
  display: none;
}
.wg_sub_tit2{
  display: none;
}
.yko_tit{
  display: none;
}
.wg_sub_tit3{
  display: none;
}
.mtit_mo_767 {
  display: block;
}

.wg_slides {
    position: absolute;
    width: 100%;
    height: 0;
}
.wg_slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 1000px;
}
.wg_slide {
  width: 2000px;
  height: 1000px;
}


.reasonlist1 {
  display: none;
}
.reasonlist2 {
  display: none;
}
.rs_mo {
  display: block;
  width: 50%;
}
.wg_reason p {
    font-size: 3rem;
    color: #fff;
    font-weight: bolder;
}
.wg_yko_spot .wg_yko_mtit {
    width: 70%;
    margin-top: 130px;
}
.wg_yko_spot .yko_spot_img img {
    width: 88%;
}
.wg_ab_cs_tit img {
    width: 60%;
}
.cs_tab-menu button {
    padding: 25px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    border: none;
}
.cs_tab-menu button:first-child {
    background-color: #fcf6cd;
    color: #333333;
    font-size: 1.5rem;
    font-weight: bold;
}
#tab1.active {
  display: flex;
  flex-direction: column;
}
#tab2.active {
  display: flex;
  flex-direction: column;
}
.apoint1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
}
.apoint2 {
    width: 100%;
    display: flex
;
    justify-content: center;
    gap: 30px;
}
.apoint3 {
    width: 100%;
    display: flex
;
    justify-content: center;
    gap: 30px;
}
.apoint1 img {
    width: 44%;
}
.apoint2 img {
    width: 44%;
}
.apoint3 img {
    width: 44%;
}
.apointlast {
    width: 94.2%;
}
.aplanname img {
    width: 60%;
}
.aplanname {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.acs_ezplan_img img {
    width: 22%;
}
.hdplan {
    width: 100%;
    display: none;
    position: relative;
    top: -20px;
    opacity: 0;
    padding-bottom: 150px;
    transition: top 0.5s ease, opacity 0.5s ease;
}

.hdplan_img img {
    width: 80%;
}

.bpoint1 {
    width: 100%;
    display: flex
;
    justify-content: center;
    gap: 30px;
}
.bpoint2 {
    width: 100%;
    display: flex
;
    justify-content: center;
    gap: 30px;
}
.bpoint3 {
    width: 100%;
    display: flex
;
    justify-content: center;
    gap: 30px;
}
.bpoint1 img {
    width: 44%;
}
.bpoint2 img {
    width: 44%;
}
.bpoint3 img {
    width: 44%;
}
.bpointlast {
    width: 94.2%;
}
.bplanname img {
    width: 60%;
}
.bcs_plan {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/img/Bloggertrip/B_planbg.jpg) ;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 300px;
}
.bcs_ezplan_img img {
    width: 22%;
}
.bplanname {
    width: 100%;
    height: 200px;
    display: flex
;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.wg-show-btn {
    width: 80%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 1.5rem;
    border-radius: 50px;
}
.b-show-btn {
    width: 80%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 1.5rem;
    border-radius: 50px;
}

.wg_last_main_tit_hj {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-top: 80px;
}
.wg_last_sub_tit_hj {
    margin-top: 20px;
    color: #333;
    font-size: 14px;
    text-align: center;
}

.wg_lastcard img {
  width: 32%;
}

}

@media(max-width: 766px) and (min-width: 599px){

  .wg_main_tit p.wg_sub_tit {
    font-size: 1.5rem;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 150px;
}
.wg_main_tit p.wg_sub_tit2 {
    font-size: 2rem;
    font-weight: bold;
}
.wg_main_tit .yko_tit {
    font-size: 5rem;
    line-height: 1.2;
    text-shadow: 2px 2px 4px gray;
}
.wg_main_tit p.wg_sub_tit3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 15px;
    margin-right: 15px;
}
.wg_main_tit {
  height: 100%;
    }

.wg_main_tit img {
    width: 90%;
    margin: 0 auto;
    padding-top: 0;
}
.wg_main_tit img:last-child {
    width: 100%;
    margin: 0 auto;
    transform: translate(-5%);
    padding-top: 50px;
}
.wg_sub_tit{
  display: none;
}
.wg_sub_tit2{
  display: none;
}
.yko_tit{
  display: none;
}
.wg_sub_tit3{
  display: none;
}
.mtit_mo_767 {
  display: block;
}

.wg_slides {
    position: absolute;
    width: 100%;
    height: 0;
}
.wg_slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 1000px;
}
.wg_slide {
  width: 2000px;
  height: 1000px;
}


.wg_reason p {
    font-size: 2.3rem;
    color: #fff;
    font-weight: bolder;
}
.wg_reason {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #133476;
    text-align: center;
    justify-content: center;
    gap: 50px;
}
.reasonlist1 {
  display: none;
}
.reasonlist2 {
  display: none;
}
.rs_mo {
  display: block;
  width: 70%;
}
.wg_yko_spot {
    width: 100%;
    background-image: url(../assets/img/Bloggertrip/DSCF1363-1.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-bottom: 150px;
}
.wg_yko_spot .wg_yko_mtit {
    width: 80%;
    margin-top: 100px;
}
.wg_yko_spot .yko_spot_img img {
    width: 87%;
}
.wg_yko_spot .yko_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.wg_ab_cs_tit img {
    width: 75%;
}
.wg_ab_cs_tit {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cs_tab-menu button {
    padding: 30px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    border: none;
}
.cs_tab-menu button:first-child {
  width: 50%;
    background-color: #fcf6cd;
    color: #333333;
    font-size: 15px;
    font-weight: bold;
}
.cs_tab-menu button:last-child {
  width: 50%;
    background-color: #dff2eb;
    color: #133476;
    font-size: 15px;
    font-weight: bold;
}


.cs_tab-menu button span{
  font-size: 13px;
}

.cs_tab-menu button:last-child span{
  width: 50%;
    background-color: #dff2eb;
    color: #133476;
    font-size: 13px;
    font-weight: bold;
}
.acs_spot p {
    color: #333333;
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    margin-block: 80px;
}
.acs_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.apoint1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.apoint1 img {
    width: 45%;
}
.apoint2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.apoint2 img {
    width: 45%;
}
.apoint3 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.apoint3 img {
    width: 45%;
}
.apointlast {
    width: 93.1%;
}

.aplanname {
  width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.aplanname img {
    width: 75%;
}
.acs_plan {
    width: 101%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/img/Bloggertrip/A_planbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
}
.acs_ezplan_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.acs_ezplan_img img {
    width: 40%;
}
.wg-show-btn {
    width: 70%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 1rem;
    border-radius: 50px;
}
.hdplan {
    width: 100%;
    display: none;
    position: relative;
    top: -20px;
    opacity: 0;
    padding-bottom: 100px;
    transition: top 0.5s ease, opacity 0.5s ease;
}

.hdplan_img img {
    width: 75%;
}

.bcs_spot p {
  color: #333333;
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    margin-block: 80px;
}
.bcs_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.bpoint1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.bpoint1 img {
    width: 45%;
}
.bpoint2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.bpoint2 img {
    width: 45%;
}
.bpoint3 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
}
.bpoint3 img {
    width: 45%;
}
.bpointlast {
    width: 93.1%;
}

.bplanname {
  width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bplanname img {
    width: 75%;
}
.bcs_plan {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/img/Bloggertrip/B_planbg.jpg) ;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 300px;
}
.bcs_ezplan_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.bcs_ezplan_img img {
    width: 40%;
}
.b-show-btn {
  width: 70%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 1rem;
    border-radius: 50px;
}
.wg_last_main_tit_hj {
        font-size: 1.9rem;
        color: #333;
        text-align: center;
        margin-top: 100px;
        margin-inline: 20px;
        font-weight: bold;
    }
    .wg_last_sub_tit_hj {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
    text-align: center;
    margin-inline: 20px;
}
.wg_lastcard img {
  width: 32%;
}
}

@media (max-width: 598px) and (min-width: 360px){
  .wg_slide{
    width: 700px;
    height: 900px;
  }
  .wg_slides{
    height: 900px;
  }
  .wg_slider {
    height: 900px;
  }
  .wg_main_tit {
    position: absolute;
    width: 100%;
    z-index: 99;
    height:100%;
    color: #fff;
    text-align: center;
    transform: translate(0%, 10%);
    padding-top: 100px;
}
  .wg_main_tit p.wg_sub_tit {
    font-size: 19px;
    padding-inline: 20px;
}

.wg_main_tit p.wg_sub_tit2 {
    font-size: 25px;
    font-weight: bold;
    padding-inline: 20px;

}

.wg_main_tit .yko_tit {
    font-size: 75px;
    text-shadow: 2px 2px 2px gray;
    line-height: 1.2;
}

.wg_main_tit p.wg_sub_tit3 {
    font-size: 19px;
    font-weight: bold;
    padding-inline: 20px;
}

.wg_main_tit img {
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;
}
.wg_main_tit img:last-child {
    width: 100%;
    margin: 0 auto;
    transform: translate(-5%);
    padding-top: 50px;
}
.wg_sub_tit{
  display: none;
}
.wg_sub_tit2{
  display: none;
}
.yko_tit{
  display: none;
}
.wg_sub_tit3{
  display: none;
}
.mtit_mo_767 {
  display: block;
}
.wg_reason {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #133476;
    text-align: center;
    justify-content: center;
    gap: 30px;
    padding-block: 100px;
}
.wg_slides {
    position: absolute;
    width: 100%;
    height: 0;
}
.wg_slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 700px;
}
.wg_slide {
  width: 100%;
  height: 700px;
}

.wg_reason p {
  font-size: 28px;
}

.reasonlist1 {
  display: none;
}
.reasonlist2 {
  display: none;
}
.rs_mo {
  display: block;
  width: 90%;
}
.wg_yko_spot .wg_yko_mtit {
    width: 85%;
    margin-top: 50px;
}
.wg_yko_spot .yko_spot_img img {
    width: 90%;
}
.wg_yko_spot {
    width: 100%;
    background-image: url(../assets/img/Bloggertrip/DSCF1363-1.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-bottom: 150px;
  
}

.wg_yko_spot .yko_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.wg_ab_cs_tit img {
    width: 80%;
    margin-top: 50px;
}

.wg_ab_cs_tit {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cs_tab-menu button {
    padding: 30px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    border: none;
}

.cs_tab-menu button:first-child {
    background-color: #fcf6cd;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
}

.cs_tab-menu button:last-child {
    background-color: #dff2eb;
    color: #133476;
    font-size: 12px;
    font-weight: bold;
}

.acs_spot p {
    color: #333333;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    margin-block: 40px;
}
.acs_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.apoint1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.apoint1 img {
    width: 45%;
}
.apoint2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.apoint2 img {
    width: 45%;
}
.apoint3 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.apoint3 img {
    width: 45%;
}
.apointlast {
    width: 93.1%;
}

.aplanname {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.acs_plan {
    width: 101%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/img/Bloggertrip/A_planbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 60px;
}

.aplanname img {
    width: 70%;
}

.acs_ezplan_img img {
    width: 40%;
}
.acs_ezplan_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.wg-show-btn {
    width: 80%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 15px;
    border-radius: 50px;
    color: #333;
    font-weight: bold;
}
.hdplan_img img {
    width: 90%;
}
.hdplan {
    width: 100%;
    display: none;
    position: relative;
    top: -20px;
    opacity: 0;
    padding-bottom: 100px;
    transition: top 0.5s ease, opacity 0.5s ease;
}

.wg_last_main_tit_hj {
    font-size: 30px;
    color: #333;
    text-align: center;
    margin-top: 50px;
}

.wg_last_sub_tit_hj {
    margin-top: 20px;
    color: #333;
    font-size: 12px;
    text-align: center;
    padding-inline: 15px;
}

.bcs_spot p {
    color: #333333;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    margin-block: 40px;
}
.bcs_spot_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.bpoint1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.bpoint1 img {
    width: 45%;
}
.bpoint2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.bpoint2 img {
    width: 45%;
}
.bpoint3 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.bpoint3 img {
    width: 45%;
}
.bpointlast {
    width: 93.1%;
}

.bplanname {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.bcs_plan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/img/Bloggertrip/B_planbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 150px;
}

.bplanname img {
    width: 70%;
}

.bcs_ezplan_img img {
    width: 40%;
}
.bcs_ezplan_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.b-show-btn {
    width: 80%;
    margin-block: 100px;
    border: none;
    padding-block: 20px;
    font-size: 15px;
    border-radius: 50px;
    color: #333;
    font-weight: bold;
}
.hdplan_img img {
    width: 90%;
}
.hdplan {
    width: 100%;
    display: none;
    position: relative;
    top: -20px;
    opacity: 0;
    padding-bottom: 100px;
    transition: top 0.5s ease, opacity 0.5s ease;
}
.wg_lastcard img {
  width: 40%;
}
}
</style>