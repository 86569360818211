<template>
  <div id="subpkg">
    <main class="mt-5">
      <div class="container">
        <!-- 지역 구분-->
        <header class="masthead2 sublist">
          <div class="container px-5" id="subBg">
            <div class="row gx-5 align-items-center justify-content-center">
              <div class="col-lg-8 col-xl-7 col-xxl-6">
                <div class="mb-3 text-center masthead2-heading">
                  <a href="javascript:void(0)" @click="toggleLayer()">
                    <!-- <a href="javascript:void(0)" @click="showLayer = !showLayer">   -->
                      <h2 v-if="!showLayer">{{ this.natName }} <i class="fa-solid fa-angle-down"></i></h2>
                      <h2 v-else>{{ this.natName }} <i class="fa-solid fa-angle-up"></i></h2>
                    </a>
                  </div>
                  <div v-if="showLayer" class="layer">
                    <table>
                      <tr :key="i" v-for="(sn, i) in this.selectableNation">
                        <td>
                          <!-- const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`; -->
                          <a :href="`/subList?nt=${sn.nCode}`">
                            <div>
                              {{ sn.nName }}
                            </div>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </header>

        <!-- 내용 -->
        <section class="bestitem py-2">
          <div class="container my-2">
            <div class="row gx-2">
              <div class="col-lg-4 col-md-6 col-sm-12 mb-4" :key="i" v-for="(product, i) in productListForSales">
                <div class="position-relative item">
                  <div>
                    <a class="text-decoration-none link-dark stretched-link" href="/mongolia" style="cursor: pointer" v-if="product.pdtCode == 'T-202358-1683534859762'">     
                      <div class="pic">
                        <div class="bestitem-hover">
                          <div class="bestitem-hover-content"></div>
                        </div>
                        <!-- 상품이미지 -->      
                        <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                        <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                          
                        <div class="badge">
                          <div v-if="product.badgeFlag === 'Y'">
                            <div class="badge1">
                              <small class="badge1-text">{{ product.badge }}</small>
                            </div>
                          </div>
                          <div v-if="product.badge2Flag === 'Y'">
                            <div v-if="product.badgeFlag != 'Y'" class="badge1">
                              <small class="badge1-text">{{ product.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left: 180px;">
                              <small class="badge2-text">{{ product.badge2 }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer" v-else>     
                      <div class="pic">
                        <div class="bestitem-hover">
                          <div class="bestitem-hover-content"></div>
                        </div>
                        <!-- 상품이미지 -->      
                        <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                        <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                        <div class="badge">
                          <div v-if="product.badgeFlag === 'Y'">
                            <div class="badge1">
                              <small class="badge1-text">{{ product.badge }}</small>
                            </div>
                          </div>
                          <div v-if="product.badge2Flag === 'Y'">
                            <div v-if="product.badgeFlag != 'Y'" class="badge1">
                              <small class="badge1-text">{{ product.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left: 180px;">
                              <small class="badge2-text">{{ product.badge2 }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="text mx-3 mt-2 text-dark">
                      <small v-if="product.trCd1 == 'FLT'">
                        <small class="align-middle text-muted">[{{product.cityName}}출발항공]</small>
                      </small>
                      <small v-else-if="product.trCd1 == 'SHP'">
                        <small class="align-middle text-muted">[{{product.cityName}}출발선박]</small>
                      </small>
                      <small v-else-if="product.trCd1 == 'BUS'">
                        <small class="align-middle text-muted">[현지출발]</small>
                      </small>
                      &nbsp;<h4 class="title">{{ product.pdtNameKor }}</h4>
                      <p class="binfo">{{ product.pdtRemarks }}</p>
                      <span class="price">
                        <div class="card-text mx- text-wrap">
                        <!-- 프리미엄+이외상품구매 -->
                        <div v-if="this.user.memLevel === 'P'">
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                          <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div v-else-if="this.user.memLevel === 'S'">
                          <div>
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                        </div>

                        <!-- 비로그인상태의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div v-if="(product.dcForMem_S > 0)">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<link href="../assets/css/style.css" rel="stylesheet" />

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      showLayer: false,        //지역(국가)선택 레이어

      nt: "",                           //상품카테고리(국가기준)
      natName: "",                      //표시페이지 국가명
      selectableNation: [],             //선택가능 국가
      ct: "",                           //출발지
      tr: "",                           //출발교통수단
      nationList: {},                   //여행지역리스트
      pdtCatList: {},                   //상품카테고리
      productListForSales_org: {},      //국적제거전 엔데트상품리스트      
      productListForSales: {},          //판매용 상품리스트
      //productListForSales_recomend: {}, //추천상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,

      /////멤버십 금액반영
      memLv: "",            //멤버십등급(S,P)
      memDC: [0,0,0,],      //멤버십환급율(성인,소인,유아)

      // maxDC_P: 0,        //프리미엄 최대환급율
      // maxDC_S: 0,        //스탠다드 최대환급율
      // dcForMem_P: 0,     //프리미엄 일반상품 할인율
      // dcForMem_S: 0,     //스탠다드 일반상품 할인율
      // dcForStd: 0,       //프리미엄전용을 스탠다드가 구매할 경우 할인율
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
      price_memP: 0,        //프리미엄 환급액
      price_memS: 0,        //스탠다드 환급액
      price_dcForStd: 0,    //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,  //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,  //일반상품을 스탠다드가 구매시 할인액
    };
  },
  created() {
    this.nt = this.$route.query.nt;
    this.ct = this.$route.query.ct;
    this.tr = this.$route.query.tr;
        
    // 페이지생성과 동시에 리스트정보를 get
    this.getNationList();
    this.getPdtCatList();
    this.getProductList();

    console.log(
      "userInfo:",this.$store.state.user,
    );
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   //this.$router.push({path: '/'});
    //   location.replace("/");
    // }

    if (this.$store.state.user.handlerId != undefined) {
      document.querySelector(".b2cTag").style.display = "none";
    }
    if (this.$store.state.user.email != undefined) {
      document.querySelector(".b2bTag").style.display = "none";
    }
    // if(this.$store.state.user.buyerId == undefined && this.$store.state.user.email == undefined) {
    //   // document.querySelector(".b2bTag").style.display="none";
    // }

    //상단배너이미지(지역카테고리별)
    //let imgUrl = "/IMGs/subBg/" + this.nt + ".jpg";
    //document.getElementById("subBg").style.background = "url("+imgUrl+") no-repeat center center";
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  methods: {
    toggleLayer()
    {
      this.showLayer = !this.showLayer
      //판매상품 중에 엔타비상품 중 국가코드만 추출
      let targetNation = this.productListForSales_org.filter((item) => 
				{
					return item.sellerCode_nt == "Y";
				});
      //console.log("targetNation:",targetNation)
      let values = Object.values(targetNation)
      const key = 'ntCd'
      //ntCd를 추출하되 중복제거한다
      let set = new Set(values.filter(val => typeof val === 'object').map(val => val[key]));
      let result = Array.from(set)
      // console.log("result:",result)

      //선택가능 국가명
      this.selectableNation = this.nationList.filter(obj => result.includes(obj.baseCode)).map(obj => ({nCode: obj.baseCode, nName: obj.codeNameKor}))
      //console.log("selectableNation:",this.selectableNation)
    },    
    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      let natName = nationList.filter((item) => {
        return item.baseCode == this.nt
      })
      this.natName = natName[0].codeNameKor
      //console.log("nationList:",this.nationList,",natName:",natName[0].codeNameKor)
    },

    async getPdtCatList() 
    {
      let pdtCatList = await this.$api("/api/pdtCatList", {});
      this.pdtCatList = pdtCatList;
    },
    chage_ntCodeList(c1,c2) 
    {
      this.getProductList(c1, c2)
    },
    
    //메인화면 상품검색
    selSearchCondition(c1, c2){
      this.getProductList(c1, c2)
    },
    
    async getProductList(p1, p2) 
    {
      let arCd = ""; //지역코드
      let sRoute = "";
      let sMinPrice = 0;
      let sMargin = 0;
      arCd = (p1 == undefined || p1 == "") ? "%" : p1;
      console.log(arCd);
      
      sRoute = (p2 == undefined || p2 == "") ? "%" : p2;
      let ntCd = this.nt
      let ctCd = this.ct
      let trCd = this.tr

      //국가코드에 따른 상품리스트
      let productListForSales_tmptmp = await this.$api("/api/productListForSales", {param: ['%', sRoute]});

      //연계상품배제
      let productListForSales_tmp1 = productListForSales_tmptmp.filter((item) => {
        return (item.assoFlag == null || item.assoFlag == "" || item.assoFlag == undefined)
      })

      //선박&&상품배제
      let productListForSales_tmp = productListForSales_tmp1.filter((item) => {
        return (item.salesRoute != "F" && item.salesRoute != "E")
      })      
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      let productListForSales_mix_tmp = await this.$api("/api/productListForSales_mix", {param: [this.nt, sRoute]});
      let k = 0;
      while(k < productListForSales_mix_tmp.length)
      {
        if(productListForSales_mix_tmp[k].marginMethod === "P")
        {
          sMargin = productListForSales_mix_tmp[k].minPrice * (productListForSales_mix_tmp[k].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_mix_tmp[k].basicMargin;
        }
        sMinPrice = productListForSales_mix_tmp[k].minPrice + sMargin;
        productListForSales_mix_tmp[k].sMinPrice = sMinPrice;
        k++;
      }

      let productListForSales_org = productListForSales_tmp;

      let j = 0;
      while(j < productListForSales_mix_tmp.length) {
          //결합상품을 앞쪽에 위치하도록 넣는다(push는 뒤에 넣기)
          productListForSales_org.unshift(productListForSales_mix_tmp[j]);
        j++;
      }
      this.productListForSales_org = productListForSales_org;
      
      //지정된 국가코드로 분류
      this.productListForSales = this.productListForSales_org.filter((item) => 
				{
					return item.ntCd == ntCd;
				});      

      //엔타비 상품만 분류
      this.productListForSales = this.productListForSales.filter((item) => 
				{
					return item.sellerCode_nt == "Y";
				});
      //console.log("productListForSales:", this.productListForSales);
      
      //출발지+교통편으로 파라메타가 넘어오면?
      if(ntCd != undefined && trCd != undefined)
      {
        this.productListForSales = this.productListForSales.filter((item) => 
				{
					return item.ctCd == ctCd && item.trCd1 == trCd
				});
        console.log("productListForSales:", this.productListForSales);
      }

      // 상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      let dcForMem_P = 0      //이외상품 프리미엄 할인율
      let dcForMem_S = 0      //이외상품 스탠다드 할인율

      let marginPrice = 0;    //마진가격
      let promoPrice = 0;     //프로모션금액

      let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액



      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice =  (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice =  this.productListForSales[i].promoRate;
          }

          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        }

        //일반상품, 기타상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        if(this.productListForSales[i].salesRoute == "T" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
        {   
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          //할인방식에 따라 분기처리
          if(this.productListForSales[i].dcRateForMem_P === "P")
          {
            price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          }
          else
          {
            price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
          }
          
          if(this.productListForSales[i].dcRateForMem_S === "P")
          {
            price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
          }
          else
          {
            price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
          }

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }

        //멤버십미지정 로컬투어
        else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
        {
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
          
          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }

        //화면표시용 가격
        //구매자 멤버십
        if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
        {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격" 
            
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if(this.price_memP >= this.promoPrice && this.price_memP > 0)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memP = this.price_memP  //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율
                }
                else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S' && this.price_memS > 0)
              {
                if(this.price_memS >= this.promoPrice)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memS = this.price_memS  //환급액
                }
                else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }

            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
              else if(this.user.memLevel === 'S')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //환급액
              }
            }
          }

          //멤버십+일반상품
          else
          {
            //일반상품지정(패키지, 기타상품, 로컬+엔데어 || 버라이어티)
            if(this.productListForSales[i].salesRoute == 'T' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                }
              }
            }
            
            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
            {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
              }
            }
          }
        }

        //구매자 비멤버십
        else
        {
          //프로모션이 있으면 할인처리
          if (this.productListForSales[i].promoStatus === "Y")
          {
            this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
              //console.log(`pPrice${i}: ${this.pPriceY}`);
              this.productListForSales[i].pPrice = this.pPriceY;
          } 
          else if (this.productListForSales[i].promoStatus == "E") 
          {
            this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceE;
          } 
          else if (this.productListForSales[i].promoStatus == "N") 
          {
            this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceN;
          }
        }
      }
      //console.log("productListForSales:",this.productListForSales);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    // 숫자콤마표시
    // getCurrencyFormat(value) {
    //   // console.log('value' + value)
    //   return (value+"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }
};
</script>

<style>
/* -------------------------------------------------
  # 지역 구분
--------------------------------------------------- */
#subpkg .sublist .layer {
  pointer-events: auto;
  width: auto;
  height: auto;
  margin-bottom:50px;
  border-top:1px solid #aaa
}
#subpkg .sublist .layer table{
  margin-left: auto; 
  margin-right: auto;
  border-collapse: separate;
  border-spacing: 0 15px;
}
#subpkg .sublist .layer tr td a{
  font-size:1.3rem;
  position:relative
}
#subpkg .layer tr td:hover a:after{
  content: '';
  width: 70px;
  height: 1px;
  display: block;
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  margin-top: 1px;
  border-bottom: 3px solid #9fcd53;
}




/* -------------------------------------------------
  # 내용
--------------------------------------------------- */
#subpkg .bestitem .pic{
  position:relative;
}
#subpkg .bestitem .card-img-top {
  width: 100%;
  min-height:350px;
  max-height:350px;
  object-fit: cover;
}
/* 멤버십 아이콘 */
#subpkg .bestitem .mbus-icon{
  width:2.5rem !important;
  height: auto !important;
}

#subpkg .bestitem .pic .bestitem-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  opacity:0;
  transition: opacity ease-in-out 0.4s;
}
#subpkg .item:hover .pic .bestitem-hover {
  opacity:.7;
}
/*
@media (max-width:1200px){
  #subpkg .bestitem img {
    min-height:280px;
    max-height:280px;
  }
}
*/
/* 제목 */
#subpkg .item h4{
  text-align:start;
  position: relative;
  font-size: 1.1rem;
  line-height:23px;
  color: #000;
  font-weight:600;
  padding-top: 5px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height:27px;
}

/* 내용 */
#subpkg .item .binfo{
  font-size:.8rem;
  height:40px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom:.5rem
}

/* 가격 */
#subpkg .item .price {
	float:right;
  font-size: 1.2rem;
  font-weight:600;
  color:#111;
  margin-bottom:.5rem
}


/* 뱃지 */
#sub-tkt .badge {
  /* width: 120px; */
  text-align: center;
}

#sub-tkt .badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
#sub-tkt .badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

#sub-tkt .badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
#sub-tkt .badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}
</style>
