<template>
  <div id="b2b">
    <section class="login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-xs-12">
            <div class="page-login-form">
              <h3>로그인 / 회원가입</h3>

              <!-- 회원,비회원 구분 탭 -->
              <div class="lo-tap">
                <button type="button" class="member" id="pre" @click="showLogin('M')">회원(로그인)</button>
                <button type="button" class="nonmem" id="std" @click="showLogin('N')">비회원(예약확인)</button>
              </div>

              <!-- 회원 내용 -->
              <div class="minput" v-if="this.showFlag === 'M'">
                <div class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="email-id" placeholder="아이디(이메일주소)" v-model="this.uEmail" ref="uEmail">
                    <label for="email-id">아이디(이메일주소)</label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-floating">
                    <input type="password" class="form-control" id="password" placeholder="비밀번호" v-model="this.uPass" ref="uPass">
                    <label for="password">비밀번호</label>
                  </div>
                </div>
                
                <div class="row col-12 check">
                  <!-- <div class="form-check col-6">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="this.remember">
                    <label class="form-check-label" for="flexCheckDefault">
                      아이디저장
                    </label>
                  </div> -->

                  <div class="col-lg-12 idpw">
                    <ul>
                      <li><a href="javascript: void(0)" @click="searchMy('id')">아이디 찾기</a></li>
                      <li><a href="javascript: void(0)" @click="searchMy('pass')">비밀번호 찾기</a></li>
                      <li><a href="/membership"><strong style="color:red">회원가입</strong></a></li>
                    </ul>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-12 d-grid">
                    <button type="button" class="btn go-login" ref="enterBtn" @click="memberLogin('E')">로 그 인</button>
                  </div>
                </div>

                <hr>
                <!-- sns 로그인 -->
                <div class="row sns">
                  <div class="row justify-content-center">
                    <div class="col-lg-12 d-grid text-start">
                      <label class="simpleBooking">
                        <span>간편 로그인</span>
                        <span class="SBexplain">1회 인증 후, 간편 로그인을 하실 수 있습니다.</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-6 my-2">
                    <div id="naver_id_login"></div>
                  </div>
                  <div class="col-lg-6 my-2 kakao">
                    <button class="border" @click="kakaoLogin('K')">
                      <img src="../assets/img/new-test/kaka.png" class="img-fluid">
                      카카오 아이디로 로그인
                    </button>
                  </div>
                </div>

              </div>


              <!-- 비회원 내용 -->
              <div class="nminput" v-else-if="this.showFlag === 'N'">
                <!-- <div class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="name" placeholder="예약자이름" ref="nResName" v-model="this.nResName">
                    <label for="name">예약시 등록한 성명</label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="phone" placeholder="휴대폰번호" ref="nResCall" v-model="this.nResCall">
                    <label for="phone">예약시 등록한 연락처(ex: 01023457890)</label>
                  </div>
                </div> -->
                <div class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="number" placeholder="예약번호" ref="nResCode" v-model="this.nResCode">
                    <label for="number">알림톡으로 안내된 예약번호</label>
                  </div>
                </div>
                
                <p><i class="bi bi-circle"></i> 예약번호는 카카오톡 확인 부탁드리며 확인 불가인 경우 1660-4602로 문의주시기 바랍니다.</p>

                <div class="row justify-content-center">
                  <div class="col-lg-12 d-grid">
                    <button type="button" class="btn go-reser" @click="checkNonMemRes()">예약 확인</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { genCertiNumber } from '../assets/js/AuthFunctions'

export default {
  data() {
    return {
      showFlag: "M",        //화면표시플래그

      uEmail: "",           //아이디
      uPass: "",            //패스워드
      loginInfo_E: [],      //직가입회원
      certiNo: "",          //인증번호

      loginInfo: [],
      loginParam: "",
      checkRes: "",

      nResName: "",         //비회원성명
      nResCall: "",         //비회원전번
      nResCode: "",         //비회원예약

      membershipInfo: []    //멤버십정보
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      return this.$store.state.user;
    }
  },
  created(){
    //this.st = (this.$route.query.st === 'Y') ? 'Y' : ''

    this.st = this.$route.query.st              //결제스타일
    this.pFlag = this.$route.query.pFlag        //회원결제
    this.seqReq = this.$route.query.seqReq      //예번
    this.pCode = this.$route.query.pCode        //상품코드

    //console.log("st:", this.st, ",seqReq:",this.seqReq, ",pcode:",this.pCode)
  },
  mounted(){
    //무관화면 표시방지
    document.getElementById("notice").style.display = "none";

    //const naver_id_login = new window.naver_id_login("ZWHmhzE6YbpdaPkpRVxP", "http://localhost/naverCallBack");
    let naver_id_login = new window.naver_id_login("ZWHmhzE6YbpdaPkpRVxP", "http://ntabi.co.kr/naverCallBack");
    let state = naver_id_login.getUniqState();
    naver_id_login.setButton("green", 10,62); // 버튼 설정
    naver_id_login.setState(state);
    // naver_id_login.setPopup(); // popup 설정을 위한 코드
    naver_id_login.init_naver_id_login();

    //리턴키바로가기
    document.addEventListener("keydown",this.handleEnterKeyPress);
  },

  beforeUnmount() {
    document.removeEventListener("keydown",this.handleEnterKeyPress);
  },
  methods: {
    showLogin(param){
      this.showFlag = (param === "") ? "M" : param
      

      if (param === "M") 
      {
        location.reload(true)
        document.getElementById("pre").style.backgroundColor = "black";
        document.getElementById("pre").style.color = "white";
        document.getElementById("std").style.backgroundColor = "white";
        document.getElementById("std").style.color = "black";
        //this.showNaverLogin()
      } 
      else if (param === "N") 
      {
        document.getElementById("std").style.backgroundColor = "black";
        document.getElementById("std").style.color = "white";
        document.getElementById("pre").style.backgroundColor = "white";
        document.getElementById("pre").style.color = "black";
      }

    },

    handleEnterKeyPress(event)
    {
      if(event.keyCode === 13)
      {
        //this.st = "N"
        this.memberLogin("E")
      }
    },

    //비회원 예약확인 => 비회원예약페이지로 연결만 되도록 단순화(2025-01-08)
    async checkNonMemRes(){
      if(!this.nResCode)
      {
        this.$refs.nResCode.focus();
        this.$swal.fire("","예약번호를 입력하세요","warning")
        return false
      }

      let loginInfo_E = []
      loginInfo_E = await this.$api("/api/checkResNonMember_new", {param: [this.nResCode]});
      if(loginInfo_E.length > 0)
      {
        this.loginInfo_E = loginInfo_E[0]

        this.$router.push({path:'/reserveInfoDetail_nMem', query: {
          seqReq: this.nResCode, pCode: this.loginInfo_E.reqPdtCode, cCode: "Y", rpCode: "nonRP"
        }})
      }
      else
      {
        this.$swal.fire("","카카오알림톡으로 안내된 정보를 확인해주세요<br>확인이 어려운 경우 문의처: <b class='text-danger'>1660-4062</b>","warning")
        //location.reload(true)
      }
    },

    // //비회원 예약확인
    // async checkNonMemRes(){
    //   //let rCode = "RQ-" + this.nResCode

    //   if(!this.nResName)
    //   {
    //     this.$refs.nResName.focus();
    //     this.$swal.fire("","예약시 입력한 예약자이름을 입력하세요","warning")
    //     return false
    //   }
      
    //   if(!this.nResCall)
    //   {
    //     this.$refs.nResCall.focus();
    //     this.$swal.fire("","예약시 입력한 연락처를 입력하세요","warning")
    //     return false
    //   }

    //   if(!this.nResCode)
    //   {
    //     this.$refs.nResCode.focus();
    //     this.$swal.fire("","예약번호를 입력하세요","warning")
    //     return false
    //   }       

    //   let loginInfo_E = []
    //   loginInfo_E = await this.$api("/api/checkResNonMember", {param: [this.nResName, this.nResCall, this.nResCode]});
    //   if(loginInfo_E.length > 0)
    //   {
    //     this.loginInfo_E = loginInfo_E[0]

    //     //console.log("loginInfo_E:",loginInfo_E)

    //     //this.$swal.fire("", '<b>'+ loginInfo_E[0].reqName + '님</b>, 반갑습니다(비회원예약)', "success")

    //     this.$router.push({path:'/reserveInfoDetail_nMem', query: {
    //       seqReq: this.nResCode, pCode: this.loginInfo_E.reqPdtCode, cCode: "Y"
    //     }})
    //   }
    //   else
    //   {
    //     this.$swal.fire("","카카오알림톡으로 안내된 정보를 확인해주세요<br>확인이 어려운 경우 문의처: <b class='text-danger'>1660-4062</b>","warning")
    //     //location.reload(true)
    //   }
    // },


    //직가입회원 로그인체크
    async memberLogin(param){
      if(!this.uEmail)
      {
        this.$refs.uEmail.focus();
        this.$swal.fire("","아이디(이메일)을 입력하세요","warning")
        return false
      }
      
      if(!this.uPass)
      {
        this.$refs.uPass.focus();
        this.$swal.fire("","비밀번호를 입력하세요","warning")
        return false
      }      

      let loginInfo_E = []
      loginInfo_E = await this.$api("/api/checkMemberLogin", {param: [this.uEmail, this.uPass, param]});
      //console.log("loginInfo_E:",loginInfo_E)

      if(loginInfo_E.length > 0)
      {
        this.loginInfo_E = loginInfo_E[0]

        //console.log("loginInfo_E:",this.loginInfo_E)
        if(this.loginInfo_E.mShipFlag === 'Y')
        {
          //alert("This man is the membership subscriber!")
          let membershipInfo = []
          membershipInfo = await this.$api("/api/checkMembershipInfo", {param: [this.uEmail]});
          if(membershipInfo.length > 0)
          {
            this.membershipInfo = membershipInfo[0]
          }
          //console.log("membershipInfo:",this.membershipInfo)
        }

        //멤버십회원인 경우 세션추가
        //store저장용
        let loginInfo_E1 = {
          email:loginInfo_E[0].uEmail, nickname:loginInfo_E[0].uNickName, joinFlag:loginInfo_E[0].joinFlag, phone:loginInfo_E[0].alimCallC, alimCallC:loginInfo_E[0].alimCallC, birthday:loginInfo_E[0].uBirthday, birthyear:loginInfo_E[0].uBirthyear, gender:loginInfo_E[0].uGender, insDate:loginInfo_E[0].insDate, memLevel:this.membershipInfo.memLevel, memNo:this.membershipInfo.memNo, memExpDate:this.membershipInfo.expDate
        }

        //로그인 & 세션 처리
        this.$api("/api/loginMemberE", {param: [{ 
          email:loginInfo_E[0].uEmail, nickname:loginInfo_E[0].uNickName, joinFlag:loginInfo_E[0].joinFlag, phone:loginInfo_E[0].alimCallC, alimCallC:loginInfo_E[0].alimCallC, birthday:loginInfo_E[0].uBirthday, birthyear:loginInfo_E[0].uBirthyear, gender:loginInfo_E[0].uGender, insDate:loginInfo_E[0].insDate, memLevel:this.membershipInfo.memLevel, memNo:this.membershipInfo.memNo, memExpDate:this.membershipInfo.expDate
        }
          ]});
        this.$store.commit("user", loginInfo_E1);

        //세션유지시간 1시간으로 로컬세팅
        localStorage.setItem('sessionExpiration', new Date().getTime() + 60 * 60 * 1000);


        //로그인 루트로 분기처리
        if(this.st == "Y")
        {
          this.$router.back();
        }
        else if(this.st == "M")
        {
          //회원결제는 예약상세로 이동처리
          this.goToReqReserveDetail(this.seqReq, this.pCode, "Y")
        }
        else if(!this.st || this.st == '' || this.st == 'N')
        {
          location.href = "/"
        }
      }
      else
      {
        this.$swal.fire("","아이디 또는 비밀번호를 확인해주세요.","error")
        location.reload(true)
      }
    },

    goToReqReserveDetail(seqReq, pCode, ntCode1) {
      if(seqReq.substr(0,2) === "RQ")
      {
        this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, pCode: pCode, cCode: 'Y'}});
      }
      else
      {
        this.$router.push({path: '/eventInfo_client', query: {eCode: seqReq, ntCd: ntCode1}});
      }
    },

    //아이디찾기 or 임시비번생성
    async searchMy(param){
      if(param === "id")  //아이디 찾기
      {
        const { value: formValues } = await this.$swal.fire({
          // title: '아이디찾기(직접 가입회원만 가능)',
          html:
            '<div class="searchId">' +
            '<b>아이디찾기</b> <font class="fs-6">(홈페이지 가입회원만 가능)</font> <br>' +
            '<input id="alimCallC" class="swal2-input" placeholder="가입시 연락처(-제외)">' +
            '<input id="uNickName" class="swal2-input" placeholder="가입시 한글성명">' +
            '</div>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById('alimCallC').value,
              document.getElementById('uNickName').value
            ]
          }
        })

        if (!formValues) 
        {
          this.$swal.fire("","연락처와 한글설명을 입력해주세요","warning")
          return false
        }
        else
        {
          //this.$swal.fire(JSON.stringify(formValues))
          let alimCallC = formValues[0]
          let uNickName = formValues[1]

          this.searchMemberId(alimCallC, uNickName, "id")
        }
      }
      else if(param === "pass")  //임시비번생성+발송
      {
        const { value: formValues } = await this.$swal.fire({
          // title: '아이디찾기(직접 가입회원만 가능)',
          html:
            '<div class="searchPass">' +
            '<b>비밀번호찾기</b> <font class="fs-6">(홈페이지 가입회원만 가능)</font> <br>' +
            '<input id="alimCallC" class="swal2-input" placeholder="가입시 연락처(-제외)">' +
            '<input id="uEmail" class="swal2-input" placeholder="가입시 아이디(메일주소)">' +
            '</div>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById('alimCallC').value,
              document.getElementById('uEmail').value
            ]
          }
        })

        if (!formValues) 
        {
          this.$swal.fire("","연락처와 아이디(이메일)을 입력해주세요","warning")
          return false
        }
        else
        {
          //this.$swal.fire(JSON.stringify(formValues))
          let alimCallC = formValues[0]
          let uEmail = formValues[1]

          this.searchMemberId(alimCallC, uEmail, "pass")
        }
      }
      else
      {
        this.$swal.fire("","사이트에서 직접 회원가입한 회원분만 아이디찾기가 가능합니다.","info")
        return false
      }      
    },

    async searchMemberId(alimCallC, val, param){
      let res_SearchMemberIDInfo = []
      let res_SearchMemberPASSInfo = []

      if(param === "id")
      {
        res_SearchMemberIDInfo = await this.$api("/api/searchMemberId", {param: [
          val, alimCallC
        ]})
        if(res_SearchMemberIDInfo.length > 0)
        {
          this.$swal.fire('','아이디를 가입시 등록하신 연락처로 전송합니다.<br>확인해주세요.', 'info')
          //아이디찾기 결과값 발송
          this.sendKakao('CT',res_SearchMemberIDInfo[0].uEmail, alimCallC)
        }
        else
        {
          this.$swal.fire('','결과를 찾을 수 없습니다', 'warning')
          return false;
        }
      }
      else if(param === "pass")
      {
        res_SearchMemberPASSInfo = await this.$api("/api/searchMemberPass", {param: [
          val, alimCallC
        ]})

        if(res_SearchMemberPASSInfo.length > 0)
        {
          let tempPass = ""
          tempPass = await this.genCertiNumber(val)
          //console.log("tempPass:", tempPass)
          
          if(!tempPass)
          {
            this.$swal.fire('','임시비밀번호 생성에 오류가 발생했습니다. 다시 비밀번호 찾기를 해주세요', 'warning')
            return false;
          }
          else
          {
            this.$swal.fire('','임시비밀번호를 가입시 등록하신 연락처로 전송합니다.<br>확인해주세요.', 'info')
            //임시비밀번호 발송
            this.sendKakao('CT',tempPass , alimCallC)
          }
        }
        else
        {
          this.$swal.fire('','결과를 찾을 수 없습니다', 'warning')
          return false;
        }
      }
    },

    async genCertiNumber(val)
    {
      //임시비번 생성용
      let char = "123456789abcdefghijkmnpqrstuvwxyz"
      let gNo = 5

      let tempPass = await genCertiNumber(this.$api, char, gNo, val)
      return tempPass
    },

    /** 카카오알림 발송 */
    async sendKakao(param, cNo, mobile){
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: 
      [
        cNo, param, mobile
      ]});

      //- 인증번호: #{certiNo}
      if(insKSendHistory.affectedRows > 0) {
        if(param == "CT") 
        {
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(지정값 발송)
            certiNo: cNo,
            clientCall: mobile,
            resCode: '',
            alimFlag: "CT" //알림유형
          };
          this.$api("api/kakaoAlimSend4", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          //this.$swal.fire('', '연락처로 발송되었습니다.', 'success');
          //location.reload(true)
        }
      }
    },

    kakaoLogin(val) {
      console.log("loginParam:", val); //val: K
      window.Kakao.Auth.login({
        scope: "profile, account_email, gender, birthday, name, phone_number",
        success: this.getProfile,
      });
    },
    getProfile(authObj) {
      console.log("authObj:",authObj);
      window.Kakao.API.request({
        url: "/v2/user/me",
        success: (res) => {
          const kakao_account = res.kakao_account;
          //카카오로그인이 성공하면 자사서버에 정보입력
          this.login(kakao_account);
        },
      });
    },
    async login(kakao_account) 
    {
      //로그인 불가
      if(kakao_account.email == 'dnfwlq9215@nate.com' || kakao_account.phone == '+82 10-5156-9218' ||  kakao_account.phone == '01051569218')
      {
        this.$swal.fire('', '당사로 문의를 주시면 감사하겠습니다.<br>1660-4602', 'warning')
        return false;
      }

      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;    

      let joinFlag = "K";

      //let kakao_account1 = [kakao_account.email, kakao_account.profile.nickname, joinFlag, userNo]
      let kakao_account1 = {
        email: kakao_account.email, 
        nickname: kakao_account.profile.nickname,
        //nickname: kakao_account.name,
        joinFlag: joinFlag,
        phone: kakao_account.phone_number,
        alimCallC: kakao_account.phone_number,
        birthday: kakao_account.birthday,
        gender: kakao_account.gender,
        //userNo: userNo
        //가입일자
        insDate: insDate
        //userNo: userNo
        
      };
      await this.$api("/api/login", {
        // 라우터에서 sql호출시, 파라메타를 같이 넘긴다
        // 처음 파라메터: 기존여부체크용, 두번째 파라메터: 업데이트용
        param: [
          {
            uEmail: kakao_account1.email,
            uNickName: kakao_account1.nickname,
            joinFlag: kakao_account1.joinFlag,
            uPhone: kakao_account1.phone,
            alimCallC: kakao_account1.phone,
            uBirthday: kakao_account1.birthday,
            uGender: kakao_account1.gender,
            insDate: kakao_account1.insDate
            //userNo: kakao_account1.userNo
          },
          { uNickName: kakao_account1.nickname },
        ],
      })
      .then(async (res) => {
        if(res.uEmail != "")
        {
          //카카오에서 인증해주면 store에 넣는다

          //멤버십회원정보가 있는 경우
          let res_membershipInfo = []
          res_membershipInfo = await this.membershipCheck(res.uEmail)

          let kakao_account2 = {}

          kakao_account2 = {
            email: kakao_account.email, 
            nickname: kakao_account.profile.nickname,
            //nickname: kakao_account.name,
            joinFlag: joinFlag,
            phone: kakao_account.phone_number,
            alimCallC: kakao_account.phone_number,
            birthday: kakao_account.birthday,
            gender: kakao_account.gender,
            //userNo: userNo
            //가입일자
            insDate: insDate,
            memLevel:res_membershipInfo.memLevel, 
            memNo:res_membershipInfo.memNo, 
            memExpDate:res_membershipInfo.expDate
          }

          //console.log("kakao_account2:", kakao_account2)

          this.$store.commit("user", kakao_account2);
          // this.$swal.fire({
          //   html: res.uNickName + '님, 환영합니다!',
          //   imageUrl: '/IMGs/loginout/welcome.png',
          //   imageWidth: 300,
          //   imageHeight: 150,
          //   imageAlt: 'WelcomeImage',
          // })

        //세션유지시간 1시간으로 로컬세팅
          localStorage.setItem('sessionExpiration', new Date().getTime() + 60 * 60 * 1000);
                  
          //로그인 루트로 분기처리
          if(this.st == "Y")
          {
            this.$router.back();
          }
          else if(!this.st || this.st == '' || this.st == 'N')
          {
            location.href = "/"
          }
        }
        else
        {
          this.$swal.fire("","로그인에 실패하였습니다","error");
          //this.$router.push({ path: "/" });
          location.href = "/"
        }
      })
    },

    //간편인증자의 멤버십가입자 정보획득
    async membershipCheck(uEmail){
      let checkMembershipYN = ""
      checkMembershipYN = await this.$api("/api/checkMembershipYN", {param: [uEmail]})

      if(checkMembershipYN[0].mShipFlag == "Y")
      {
        let membershipInfo = []
        membershipInfo = await this.$api("/api/checkMembershipInfo", {param: [uEmail]});
        //console.log("membershipInfo:",membershipInfo)
        if(membershipInfo.length > 0)
        {
          return membershipInfo[0]
        }
      }
      else
      {
        return []
      }
    }
  }
}
</script>

<style scoped>
.active {
  background-color: black;
}
</style>

<style>
#b2b .login{
  margin:6rem 0
}
#b2b .page-login-form h3 {
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}

/*--------------------------------------------------------------
	# 회원,비회원 구분 탭
--------------------------------------------------------------*/
#b2b .lo-tap{
  margin:3.5rem 0 2.5rem;
  text-align: center;
}
#b2b .lo-tap button{
  width:50%;
  padding:1.2rem 0;
  background-color: #fff;
  border-width: 1px;
  font-size:1.2rem;
  font-weight: 600;
  letter-spacing: 3px;
  transition: all .3s;
}
/*
#b2b .lo-tap button:hover,
#b2b .lo-tap button:active{
  background-color: #000;
  color:#fff
}
*/
#b2b .lo-tap .member{
  border-right:none
}
@media (max-width:991px){
  #b2b .lo-tap{
    margin:1rem 0;
  }
  #b2b .lo-tap button{
    padding:.8rem 0;
    font-size:1rem
  }
}



/*--------------------------------------------------------------
	# 회원 내용
--------------------------------------------------------------*/
#b2b .minput .form-control{
  border-radius: 0;
  border-color: #000;
  margin:.5rem 0
}
#b2b .minput .form-floating>label{
  padding:1.45rem 0.75rem;
  color:#999
}

#b2b .minput .check{
  font-size:.9rem
}

#b2b .minput .idpw ul{
  text-align: right;
}
#b2b .minput .idpw ul li{
  display: inline-block;
  list-style: none;
  padding:0 10px;
  border-right:1px solid #777;
  line-height:17px;
}
#b2b .minput .idpw ul li:last-child{
  padding-right:0;
  border-right:none
}
#b2b .minput .idpw ul li a{
  color:#555
}
#b2b .minput .idpw ul li a:hover{
  color:#000
}

@media (max-width:991px){
  #b2b .minput .form-check{
    font-size:.75rem
  }
  #b2b .minput .idpw ul{
    text-align: center;
    padding-left:0;
    margin-top:1.5rem
  }
}


/* 로그인버튼 */
#b2b .minput .go-login{
  margin:1rem 0;
  background-color: #000;
  border-radius:0;
  padding:1.5rem 0;
  color:#fff;
  font-size:1.2rem;
}
@media (max-width:991px){
  #b2b .minput .go-login{
    padding:1rem 0;
    font-size:.9rem;
  }
}


/* 간편인증예약 */
#b2b .minput .simpleBooking{
  margin:1rem 0 .5rem;
  color:#0f0f0f;
  font-size:1.2rem;
  font-weight: 600;
}
#b2b .minput .SBexplain{
  font-size:.85rem;
  color:#999;
  font-weight: 300;
  padding-left:10px
}
@media (max-width:992px){
  #b2b .minput .SBexplain{
    display: block;
    padding-left:0;
    padding-top:5px;
    margin-bottom:20px
  }
}

/* sns 로그인 */
#b2b .minput .sns {
  text-align: center;
}
#b2b .minput .sns .kakao img{
  width:40px;
  padding-right:10px;
  margin-top:-3px
}
#b2b .minput .sns .kakao button{
  background-color: #F7E600;
  --bs-border-color:#b9ad00;
  width:290px;
  padding: 15px 0;
  border-radius: 5px;
  font-size: 1.05rem;
  font-weight: 600;
}

@media (min-width:992px) and (max-width:1199px){
  #b2b .minput .sns .kakao img{
    width:38px;
  }
  #b2b .minput .sns .kakao button{
    width:235px;
    padding: 17px 0;
    font-size: 1rem;
  }
}


/*--------------------------------------------------------------
	# 비회원 내용
--------------------------------------------------------------*/
#b2b .nminput .form-control{
  border-radius: 0;
  border-color: #000;
  margin:.5rem 0
}
#b2b .nminput .form-floating>label{
  padding:1.45rem 0.75rem;
  color:#999
}

#b2b .nminput .bi{
  font-size:5px;
  padding-right:5px
}
#b2b .nminput p{
  font-size:.9rem;
  color:#aaa
}

#b2b .nminput .go-reser{
  margin:1rem 0;
  background-color: #000;
  border-radius:0;
  padding:1.5rem 0;
  color:#fff;
  font-size:1.2rem;
}

@media (max-width:991px){
  #b2b .nminput p{
    font-size:.8rem;
  }
    #b2b .nminput .go-reser{
    padding:1rem 0;
    font-size:.9rem;
  }
}


/*--------------------------------------------------------------
  # 모바일화면 아이디,패스찾기
--------------------------------------------------------------*/
/* CSS 파일이나 <style> 태그 안에 추가 */
@media (max-width: 768px) {
  .searchId, 
  .searchPass
  {
    font-size: 1rem;
    vertical-align: middle;
  }
}
</style>
