<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="p-1 text-center">
        <div class="float-end mb-1">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="goToInsert()">공지등록</button>&nbsp;&nbsp; 
          <button type="button" class="btn btn-sm btn-outline-secondary" @click="updNoticeFlag('F')">상단고정(3개 이내)</button>&nbsp;&nbsp; 
          <button type="button" class="btn btn-sm btn-outline-danger" @click="updNoticeFlag('D')">삭제</button>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th style="width: 3%">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th style="width: 3%">상단고정</th>
              <th style="width: 10%">공지분류</th>
              <th style="width: 40%">공지제목</th>
              <th style="width: 20%">공지기간</th>
              <th style="width: 3%">게시설정</th>
              <th style="width: 5%">첨부</th>
              <th style="width: 5%">상태</th>
              <th style="width: 5%">링크</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(notice, i) in noticeList">
              <!-- <td class="col-1">
                <input type="checkbox" v-model="notice.seqNotice"  ref="chb_noticeSeq" class="align-baseline mt-3">
                {{ notice.seqNotice }}
              </td> -->
              <td class="col-1">
                <input type="checkbox" v-model="notice.checkFlag"  ref="chb_noticeSeq" class="align-baseline mt-3">
                <!-- {{ notice.noticeCode }} -->
              </td>
              <td v-if="notice.fixedFlag === 'Y'">Y</td>
              <td v-else></td>

              <td>
                <div v-if="notice.ctCd === 'NT'">공지</div>
                <div v-else-if="notice.ctCd === 'EV'">이벤트</div>
                <div v-else-if="notice.ctCd === 'TR'">여행관련</div>
                <div v-else-if="notice.ctCd === 'MO'">항공선박</div>
                <div v-else-if="notice.ctCd === 'PR'">홍보/안내</div>
                <div v-else>기타</div>
              </td>

              <td style="text-align: start; padding-left: 10px; vertical-align: middle;">
                <a @click="goToNoticeDetail(notice.noticeCode);" style="cursor:pointer;"><font class="text-primary">{{ notice.nTitle }}</font></a>
              </td>
              <td>{{ getDateFormat(notice.nFrom) }} ~ {{ getDateFormat(notice.nTo) }}</td>
              <td>{{ notice.nUseFlag }}</td>
              
              <td v-if="notice.addImageFlag === 'Y'">이미지</td>
              <td v-else-if="notice.addVideoFlag === 'Y'">동영상</td>
              <td v-else></td>
              
              <td>
                <button type="button" class="btn btn-warning btn-sm" @click="changeStatus(notice.nUseFlag, notice.seqNotice)">변경</button>
              </td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" @click="setLink(notice.linkFlag,notice.noticeCode,notice.link)" v-if="notice.linkFlag === 'Y'">설정</button>
                <button type="button" class="btn btn-outline-primary btn-sm" @click="setLink(notice.linkFlag,notice.noticeCode,notice.link)" v-else>설정</button>
              </td>              
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
//import moment from 'moment';
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      //productMaList: [],
      noticeList: [],
      companyInfo: [],

      allSelected: "N" //전체체크여부
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    // 데이타베이스 쿼링테스트
    //this.getProductMaList();
    this.getNoticeList();
  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }    
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    //공지내 바로가기 링크
    async setLink(linkFlag,noticeCode,link)
    {
      let linkFlag1 = ""
      //let badge = "1 + 1"
      if(linkFlag != 'Y' && linkFlag != 'N')
      {
        linkFlag1 = "N"
      }
      else
      {
        linkFlag1 = linkFlag
      }

      if(linkFlag1 =="Y")
      {
        this.$swal.fire({
          html: '바로가기의 속성(설정<->해제)을 변경합니까?',
          showCancelButton: true,
          confirmButtonText: '네',
          cancelButtonText: '아니오',
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            //let cStatus = (badgeFlag1 === "Y") ? "N" : "Y"
            let cStatus = 'N'
            //console.log("param:",cStatus, pdtCode);
            await this.$api("/api/changeNoticeFlagStatus", {param: [cStatus, link, noticeCode ]});
            // 상태변경 후, 갱신
            this.getNoticeList();
            this.$swal.fire('', '변경완료', 'success')
          }
        })
      }
      else
      {
        const { value: linkTxt } = await this.$swal.fire({
          html: '이동할 페이지의 주소창의 정보를 입력하세요(copy & paste)',
          input: 'text',
          //inputLabel: '뱃지표시',
          inputPlaceholder: link
        })

        //console.log("linkTxt:",linkTxt,"//badge:",badge)

        if (linkTxt) {
          this.$swal.fire('',`이동할 페이지주소: ${linkTxt}`)
          let cStatus = 'Y'
          await this.$api("/api/changeNoticeFlagStatus", {param: [cStatus, linkTxt, noticeCode ]});
          // 상태변경 후, 갱신
          this.getNoticeList();
          this.$swal.fire('', '생성완료', 'success')
        }
      }

      // //확인
      // this.$swal.fire({
      //   html: '뱃지를 탈착합니까?(부착<->탈착)',
      //   showCancelButton: true,
      //   confirmButtonText: '변경',
      //   cancelButtonText: '취소',
      // }).then(async (result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     let cStatus = (badgeFlag1 === "Y") ? "N" : "Y"
      //     console.log("param:",cStatus, pdtCode);
      //     await this.$api("/api/changePdtBadgeStatus", {param: [cStatus, badge, pdtCode ]});
      //     // 상태변경 후, 갱신
      //     this.getSalesList("all");
      //     this.$swal.fire('', '변경완료', 'success')
      //   }
      // })     
      // alert("nCode:"+nCode)
      // const { value: link } = this.$swal.fire({
      //   //html: '이동할 페이지의 주소창 url을 카피해서 붙여넣으세요.',
      //   input: 'text',
      //   inputLabel: '이동할 페이지의 주소창 url을 카피해서 붙여넣으세요.',
      //   inputPlaceholder: '이동할 페이지 주소'
      // })

      // if (link) {
      //   this.$swal.fire(`Entered URL: ${link}`)
      //   await this.$api("/api/makeShortcutLink", {param: [link, nCode]});
      //   // 상태변경 후, 갱신
      //   this.getSalesList("all");
      //   this.$swal.fire('', '바로가기 생성완료', 'success')        
      // }
    },
    allChoice() 
    {
      if(this.allSelected)
      {
        this.noticeList.forEach((notice) => {
          notice.checkFlag = true;
        })
      }
      else
      {
        this.noticeList.forEach((notice) => {
          notice.checkFlag = false;
        })
      }
    },

    //선택된 공지의 상태변경
    async updNoticeFlag(flag)
    {
      let selectedNotice = [];
      this.noticeList.forEach((notice) => {
        if(notice.checkFlag)
        {
          selectedNotice.push(notice.noticeCode)
        }
      })
      console.log("selectedNotice:",selectedNotice)
      //비표시처리
      if(flag === "D")
      {
        this.$swal.fire({
        html: '선택한 공지를 삭제합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedNotice.length)
            {
              let hideNotice = await this.$api("/api/hideNotice", {param: [ 'N',selectedNotice[i]]});
              if(hideNotice.affectedRows < 0 || hideNotice.affectedRows === undefined)
              {
                res = "error"
                break;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","삭제 중 에러가 발생했습니다.","error");
              return false;          
            }
            else
            {
              location.reload(true)
            }
          } 
        });
      }
      //상단고정(3개까지)
      else if(flag === "F")
      {
        //const today = moment().format('YYYY-MM-DD');

        // let cnt = 0; //유효한 상단공지 숫자(현재)
        // let j = 0;
        // while(j<this.noticeList.length)
        // {
        //   console.log("this.noticeList[j].nTo:",this.getDateFormat(this.noticeList[j].nTo))
        //   if(this.noticeList[j].fixedFlag == 'Y' && this.getDateFormat(this.noticeList[j].nTo) > today)
        //   {
        //     cnt++;
        //   }
        //   j++;
        // }
        // console.log("cnt:",cnt)
        
        if(selectedNotice.length> 3)
        {
          this.$swal.fire("","상단고정은 3개까지 입니다","warning");
          return false;
        }
        else
        {
          this.$swal.fire({
          html: '선택한 공지를 상단고정공지로 지정합니까?<br>지정되지 않은 공지들은 일반공지가 됩니다.',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오'
          }).then(async (result) => {
            if (result.isConfirmed) 
            {
              let makeFixedNotice = await this.$api("/api/makeFixedNotice", {param: [
                selectedNotice[0], selectedNotice[1], selectedNotice[2]
              ]});
              console.log("makeFixedNotice:",makeFixedNotice)
              if(makeFixedNotice.affectedRows < 0 || makeFixedNotice.affectedRows === undefined)
              {
                this.$swal.fire("","설정 중 에러가 발생했습니다.","error");
                return false;          
              }
              else
              {
                location.reload(true)
              }
            } 
          });          
        }
      }      
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },    
    async getNoticeList() {
      this.noticeList = await this.$api("/api/noticeListForAdmin", {});
      console.log("noticeList:",this.noticeList);
    },
    goToInsert() {
      this.$router.push({path: '/noticeCreate'});
    },
    goToNoticeDetail(seqNotice) {
      this.$router.push({path: '/noticeDetail', query: {seqNotice: seqNotice}});
      //console.log("oId:", orderId);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    // goToImgInsert(pdtCode) {
    //   // 이미지 저장패스
    //   this.$router.push({path: '/image_insert', query: {productId: pdtCode}});
    // },
    // goToUpdate(pdtCode, salesRouet) {
    //   // 이미지 저장패스
    //   this.$router.push({path: '/update', query: {productId: pdtCode, salesRoute: salesRouet}});
    // },
    changeStatus(nUseFlag, seqNotice) {
      console.log(nUseFlag,"/",seqNotice);
      //변경전 확인
      this.$swal.fire({
        title: '공지상태(실행<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let nStatus = (nUseFlag == "Y") ? "N" : "Y"
          await this.$api("/api/changeNoticeStatus", {param: [nStatus, seqNotice]});
          // 상태변경 후, 갱신
          this.getNoticeList();
          this.$swal.fire('변경완료!', '', 'success')
        }
      })
    }
  }
}
</script>