<template>
  <div id="setsetset_bsu">
    <div class="set-fst_section_bsu">
      <img class="set-fst-bg-bsu" src="../assets/img/setsetset/fstbg.jpg" alt="">
      <div class="set-fst_sc_imgs_bsu">
        <img class="imgfsttit" src="../assets/img/setsetset/main_title.png" alt="">
      </div>
    </div>

    <div class="set-scd_section_bsu">
      <div class="set-scd-whatset_bsu">
        <img class="whatset" src="../assets/img/setsetset/whatset.png" alt="">
        <div class="set-warning_bsu">
          <img class="warning" src="../assets/img/setsetset/warning.png" alt="">
        </div>
      </div>
    </div>

    <div class="set-thr_section_bsu">
      <div class="set-thr_product_bsu">
        <img src="../assets/img/setsetset/saletitle.png" alt=""> 
      </div>
      <div class="set-thr_sapporo_bsu">
        <div class="set-thr_sapporo-pd_bsu">
          <img class="spr01_bsu" src="../assets/img/setsetset/biei_nobori2.png" @click="goToDetail('L-24112272FYC', 'L')" alt="">
          <img class="spr02_bsu" src="../assets/img/setsetset/biei_hoheykyo2.png" @click="goToDetail('L-24112284HUC', 'L')" alt="">
          <img class="spr03_bsu" src="../assets/img/setsetset/biei_oshiho2.png" @click="goToDetail('L-24112299HEC', 'L')" alt="">
          <img class="spr04_bsu" src="../assets/img/setsetset/aso2.png" @click="goToDetail('L-24112279XGC', 'L')" alt="">
          <img class="spr05_bsu" src="../assets/img/setsetset/yamaguchi2.png" @click="goToDetail('L-24112233UNC', 'L')" alt="">
        </div>
      </div>
    </div>
  </div>
  </template>
  
<script>
export default {
  data() {},
  computed: {
    user() {
      return this.$store.state.user;
    }   
  },  
  methods: {
    goToDetail(productId, salesRoute) {
    this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  }
}
  </script>
  
  <style>
    #setsetset_bsu{
      margin-top: -56px;
      position: relative;
      width: 100%;
      height: 100%;
    }
    .set-fst_section_bsu{
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    }
  .set-fst_section_bsu .set-fst-bg-bsu {
    width: 100%;
  }

  .set-fst_sc_imgs_bsu .imgfsttit{
    top: 50%;
    width: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }

.set-scd_section_bsu {
  width: 100%;
  height: 100%;
  background-color: #ffc519;
  position: relative;
}
.set-scd-whatset_bsu{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.set-warning_bsu{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
}
.whatset{
  transition: all 0.4s;
}
.warning{
  margin-bottom: 100px;
  transition: all 0.4s;
}
.whatset:hover{
  transform:translateY(-8px);
}
.warning:hover{
  transform:translateY(-8px);
}

.set-thr_section_bsu{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #ffb039;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid #000 2px;
  border-right: 0;
  border-left: 0;
  padding: 150px 0 150px 0;
  }

.set-thr_section_bsu .set-thr_product_bsu{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block: 20px ;
}

.set-thr_product_bsu img {
  display: flex;
}


.set-thr_sapporo_bsu{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.set-thr_sapporo_bsu{
  margin-bottom: 60px;
  margin-top: 60px;
}

.set-thr_sapporo-pd_bsu{
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.set-thr_sapporo-pd_bsu img{
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(77%/3);
  margin-bottom: 60px;
}

.set-thr_sapporo-pd_bsu img:hover{
  transform:translateY(-8px);
}
.set-thr_sapporo-pd_bsu .spr03_bsu {
  margin-right: 0;
}

.set-thr_sapporo-pd_bsu .spr04_bsu{
  margin-bottom: 0;
}

.set-thr_sapporo-pd_bsu .spr05_bsu {
  margin-right: 0;
  margin-bottom: 0;
}


@media(max-width: 767px) {
  #setsetset_bsu{
      margin-top: 0;
    }
  .set-fst_section_bsu {
    position: relative;
    width: 100%;
    padding-top: 15px;
  }
  .set-fst_section_bsu {
  position: relative;
  width: 100%;
}
.set-fst_sc_imgs_bsu {
  width: 100%;
}
.set-fst_sc_imgs_bsu .imgfsttit{
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
.whatset{
  width: 90%;
}
.set-warning_bsu{
  margin-top: 50px;
}
.warning{
  width: 90%;
  margin-bottom: 60px;
}


.set-thr_section_bsu{
  padding: 0;
}

.set-thr_section_bsu .set-thr_product_bsu {
    width: 100%;
    margin-block: 40px;
}
.set-thr_sapporo_bsu{
  margin-bottom: 60px;
  margin-top: 0px;
}
.set-thr_sapporo_bsu img{
  width: 100%;
  margin-right: 0;
}
.set-thr_product_bsu img {
    width: 80%;
    
}
.set-thr_sapporo-pd_bsu .spr04_bsu{
  margin-bottom: 30px;
}


.set-thr_sapporo-pd_bsu{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.set-thr_sapporo-pd_bsu img{
  cursor: pointer;
  transition: all 0.5s;
  width: 80%;
  margin-bottom: 30px;
}
}

@media (min-width: 768px) and (max-width: 1023px) {

.la-fst_section_hj {
  position: relative;
  width: 100%;
}
.la-fst_section_hj .la-fst-bg-hj1 {
  position: relative;
  width: 100%;
}

.set-fst_sc_imgs_bsu {
  width: 100%;
}
.set-fst_sc_imgs_bsu .imgfsttit{
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
  .set-fst_sc_imgs_bsu .imgfstpic{
    top: 80%;
    width: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

.whatset{
  width: 90%;
}
.set-warning_bsu{
  margin-top: 50px;
}
.warning{
  margin-bottom: 60px;
  width: 90%;
}

}

</style>
