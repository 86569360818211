<template>
  <main id="newMain">
    <div id="app" class="main-container">
      
      <!-- Header Section -->
      <header class="d-flex justify-content-between align-items-center py-3 top-menu">
        <div class="mlogo">
          <a href="/main_test">
            <img src="../../src/assets/img/Ntabi.png" class="logo" alt="Ntabi">
          </a>
          <img src="../../src/assets/img/pBus.png" alt="" style="width:30px;">
          <!-- <img src="../../src/assets/img/pBus.png" alt="" style="width:30px;" v-if="this.user.email != undefined && this.user.memLevel =='P'">
          <img src="../../src/assets/img/sBus.png" alt="" style="width:30px;" v-else-if="this.user.email != undefined && this.user.memLevel =='S'">
          <img src="../../src/assets/img/gBus.png" alt="" style="width:30px;" v-else-if="this.user.email != undefined && (this.user.memLevel != 'P' && this.user.memLevel != 'S')"> -->          
        </div>

        <nav>
          <ul class="nav" style="display:flex; align-items: center;">
            <li class="nav-item" v-if="user.email == undefined">
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toB2BLoginPage">로그인</a>
            </li>
            <li class="nav-item" v-else>
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toB2BLoginPage">로그아웃</a>
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toMembership" v-if="user.email == undefined">회원가입</a>
            </li>
            <li class="nav-item">
              <a href="https://smartstore.naver.com/ntabi" target='_blank' class="nav-link blink fw-bold" style="color:green;">네이버예약</a>
            </li>
            <li class="nav-item">
              <a href="https://smartstore.naver.com/somen" target='_blank' class="nav-link" style="color:green;">소면공방</a>
            </li>
            <div class="input-with-icon">
              <li class="nav-item"><input type="text" class="form form-text-sm searchBox text-primary"></li>
            </div>          
          </ul>
        </nav>
      </header>

      <!-- Main Banner Section -->
      <section :style="{ backgroundColor: currentBackgroundColor }" class="main-banner text-center py-4 text-white" ref="mainBanner">
        <div class="justify-content-center my-4 ct-position mx-auto"  @click="handleMainBannerClick">
          <table class="main-banner-table cap" style="height: 400px;">
            <tr style="vertical-align:middle;">
              <td style="text-align:start; padding-left:8%; width:45%">
                <!-- 텍스트 블록 -->
                <div class="text-container">
                  <div id="banner1-text" class="banner-text">
                    <div style="font-size:2.5rem;">ddd, 연간 무제한1</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner2-text" class="banner-text">
                    <div style="font-size:2.5rem;">iranroll, 연간 무제한2</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner3-text" class="banner-text">
                    <div style="font-size:2.5rem;">tate, 연간 무제한3</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner4-text" class="banner-text">
                    <div style="font-size:2.5rem;">taiwan, 연간 무제한4</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner5-text" class="banner-text">
                    <div style="font-size:2.5rem;">kustsu, 연간 무제한5</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner6-text" class="banner-text">
                    <div style="font-size:2.5rem;">arch, 연간 무제한6</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                  <div id="banner7-text" class="banner-text">
                    <div style="font-size:2.5rem;">aso, 연간 무제한7</div>
                    <div style="font-size:2.5rem;">엔데이트립 멤버십</div>
                    <div class="mt-0" style="font-size:1.5rem;font-family: noto-sans sans-serif;">지금 가입하세요!</div>
                    <div class="mt-5">
                      <button class="main-banner-btn text-center">&nbsp;&nbsp;자세히 보기&nbsp;&nbsp;</button>
                    </div>
                  </div>
                </div>
              </td>
              <td style="width:55%;">
                <div class="justify-content-center my-4 main-center image-container">
                  <img src="../assets/img/main/ddd.jpg"      alt="banner1" class="image-stack" id="banner1">
                  <img src="../assets/img/main/iranroll.jpg" alt="banner2" class="image-stack" id="banner2">
                  <img src="../assets/img/main/tate.jpg"     alt="banner3" class="image-stack" id="banner3">
                  <img src="../assets/img/main/taiwan.jpg"   alt="banner4" class="image-stack" id="banner4">
                  <img src="../assets/img/main/kusatsu.jpg"  alt="banner5" class="image-stack" id="banner5">
                  <img src="../assets/img/main/arch.jpg"     alt="banner6" class="image-stack" id="banner6">
                  <img src="../assets/img/main/aso.jpg"      alt="banner7" class="image-stack" id="banner7">
                </div>
              </td>
            </tr>
          </table>

          <!-- 인디케이터 (•) -->
          <div class="indicator-container">
            <!-- 인디케이터 표시 -->
            <span v-for="(image, index) in images" :key="index">
              <span v-if="index === this.currentImageIndex" style="color:yellow;font-size:2rem;font-weight: 600;">
                <i class="bi bi-balloon-heart"></i>
              </span>
              <span v-else style="color: white;">•</span>
            </span>
            <!-- <span v-for="(image, index) in images" :key="index" :class="{ 'active-indicator': index+1 === this.currentImageIndex }">
              •
            </span> -->
          </div>

        </div>
      </section>

      <!-- 상단메뉴 -->
      <div class="justify-content-center my-4 ct-position mx-auto main-left">
        <table class="border w-100 text-center">
          <tr class="border">
            <td class="mainCategory">
              <button type="btn" class="btn btn-primary btn-lg" @click="showNdt('ND');scrollToElement('saleitem')">Ndaytrip</button>
            </td>
            <td class="mainCategory">
              <button type="btn" class="btn btn-primary btn-lg" @click="showNdt('NT');scrollToElement('saleitem')">Ntabi</button>
            </td>
            <td class="mainCategory">
              <button type="btn" class="btn btn-primary btn-lg" @click="showNdt('VR');scrollToElement('saleitem')">VarietyPack</button>
            </td>
          </tr>
          <tr>
            <td class="mainCategory">
              <button type="btn" class="btn btn-success btn-lg" @click="showNdt('JShip');scrollToElement('saleitem')">일본선박</button>
            </td>
            <td class="mainCategory">
              <button type="btn" class="btn btn-success btn-lg" @click="showNdt('MShip');scrollToElement('saleitem')">멤버십</button>
            </td>
            <td class="mainCategory">
              <button type="btn" class="btn btn-success btn-lg" @click="showNdt('TkPass');scrollToElement('saleitem')">티켓/패스</button>
            </td>
          </tr>        
        </table>
      </div>

      <!-- 상단메뉴 연동 -->
      <section class="mt-5 saleitem" id="saleitem">
        <div>
          <div v-if="sellerFlag === 'ND'">
            <div class="justify-content-center my-4 ct-position mx-auto main-left">
              <div class="d-flex justify-content-between align-items-center">
                <div class="section-title">
                  <h2 class="section-title">최고의 1일, 엔데이트립</h2>
                  <p class="section-description">코스별 전문가이드가 동행하는 1일 버스투어브랜드입니다</p>
                </div>
                <div class="text-end">
                  <a href="/bustour">더보기</a>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="sellerFlag === 'NT'">
            <div class="justify-content-center my-4 ct-position mx-auto main-left">
              <div class="d-flex justify-content-between align-items-center">
                <div class="section-title">
                  <h2 class="section-title">엔타비가 추천하는 여행상품</h2>
                  <!-- <p class="section-description">코스별 전문가이드가 동행하는 1일 버스투어브랜드입니다</p> -->
                </div>
                <div class="text-end">
                  <a href="/bustour">더보기</a>
                </div>
              </div>
            </div>
          </div>
          
          <div v-else-if="sellerFlag === 'VR'">
            <div class="justify-content-center my-4 ct-position mx-auto main-left">
              <div class="d-flex justify-content-between align-items-center">
                <div class="section-title">
                  <h2 class="section-title">버라이어티팩</h2>
                  <p class="section-description">일반적인 패키지 여행이 아닌 시즌별, 기간별 한정 이벤트여행, 기획여행, 테마여행 상품 브랜드입니다</p>
                </div>
                <div class="text-end">
                  <a href="/bustour">더보기</a>
                </div>
              </div>
            </div>
          </div>                    

          <div class="justify-content-center my-4 ct-position mx-auto">
            <table class="w-100 text-center">
              <tr>
                <td class="col-6">
                  <div class="nday">
                    <div class="card procuct-detail">
                      <div v-if="this.selFlag === 'N'">
                        <img :src="`/download/noImage/noSelectedPDT.png`" class="card-img-top" style="height:500px;" alt="no image"/>
                        <h5 class="nday-body">
                          <h5 class="nday-title">
                            선택상품이 업습니다
                          </h5>
                          <!-- <p class="showPdtName">
                            상품리스트 이미지에 마우스를 올려놓으세요
                          </p> -->
                        </h5>
                      </div>
                      <div v-else>
                        <img v-if="this.pImgName != 'noImage'" :src="`/download/${this.pCode}/${this.pImgName}`" class="card-img-top" style="height:500px;" alt="..."/>
                        <img v-else :src="`/download/noImage/noSelectedPDT.png`" class="card-img-top" style="height:500px;" alt="no image"/>

                        <!-- 뱃지표시 -->
                        <div class="badge">
                          <div v-if="this.badgeF1 != ''">
                            <div class="badge1">
                              <small class="badge1-text" style="font-size: 1rem;">{{ this.badge1 }}</small>
                            </div>
                          </div>
                          <div v-if="this.badgeF2 != ''">
                            <div v-if="this.badgeF1 == ''" class="badge1">
                              <small class="badge1-text" style="font-size: 1rem;">{{ this.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left:10rem;">
                              <small class="badge2-text" style="font-size: 1rem;">{{ this.badge2 }}</small>
                            </div>
                          </div>
                        </div>
                        
                        <h5 class="nday-body">
                          <h5 class="nday-title">
                            {{ this.pArName }}
                          </h5>
                          <p class="showPdtName">
                            {{ this.pName }}
                          </p>
                        </h5>
                      </div>

                      <div class="card-body">
                        <div class="nday-price" v-if="this.selFlag === 'N'">
                          <div style="height: 80px;">
                            <p class="showPdtName text-secondary">
                              상품리스트에 마우스를 올려놓아보세요
                            </p>  
                          </div>
                        </div>
                        <div class="nday-price" v-else>
                          <div v-if="this.user.memLevel === 'P'">
                            <div v-if="selectedPdtInfo.dispPrice === 'mem'">
                              <div v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                              </div>
                              <div v-else>
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice)}}</b>
                                <small><font>원~</font></small>
                                <small style="color:white;font-size: 0.8rem;" v-if="selectedPdtInfo.memP*1 > 0"> (결제 후,</small>
                                <b class="mx-1" style="color:#ffce00;font-size: 0.9rem;" v-if="selectedPdtInfo.memP*1 > 0">{{ getCurrencyFormat(selectedPdtInfo.memP)}}</b>
                                <small style="color:white;font-size: 0.8rem;" v-if="selectedPdtInfo.memP*1 > 0">원 환급)</small>
                              </div>
                            </div>
                            <div v-else>
                              <div v-if="selectedPdtInfo.promoStatus === 'Y'">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">                                  
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div v-else-if="selectedPdtInfo.promoStatus === 'E'">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">                                  
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div>
                          </div>

                          <div v-else-if="this.user.memLevel === 'S'">
                            <div v-if="selectedPdtInfo.dispPrice === 'mem'">
                              <div v-if="selectedPdtInfo.dcForStd > 0">
                                <div>
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">
                                    {{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.dcForStd/100)))}} <small><font> 원~</font></small>
                                  </b>
                                </div>
                                <div>
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <b class="mx-1" v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">0원! <span style="font-size:small;">(연간무제한)</span></b>
                                  <b class="mx-1" v-else>{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_P/100)))}}
                                    <small><font> 원~</font></small>
                                  </b>
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memS">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                                </div>
                                <div v-else>
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice)}} <small><font>원~</font></small></b>
                                  <small style="color:white;font-size: 0.8rem;" v-if="selectedPdtInfo.memS*1 > 0"> (결제 후, </small>
                                  <b class="mx-1" style="color:slategrey;font-size: 0.9rem;" v-if="selectedPdtInfo.memS*1 > 0">{{ getCurrencyFormat(selectedPdtInfo.memS)}}</b>
                                  <small style="color:white;font-size: 0.8rem;" v-if="selectedPdtInfo.memS*1 > 0">원 환급) </small>
                                </div>
                                <div>
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <b class="mx-1" v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">
                                    0원! <span style="font-size:small;">(연간무제한)</span>
                                  </b>
                                  <b class="mx-1" v-else>{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_P/100)))}}
                                    <small><font> 원~</font></small>
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div v-else>
                              <div v-if="selectedPdtInfo.promoStatus === 'Y'">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div v-else-if="selectedPdtInfo.promoStatus === 'E'">
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div>
                          </div>
                          
                          <!-- 비로그인, 일반회원 멤버십표시 -->
                          <div v-else>
                            <!-- 일반회원가격(default) -->
                            <div class="my-1" v-if="selectedPdtInfo.promoStatus === 'Y'">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice)}}</b>
                                <small><i class="bi bi-caret-right-fill"></i></small>
                              <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                            <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'E'">
                              <div class="my-1">
                                <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div> 
                            </div>
                            <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'N'">
                              <div class="my-1" v-if="(selectedPdtInfo.dcForMem_S > 0)">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">
                                  {{ getCurrencyFormat(selectedPdtInfo.pPrice)}} <small><font>원~</font></small>
                                </b>
                              </div>
                              <div v-else class="my-1">
                                <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                <b class="mx-1">
                                  {{ getCurrencyFormat(selectedPdtInfo.pPrice)}} <small><font>원~</font></small>
                                </b>
                              </div>
                            </div>

                            <div v-if="((selectedPdtInfo.memLevel_S == 'N' || selectedPdtInfo.memLevel_S == null) && (selectedPdtInfo.memLevel_P == 'N' || selectedPdtInfo.memLevel_P == null)) || (selectedPdtInfo.nrFlag == 'Y' || selectedPdtInfo.vrFlag == 'Y')">
                              <div class="my-1" v-if="selectedPdtInfo.promoStatus === 'E'">
                                <div>
                                  <div class="my-1">
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - selectedPdtInfo.price_dcForMem_S)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                  <div class="my-1">
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - selectedPdtInfo.price_dcForMem_P)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                </div> 
                              </div>
                              <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                <div>
                                  <div class="my-1">
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - selectedPdtInfo.price_dcForMem_S)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                  <div class="my-1">
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - selectedPdtInfo.price_dcForMem_P)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                </div> 
                              </div>
                            </div>

                            <!-- 스탠다드멤버십 상품 -->
                            <div class="my-1" v-if="selectedPdtInfo.memLevel_S === 'Y' && ((selectedPdtInfo.nrFlag === 'N' || selectedPdtInfo.nrFlag == null) && (selectedPdtInfo.vrFlag === 'N' || selectedPdtInfo.vrFlag == null))">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                              <b class="mx-1" v-if="getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_S/100))) == 0">
                                0원! <span style="font-size:small;">(연간무제한)</span>
                              </b>
                              <b class="mx-1" v-else>
                                {{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_S/100)))}} <small><font> 원~</font></small>
                              </b>
                            </div>

                            <!-- 프리미엄멤버십 상품 -->
                            <div class="my-1" v-if="selectedPdtInfo.memLevel_P === 'Y' && ((selectedPdtInfo.nrFlag === 'N' || selectedPdtInfo.nrFlag == null) && (selectedPdtInfo.vrFlag === 'N' || selectedPdtInfo.vrFlag == null))">
                              <div class="my-1" v-if="(selectedPdtInfo.memLevel_s == 'N' || selectedPdtInfo.memLevel_s == null) && (selectedPdtInfo.dcForStd > 0)">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">
                                  {{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.dcForStd/100)))}} <small><font> 원~</font></small>
                                </b>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1" v-if="getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_P/100))) == 0">
                                  0원! <span style="font-size:small;">(연간무제한)</span>
                                </b>
                                <b class="mx-1" v-else>
                                  {{ getCurrencyFormat(selectedPdtInfo.sMinPrice*1 - (selectedPdtInfo.sMinPrice*(selectedPdtInfo.maxDC_P/100)))}} <small><font> 원~</font></small>
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: top;" class="col-6">
                  <div class="product-list">
                    <transition :name="animationDirection">
                      <div v-if="this.pagedProductList.length > 0" key="product-list">
                        <div class="row" :key="i" v-for="(pdt, i) in this.pagedProductList">
                          <div class="col-lg-3 mt-4">
                            <img v-if="pdt.imgName != 'noImage'" :src="`/download/${pdt.pdtCode}/${pdt.imgName}`" class="img-fluid hover-zoom pdt-img" style="width:500px; border-radius:8%" alt="..." @mouseover="setHoveredProduct(pdt.pdtCode, pdt.pdtNameKor, pdt.arName, pdt.imgName, pdt.badgeFlag, pdt.badge, pdt.badgeFlag2, pdt.badge2)"
                            />
                            <img v-else :src="`/download/noImage/noImage.jpg`" class="img-fluid pdt-img" style="width:500px; border-radius: 10%;" alt="no image"/>
                          </div>
                          <div class="c-content col-lg-9 text-start align-self-center" style="padding-left:10px;">
                            <h5 class="product-title">{{ pdt.pdtNameKor }}</h5>
                            <p class="product-description">{{ pdt.pdtRemarks }}</p>
                            <div class="product-container">
                              <!-- <span class="price">가격: {{ pdt.sMinPrice }}</span> -->
                              <span class="view-product" style=" padding-right: 20px;">
                                <button class="btn btn-outline-primary btn-sm" style="font-size:x-small;" @click="goToDetail(pdt.pdtCode, pdt.salesRoute)">
                                  <i class="bi bi-caret-right-fill"></i>상품보기
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="pagination text-end">
                    <button @click="changePage('prev')" :disabled="currentPage === 1"><i class="bi bi-arrow-left-square-fill"></i></button>
                    <span>{{ currentPage }} / {{ totalPages }}</span>
                    <button @click="changePage('next')" :disabled="currentPage === totalPages"><i class="bi bi-arrow-right-square-fill"></i></button>
                  </div>

                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <!-- Video Section -->
      <section class="video-section ct-position mx-auto main-left">
        <div class="video" id="ntabi-video">
          <video muted autoplay loop>
            <source src="../assets/img/sakura.mp4" type="video/mp4">
          </video>
          <div class="video-text">
            <p class="text-warning">Ntabi / Ndaytrip</p>
          </div>
        </div>        
      </section>

      <!-- tour product carousel -->
      <section class="carousel-section py-4 mt-5 ct-position mx-auto main-left">
        <div id="tourCarousel-container">
          <div class="scrolling-wrapper" style="align-items: right;">
            <div class="row">
              <div class="col-md-4">
              </div>
              <div class="col-md-4"  style="padding-right:15px;">
                <div class="card tourCard">
                  <img src="tour1.jpg" class="card-img-top" alt="투어 상품 1">
                  <div class="card-body">
                    <h5 class="card-title">투어 상품 1</h5>
                    <p class="card-text">134,700원~</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card tourCard">
                  <img src="tour2.jpg" class="card-img-top" alt="투어 상품 2">
                  <div class="card-body">
                    <h5 class="card-title">투어 상품 2</h5>
                    <p class="card-text">799,000원~</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- mid-banner -->
      <section class="ct-position mx-auto main-left">
        <div class="mid-banner d-flex justify-content-center">
          <a href="/membershipPage">
            <img src="../assets/img/new-test/banner3.jpg" class="img-fluid pc">
            <img src="../assets/img/new-test/banner3-2.jpg" class="img-fluid pad">
            <img src="../assets/img/new-test/banner3-3.jpg" class="img-fluid mobile">
          </a>
        </div>
      </section>

      <!-- ======= 베스트아이템 ======= -->
      <section id="best-item" class="bestitem ct-position mx-auto main-left">
        <div class="container">
          <div class="section-title">
            <p><b class="text-danger" style="font-family:Arial Black, Verdana, Geneva, Tahoma, sans-serif; font-size:2.5rem;">
              <i>BEST <span class="text-primary">SELLER</span></i>
            </b></p>
          </div>
          <div class="row gx-2 mt-3">
            <div class="col-12 col-md-6 col-lg-4 my-2" :key="i" v-for="(product, i) in this.productListForSales_best">
              <a class="card" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">
                <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                <!-- 뱃지표시 -->
                <div class="badge">
                  <div v-if="product.badgeFlag === 'Y'">
                    <div class="badge1" style="width:140px;">
                      <small class="badge1-text" style="font-size: .8rem;">{{ product.badge }}</small>
                    </div>
                  </div>
                  <div v-if="product.badge2Flag === 'Y'">
                    <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width:140px;">
                      <small class="badge1-text" style="font-size: .8rem;">{{ product.badge2 }}</small>
                    </div>
                    <div v-else class="badge2" style="margin-left:10rem;width:140px;">
                      <small class="badge2-text" style="font-size: .8rem;">{{ product.badge2 }}</small>
                    </div>
                  </div>
                </div>
                <!-- 카드 제목 -->
                <div class="card-body">
                  <h5 class="card-title">
                    <div class="b-height" v-if="product.cityName != undefined">
                      <small><small><small><small class="align-top"></small></small></small></small>{{ product.pdtNameKor }}
                    </div>
                    <div v-else>
                      {{ product.pdtNameKor }}
                    </div>
                    <div class="mt-3">
                      <div v-if="this.user.memLevel === 'P'">
                        <div v-if="product.dispPrice === 'mem'">
                          <div v-if="product.sMinPrice === product.memP">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                          </div>
                          <div v-else>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">
                              {{ getCurrencyFormat(product.sMinPrice)}}</b>
                            <small><font>원~</font></small>
                            <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0"> (결제 후,</small>
                            <b class="mx-1" style="color:#ffce00;font-size: 0.9rem;" v-if="product.memP*1 > 0">{{ getCurrencyFormat(product.memP)}}</b>
                            <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0">원 환급)</small>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="product.promoStatus === 'Y'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'N'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'E'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>
                      </div>

                      <div v-else-if="this.user.memLevel === 'S'">
                        <div v-if="product.dispPrice === 'mem'">
                          <div v-if="product.dcForStd > 0">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice-(product.sMinPrice*(product.dcForStd/100)))}}</b>
                          </div>
                          <div v-else>
                            <div v-if="product.sMinPrice === product.memS">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                            </div>
                            <div v-else>
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><font>원~</font></small>
                              <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0"> (결제 후, </small>
                              <b class="mx-1" style="color:slategrey;font-size: 0.9rem;" v-if="product.memS*1 > 0">{{ getCurrencyFormat(product.memS)}}</b>
                              <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0">원 환급) </small>
                            </div>
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <b class="mx-1" v-if="product.sMinPrice === product.memP">0원! <span style="font-size:small;">(연간무제한)</span></b>
                            <b class="mx-1" v-else>{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}}
                              <small><font> 원~</font></small>
                            </b>
                          </div>
                        </div>
                        
                        <div v-else>
                          <div v-if="product.promoStatus === 'Y'">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'N'">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'E'">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>
                      </div>
                      <!-- 로그인전 상품도 멤버십표시 -->
                      <div v-else>
                        <!-- 일반회원가격(default) -->
                        <div class="my-1" v-if="product.promoStatus === 'Y'">
                          <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                          <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                            <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div class="my-1" v-else-if="product.promoStatus === 'E'">
                          <div>
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div> 
                        </div>
                        <div class="my-1" v-else-if="product.promoStatus === 'N'">
                          <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                          <div class="my-1" v-if="(product.dcForMem_S > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else>
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>

                        <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                          <div class="my-1" v-if="product.promoStatus === 'E'">
                            <div class="my-1">
                              <div>
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div>
                              <div class="my-1">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div> 
                          </div>
                        </div>

                        <!-- 스탠다드멤버십 상품 -->
                        <div class="my-1" v-if="product.memLevel_S === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                          <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                          <b class="mx-1" v-if="product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100)) == 0">
                            0원! <span style="font-size:small;">(연간무제한)</span>
                          </b>
                          <b class="mx-1" v-else>
                            {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100)))}} <small><font> 원~</font></small>
                          </b>
                        </div>

                        <!-- 프리미엄멤버십 상품 -->
                        <div class="my-1" v-if="product.memLevel_P === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                          <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && (product.dcForStd > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.dcForStd/100)))}}</b>
                            <small><font> 원~</font></small>
                          </div>
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                          <b class="mx-1" v-if="product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)) == 0">
                            0원! <span style="font-size:small;">(연간무제한)</span>
                          </b>
                          <b class="mx-1" v-else>
                            {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}} <small><font> 원~</font></small>
                          </b>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
              </a>
            </div>

            <!-- 더보기 -->
            <div class="col-lg-12 d-flex justify-content-end">
              <div id="section-flters">
                <div v-if="this.more_be === 'N' || this.more_be === ''">
                  <a href="javascript: void(0)" @click = "getProductList('X','','BE')">
                    더보기
                  </a>
                </div>
                <div v-else>
                  <a href="javascript: void(0)" @click = "getProductList('','','BE')">
                    접기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!-- Live Section -->
      <section class="live-section py-4 mt-2 ct-position mx-auto main-left">
        <h3 class="text-start">엔데이 라이브</h3>
        <p class="mt-0 mb-0">지역전문 한국인 가이드가 진행하는 라이브 방송입니다</p>
        <div class="row">
          <div class="iframe-container">
            <iframe src="https://smartstore.naver.com/ntabi/lives?cp=1" frameborder="0" scrolling="no"></iframe>
          </div>
        </div>
      </section>

      <!-- Notice & Event Section -->
      <section class="notice-event-section py-4 mt-0 ct-position mx-auto main-left" ref="noticeSection">
        <h3 class="text-start">공지사항 & 이벤트</h3>
        <div class="row">
          <table class="border w-100 text-center">
            <tr class="border">
              <td rowspan="4">
                <div>
                  <div class="card">
                    <img src="yamguchi.jpg" class="card-img-top" style="height:300px;" alt="야마구치">
                    <div class="card-body">
                      <h5 class="card-title">야마구치</h5>
                      <p class="card-text">49,000원~</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td>
                <div>
                  <div class="card">
                    <img src="yamguchi.jpg" class="card-img-top" alt="야마구치">
                    <div class="card-body">
                      <h5 class="card-title">야마구치</h5>
                      <p class="card-text">49,000원~</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td>
                <div>
                  <div class="card">
                    <img src="yamguchi.jpg" class="card-img-top" alt="나고야">
                    <div class="card-body">
                      <h5 class="card-title">나고야</h5>
                      <p class="card-text">49,000원~</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td>
                <div>
                  <div class="card">
                    <img src="yamguchi.jpg" class="card-img-top" alt="규슈">
                    <div class="card-body">
                      <h5 class="card-title">규슈</h5>
                      <p class="card-text">49,000원~</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <ul>
          <li v-for="(event, index) in events" :key="index">
            <span>[{{ event.category }}]</span> {{ event.text }} <span>{{ event.date }}</span>
          </li>
        </ul>
      </section>

      <!-- Remote Control Section -->
      <div ref="rtRemoteContainer" class="rt-remote-container">
        <MainRtRemote :is-hidden="isHidden" />
      </div>
    
    </div>
  </main>
</template>

<script>
import MainRtRemote from '../components/Main_rtRemote.vue';
import { recentView } from '../assets/js/recentView';

export default {
  components: {
    MainRtRemote,
  },
  data() {
    return {
      // 상품상하스크롤처리
      currentPage: 1,
      itemsPerPage: 5,                      // 페이지당 표시할 상품 수
      animationDirection: "slide-up",       // 기본 애니메이션 방향

      currentBackgroundColor: '#007bff',    // 초기 배경색 설정
      autoScrollInterval: null,             // 자동 슬라이드 interval 저장
      autoScrollTime: 3000,                 // 5초 간격으로 슬라이드
      transitionInProgress: false,          // 슬라이드 중복 방지
      images: [],                           // 이미지 엘리먼트를 저장
      positions: [
        'image-hidden-left',
        'image-left',
        'image-center',
        'image-right',
        'image-hidden-right',
        'image-hidden-far-left',            // 추가된 두 개의 위치 <- 메인배너 이미지 변동에 연동
        'image-hidden-far-right'         
      ],
      backgroundColors: ['#007bff', '#ff5733', '#33ff57', '#ff33a2', '#5733ff', '#57550f', '#7733ff'], // 배경색 배열
      currentImageIndex: 0,  // 현재 중앙 이미지 인덱스

      productListForSales: [], //판매용 상품리스트

      productListForSales_ntabi: [],
      productListForSales_ndaytrip: [],
      productListForSales_ndaytrip_main: [],
      productListForSales_nthere: [],
      productListForSales_variety: [],
      productListForSales_best: [],   //베스트아이템

      sellerFlag: "nt", //판매처(nt / nd)

      //상단 카테코리 선택처리
      pCode: "",                            //상품코드
      pName: "",                            //상품명
      pArName: "",                          //지역명
      pImgName: "",

      selectedPdtInfo: [],                  //선택된 상품정보
      productListForSales_mid: [],          //선택된 카테고리의 상품정보
      selFlag: "N",                         //상품선택플래그
      badgeF1: "",                          //상품1뱃지 여부
      badgeF2: "",                          //상품2뱃지 여부
      badge1: "",                           //상품1뱃지
      badge2: "",                           //상품2뱃지





      more_nt: "N",
      more_nd: "",
      more_nr: "",
      more_vr: "",
      more_be: "N",      //베스트상품

      memLv: "",         //멤버십등급(S,P)
      memDC: [0,0,0,],    //멤버십환급율(성인,소인,유아)

      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
      price_memP: 0,        //프리미엄 환급액
      price_memS: 0,        //스탠다드 환급액
      price_dcForStd: 0,    //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,  //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,  //일반상품을 스탠다드가 구매시 할인액





      liveItems: [
        { title: 'Live Item 1', image: 'live1.jpg', price: '59,000원~' },
        { title: 'Live Item 2', image: 'live2.jpg', price: '69,000원~' },
        // 추가 라이브 아이템들...
      ],
      events: [
        { category: '이벤트', text: '공짜 투어 참여하기!', date: '2024-08-08' },
        { category: '공지사항', text: '여행 후기 이벤트!', date: '2024-08-05' },
        // 추가 이벤트 및 공지...
      ]      
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    //상품스크롤용
    pagedProductList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.productListForSales_mid.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.productListForSales_mid.length / this.itemsPerPage);
    },    
  },


  created() {
    // path에서 사용된 productId
    this.user = this.$store.state.user;
    this.conEmail = this.user.email;
    console.log("user:",this.user, "/", this.memberId, "/", this.conEmail);

    this.getProductList(); 
    //this.getNoticeList("Y"); //공지&이벤트 리스트
  },  
  mounted() {
    // 메인배너 이미지 엘리먼트 찾기
    this.images = this.$el.querySelectorAll('.image-stack'); 
    this.initImageRotation();
    this.startAutoScroll();

    //this.showNdt('ND')      //페이지 초기오픈
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.autoScrollInterval); // 페이지가 사라질 때 자동 슬라이드를 멈춤
  },
  methods: {

    //중간상품이미지 상하스크롤처리
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    changePage(direction) {
      if (direction === "prev" && this.currentPage > 1) {
        this.animationDirection = "slide-down"; // 이전 버튼: 아래로 이동
        this.currentPage--;
      } else if (direction === "next" && this.currentPage < this.totalPages) {
        this.animationDirection = "slide-up"; // 다음 버튼: 위로 이동
        this.currentPage++;
      }
    },

    setHoveredProduct(pCode, pName, pArName, pImgName, badgeF1, badge1, badgeF2, badge2){
      //alert(pCode + "/" + pName)
      this.selFlag  = "Y"
      this.pCode    = pCode
      this.pName    = pName
      this.pArName  = pArName
      this.pImgName = pImgName
      this.badgeF1  = (badgeF1 === "" || badgeF1 == undefined || badgeF1 == null) ? "" : badgeF1
      this.badge1   = badge1
      this.badgeF2  = (badgeF2 === "" || badgeF2 == undefined || badgeF2 == null) ? "" : badgeF2
      this.badge2   = badge2

      let selectedPdtInfo = this.productListForSales.filter(e => e.pdtCode === pCode)
      this.selectedPdtInfo = selectedPdtInfo[0]
      console.log("selectedPdtInfo:", this.selectedPdtInfo)
    },

    // 메인 배너 이미지 초기 설정 및 클릭 이벤트 처리
    initImageRotation() {
      this.updatePositions(); // 초기 위치 설정
    },

    // 중앙 이미지 클릭 시 영역에 따라 스크롤 방향 설정
    handleMainBannerClick(event) {
      if (this.transitionInProgress) return; // 애니메이션 중이면 클릭 무시

      const bannerWidth = this.$refs.mainBanner.clientWidth;
      const clickX = event.clientX;

      if (clickX < bannerWidth / 2) {
        // 왼쪽 클릭 -> 왼쪽으로 스크롤
        this.scrollLeft();
      } else {
        // 오른쪽 클릭 -> 오른쪽으로 스크롤
        this.scrollRight();
      }
    },    

    // 왼쪽으로 스크롤
    scrollLeft() {
      this.transitionInProgress = true;
      this.positions.push(this.positions.shift()); // 첫 번째 요소를 마지막으로 이동
      this.updatePositions();
      setTimeout(() => {
        this.transitionInProgress = false;
      }, 1000);
    },

    // 오른쪽으로 스크롤
    scrollRight() {
      this.transitionInProgress = true;
      this.positions.unshift(this.positions.pop()); // 마지막 요소를 첫 번째로 이동
      this.updatePositions();
      setTimeout(() => {
        this.transitionInProgress = false;
      }, 1000);
    },

    // 5초마다 자동 슬라이드
    startAutoScroll() {
      this.autoScrollInterval = setInterval(() => {
        if (!this.transitionInProgress) {
          this.scrollRight();
        }
      }, this.autoScrollTime);
    },

    // // 중앙 이미지의 인덱스를 반환하는 메서드
    // getCurrentCenterImageIndex() {
    //   // 'image-center'의 인덱스를 찾음
    //   return this.positions.indexOf('image-center');
    // },

    // 이미지 위치와 텍스트, 배경색 업데이트
    updatePositions() {
      this.images.forEach((img, index) => {
        img.className = `image-stack ${this.positions[index]}`;
      });

      // 중앙에 위치한 이미지의 인덱스번호
      this.currentImageIndex = this.positions.indexOf('image-center');
      // console.log("currentImageIndex:", this.currentImageIndex)

      let textBlocks = this.$el.querySelectorAll('.banner-text');
      textBlocks.forEach((block) => {
        block.style.display = 'none';
      });

      let centerIndex = this.positions.indexOf('image-center');
      let centerImageId = this.images[centerIndex].getAttribute('id');
      let activeTextBlock = document.getElementById(`${centerImageId}-text`);
      if (activeTextBlock) {
        activeTextBlock.style.display = 'block';
      }

      // 배경색 변경
      this.currentBackgroundColor = this.backgroundColors[centerIndex];
    },

    handleScroll() {
      let mainBannerBottom = this.$refs.mainBanner.getBoundingClientRect().bottom;
      let rtRemoteTop = this.$refs.rtRemoteContainer.getBoundingClientRect().top;
      let rtRemoteBottom = this.$refs.rtRemoteContainer.getBoundingClientRect().bottom;
      let noticeSectionTop = this.$refs.noticeSection.getBoundingClientRect().top;
      //let minTop = 790;

      if (rtRemoteTop < mainBannerBottom) 
      {
        this.isHidden = true;
      } 
      else if(rtRemoteTop > noticeSectionTop) 
      {
        this.isHidden = true;
      }
      else if(rtRemoteBottom > noticeSectionTop)
      {
        this.isHidden = true;
      }
      else
      {
        this.isHidden = false;
      }
    },

    getDateFormat(date) 
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) 
    {
      return this.$currencyFormat(value);
    },
    
    toB2BLoginPage() 
    {
      location.href = "/b2cLogin";
    },
    toMembership() 
    {
      location.href = "/membership";
    },
    kakaoLogin() 
    {
      window.Kakao.Auth.login({
        scope: "profile, account_email, gender, birthday",
        success: this.getProfile,
      });
    },

    async goToDetail(productId, salesRoute) {
      if(this.user.email != undefined)
      {
        await recentView(this.$api, this.user.email, productId)
      }      
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    
    //상단 메뉴카테고리 선택 대응
    showNdt(flag){
      this.selFlag = "N"      //상품선택플래그
      this.currentPage = 1    //1페이지로 이동
      this.sellerFlag = (this.sellerFlag == "") ? "ND" : flag
      this.getProductList("","",this.sellerFlag);
    },

    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },    

    async getProductList(p1, p2, p3)    //p1(X:더보기) p2() p3(nt nd nr be)
    {
      //멤버십할인정보체크
      if(this.user.memNo > 999)
      {
        this.memLv = this.user.memLevel   //멤버십등급
      }
      //기본보기
      let ntCd = "";
      let sRoute = "";
      let sMinPrice = 0;    //최소상품가
      let sMargin = 0;
      ntCd = (p1 == undefined || p1 == "" || p1 == "X") ? "%" : p1;
      sRoute = (p2 == undefined || p2 == "") ? "%" : p2;
      p3 = (p3 == undefined || p3 == "") ? "ND" : p3

      //console.log("params:", ntCd,sRoute)
      let productListForSales_tmp = await this.$api("/api/productListForSales", {param: [ntCd, sRoute]});
      //console.log("productListForSales_tmp:",productListForSales_tmp)
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      this.productListForSales = productListForSales_tmp.filter((item) => 
      {
        //return (item.mdChoice == "Y" && (item.assoFlag == null || item.assoFlag == ""));  //연계상품이 아닌 상품만
        return (item.assoFlag == null || item.assoFlag == "");  //연계상품이 아닌 상품만+추천이 아닌 상품도 포함
      });

      console.log("productListForSales:++++++++++", this.productListForSales)

      if(p3 == 'ND')    //엔데트상품
      {
        let productListForSales_ndaytrip = this.productListForSales.filter((item) => {
          return (item.sellerCode_nd === 'Y');
        });
        this.productListForSales_ndaytrip = productListForSales_ndaytrip
        this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
          return item.pdtCode != 'L-2023921-1695276228098'
        })
        this.productListForSales_mid = this.productListForSales_ndaytrip_main

        console.log("productListForSales_ndaytrip:", this.productListForSales_mid)
      }
      else if(p3 == 'NR')     //엔데어
      {
        let productListForSales_nthere = this.productListForSales.filter((item) => {
          return (item.nrFlag === 'Y');
        });
        this.productListForSales_nthere = productListForSales_nthere

        this.productListForSales_mid = this.productListForSales_nthere
        //console.log("productListForSales_nthere:", this.productListForSales_mid)
      }
      else if(p3 == 'VR')     //버라이어티
      {
        let productListForSales_variety = this.productListForSales.filter((item) => {
          return (item.vrFlag === 'Y');
        });
        this.productListForSales_variety = productListForSales_variety

        this.productListForSales_mid = this.productListForSales_variety
        //console.log("productListForSales_variety:", this.productListForSales_mid)
      }
      else if(p3 == 'NT')       //엔타비
      {
        let productListForSales_ntabi = this.productListForSales.filter((item) => {
          return (item.sellerCode_nt === 'Y' && (item.salesRoute != 'F' && item.salesRoute != 'E'));    //선박과 기타상품 제외
        });
        this.productListForSales_ntabi = productListForSales_ntabi
        this.productListForSales_mid = this.productListForSales_ntabi
        //console.log("productListForSales_ntabi:", this.productListForSales_mid)
      }      
      
      if(p3 == "" || p3 == "ND" || p3 === undefined || p3 == 'BE')       //베스트 + 기본 <- 초기값 ND에서 BEST는 독립적으로 호출해야 한다
      {
        console.log("productListForSales:>>>>>>", this.productListForSales)

        let productListForSales_best = this.productListForSales.filter((item) => {
          return (item.bestFlag === 'Y');
        });

        if(p1 === "X")
        {
          console.log("productListForSales_best::::::::", productListForSales_best)

          this.more_be = "Y"
          this.productListForSales_best = productListForSales_best;
        }
        else
        {
          this.more_be = "N"
          this.productListForSales_best = productListForSales_best.slice(0, 3);
        }
      }

    //this.productListForSales = this.productListForSales.slice(0, 4);
    //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
    let maxDC_P = 0;        //프리미엄 최대환급율
    let maxDC_S = 0;        //스탠다드 최대환급율
    let dcForMem_P = 0      //이외상품 프리미엄 할인율
    let dcForMem_S = 0      //이외상품 스탠다드 할인율
    let dcForStd = 0        //프리이엄전용을 스탠다드가 구매시 할인율
    let marginPrice = 0;    //마진가격
    let promoPrice = 0;     //프로모션금액
    let price_memP = 0      //프리미엄 환급액
    let price_memS = 0      //스탠다드 환급액
    let price_dcForStd = 0  //프리이엄전용을 스탠다드가 구매시 할인액
    let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
    let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액
    //console.log(dcForMem_P,"/",dcForMem_S,"/",dcForStd)
    for (let i = 0; i < this.productListForSales.length; i++) 
    {
      ////마진에 따른 마진가격
      if(this.productListForSales[i].marginMethod == "P")
      {
        marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
      }
      else
      {
        marginPrice = this.productListForSales[i].basicMargin;
      }
      //console.log("marginPrice:",marginPrice);
      ////프로모션 상태에 따른 프로모션가격
      if (this.productListForSales[i].promoStatus === "Y") 
      {
        if (this.productListForSales[i].promoMethod === "P") 
        {
          promoPrice = (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
        }
        else
        {
          promoPrice = this.productListForSales[i].promoRate;
        }
        this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        //console.log("promoPrice:",this.promoPrice)
      }
      //멤버십상품의 멤버십등급에 따른 멤버십환급가
      if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
      {
        //최대환급율
        maxDC_P = (this.productListForSales[i].maxDC_P == undefined) ? 0 : this.productListForSales[i].maxDC_P*1
        maxDC_S = (this.productListForSales[i].maxDC_S == undefined) ? 0 : this.productListForSales[i].maxDC_S*1
        //프리미엄전용을 스탠다드가 구매시 할인율
        dcForStd = (this.productListForSales[i].dcForStd == undefined) ? 0 : this.productListForSales[i].dcForStd*1
        //환급액
        price_memP = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_P / 100);
        price_memS = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_S / 100);
        //소숫점 절사
        this.price_memP = price_memP.toFixed(0)*1   //price_memP*1
        this.price_memS = price_memS.toFixed(0)*1   //price_memS*1
        
        //프리미엄전용을 스탠다드가 구매시 할인액 
        price_dcForStd = (this.productListForSales[i].minPrice+marginPrice)*(dcForStd / 100); 
        this.price_dcForStd = price_dcForStd.toFixed(0)*1
      } 

     //일반상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
      if(this.productListForSales[i].salesRoute == "T" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
      {   
        dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
        dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1
        //할인방식에 따라 분기처리
        if(this.productListForSales[i].dcRateForMem_P === "P")
        {
          price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
        }
        else
        {
          price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
        }
        
        if(this.productListForSales[i].dcRateForMem_S === "P")
        {
          price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
        }
        else
        {
          price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
        }
        this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        //프리미엄과 스탠다드 할인액을 배열에 추가
        this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
      }
      //멤버십미지정 로컬투어
      else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
      {
        dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
        dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

        price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
        price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

        this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
          
        //프리미엄과 스탠다드 할인액을 배열에 추가
        this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
      }
      //화면표시용 가격
      //구매자 멤버십
      if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
      {
        //this.productListForSales[i].pPrice = "멤버십가격"
        //멤버십 + 멤버십상품
        if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
        {
          //this.productListForSales[i].pPrice = "멤버용 상품가격" 
          
          //유효프로모션 존재
          if (this.productListForSales[i].promoStatus === "Y")
          {
            if(this.user.memLevel === 'P')
            {
              //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
              if(this.price_memP >= this.promoPrice && this.price_memP > 0)
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memP = this.price_memP  //환급액
                this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율
                this.productListForSales[i].memS = this.price_memS  //Std환급액
              }
              else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
              {
                this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
              }
            }
            else if(this.user.memLevel === 'S' && this.price_memS > 0)
            {
              if(this.price_memS >= this.promoPrice)
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //환급액
                this.productListForSales[i].memP = this.price_memP  //Pre환급액                  
              }
              else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
              {
                this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
              }
            }
          }
          //프로모션 없음
          else
          {
            if(this.user.memLevel === 'P')
            {
              this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
              this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
              this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
              this.productListForSales[i].memS = this.price_memS  //STD환급액
              this.productListForSales[i].memP = this.price_memP  //환급액
            }
            else if(this.user.memLevel === 'S')
            {
              this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
              this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
              this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
              this.productListForSales[i].memS = this.price_memS  //PRE환급액
              this.productListForSales[i].memP = this.price_memP  //환급액
            }
          }
        }

        //멤버십+일반상품
        else
        {
          //일반상품지정(패키지, 로컬+엔데어 || 버라이어티)
          if(this.productListForSales[i].salesRoute == 'T' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
          {
            //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                }
                else if(this.price_dcForMem_P < this.promoPrice) 
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S')
              {
                if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                }
                else if(this.price_dcForMem_S < this.promoPrice) 
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }
            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                if(this.price_dcForMem_P > 0)
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].dispPrice_P = "promo"     //표시방식 멤버십
                  this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                }
                else
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].dispPrice_P = "mem"     //표시방식 멤버십
                }
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
              }
              else if(this.user.memLevel === 'S')
              {
                if(this.price_dcForMem_S > 0)
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].dispPrice_S = "promo"     //표시방식 멤버십
                  this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                }
                else
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                }
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
              }
            }
          }
            
          //멤버십+일반상품유형2(로컬+멤버십미지정)
          else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
          {
            this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.price_dcForMem_P < this.promoPrice) 
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S')
              {
                if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
                else if(this.price_dcForMem_S < this.promoPrice) 
                {
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }
            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                if(this.price_dcForMem_P > 0)
                {
                  this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                  this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                  this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                }
                else
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                }
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
              }
              else if(this.user.memLevel === 'S')
              {
                if(this.price_dcForMem_S > 0)
                {
                  this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                  this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                }
                else
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                }
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
              }
            }
          }
        }
      }
      //구매자 비멤버십
      else
      {
        //프로모션이 있으면 할인처리
        if (this.productListForSales[i].promoStatus === "Y")
        {
          this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
            //console.log(`pPrice${i}: ${this.pPriceY}`);
            this.productListForSales[i].pPrice = this.pPriceY;
        } 
        else if (this.productListForSales[i].promoStatus == "E") 
        {
          this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceE;
        } 
        else if (this.productListForSales[i].promoStatus == "N") 
        {
          this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceN;
        }
      }
    }
    //console.log("productListForSales:", this.productListForSales)
  },

  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap'); /* url을 가장 먼저 임포트 */
@import "../assets/css/style.css";

/* font */
#newMain .cap{
  font-family: 'Black Han Sans', sans-serif;
}

#app .main-container {
  width: 100%;
}

/* 로고 */
#app .mlogo{
  display: block;
  padding-top:5px !important;
  padding-left:0 !important;
  margin-left:0 !important
}
#app .mlogo .logo{
  width:80px;
  height: auto;
}

/** Main-banner */
.banner-text {
  display: none; /* 초기에는 모든 텍스트 숨기기 */
}

/*
#banner3-text {
  display: block;
}
*/


/* ******메인 배너*******
********************************************************************
********************* */
.main-banner {
  position: relative;
  z-index: 10; /* 리모트 배너보다 앞에 위치 */
}

.rt-remote-container {
  position: fixed;
  top: 740px;
  right: 3%;
  z-index: 5; /* 메인 배너보다 뒤에 위치 */
}

/* 숨김 클래스 */
.hidden-banner {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.not-hidden-banner {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.main-banner {
  background-color: #007bff;
  color: white;
  width: 100%;
  height: 650px;
  display: flex; /* flexbox로 설정 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
}

.main-banner .main-banner-table {
  width: 100%;
  height: 100%; /* 테이블이 부모의 전체 높이를 차지하도록 설정 */
}

/* 메인배너 버튼 */
.main-banner-btn {
  background-color: white;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 20px;
  font-family: noto-sans, sans-serif;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.3); /* 그림자 효과 */
  transition: box-shadow 0.3s ease; /* 부드러운 전환 효과 */
}

.main-banner-btn:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6); /* 마우스 오버 시 그림자 강조 */
}

.image-container {
  position: relative;
  width: 780px;
  height: 600px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-stack {
  position: absolute;
  transition: transform 0.7s ease-in-out, width 0.7s ease-in-out, left 0.7s ease-in-out, z-index 0.7s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.5; /* 기본적으로 반투명하게 설정 */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 그림자 효과 추가 */
}

.image-center {
  width: 80%; /* 중앙 이미지는 더 크게 */
  height: 80%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  opacity: 1; /* 중앙 이미지는 완전하게 표시 */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* 중앙 이미지에 더 강한 그림자 */
}

.image-left, .image-right {
  width: 70%; /* 양옆 이미지는 조금 작게 */
  height: 70%;
  z-index: 2;
  opacity: 0.7; /* 양옆 이미지를 약간 투명하게 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* 양 옆 이미지에 중간 정도 그림자 */
}

.image-left {
  left: 20%; /* 왼쪽 이미지 위치 조정 */
  transform: translateX(-20%);
}

.image-right {
  left: 80%; /* 오른쪽 이미지 위치 조정 */
  transform: translateX(-80%);
}

.image-hidden-left, .image-hidden-right {
  width: 55%; /* 숨겨진 이미지는 더 작게 */
  height: 55%;
  z-index: 1;
  opacity: 0.3; /* 숨겨진 이미지를 더 투명하게 */
  border-radius: 10px;
}

.image-hidden-left {
  left: -10%; /* 왼쪽 숨겨진 이미지 위치 조정 */
  transform: translateX(10%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* 숨겨진 이미지에 약한 그림자 */
  border-radius: 10px;
}

.image-hidden-right {
  left: 110%; /* 오른쪽 숨겨진 이미지 위치 조정 */
  transform: translateX(-110%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* 숨겨진 이미지에 약한 그림자 */
  border-radius: 10px;
}

/* 이미지가 7개 케이스 */
.image-hidden-far-left, .image-hidden-far-right {
  width: 45%; /* 더 작게 설정 */
  height: 45%;
  z-index: 0;
  opacity: 0.2; /* 가장 숨겨진 이미지를 더 투명하게 */
}

.image-hidden-far-left {
  left: -20%;
  transform: translateX(20%);
}

.image-hidden-far-right {
  left: 120%;
  transform: translateX(-120%);
}


/* 메인배너이미지 인디케이터 */
.indicator-container {
  position: absolute;
  bottom: 20px; /* 배너 하단에서 20px 위에 배치 */
  left: 50%; /* 수평 중앙 배치 */
  transform: translateX(-50%); /* 정확히 가운데에 위치하도록 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.indicator-container span {
  font-size: 2rem;
  color: white;
  margin: 0 5px;
  transition: color 0.3s ease;
}

.active-indicator {
  color:black; /* 활성화된 인디케이터는 노란색으로 표시 */
  font-size: 1rem;
}




/********** * 최상단메뉴 ***************/
.top-menu{
  padding-left: 20%;
  padding-right: 20%;
}


/* 컨텐츠 영역처리 */
.ct-position{
  width: 60%;
}

/* 상단 서치박스 */
.searchBox{
  border: 1px solid gray;
  border-radius: 8px;
  padding-left: 25px; /* 이모티콘이 input 안에 겹치지 않도록 여백 추가 */
  font-size: smaller;
}

.input-with-icon {
  position: relative;
}

.input-with-icon::before {
  content: "🔍";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  pointer-events: none; /* 이모티콘이 클릭되지 않도록 설정 */
}

/* 텍스트 점멸 */
.blink {
  animation: blinker 1.5s linear infinite;
  /* color: darkgreen; */
}

@keyframes blinker {
  50% 
  {
    opacity: 0;
  }
}


/** 멤버십라벨 */
.memLabel {
  padding-left: 3px;
  padding-right: 3px;
  font-size: .8rem;
  border-radius: 5%;
  color: white;
}

/* 멤버십 아이콘 */
.mbus-icon{
  width:2.5rem !important;
  height: auto !important;
}

/*--------------------------------------------------------------
	# 선택된 상품카테고리 표시영역
  --------------------------------------------------------------*/
  #saleitem .scrollable {
    max-height: 500px; /* 원하는 높이 설정 */
    overflow-y: auto; /* 세로 스크롤 활성화 */
    display: block;
  }

  #saleitem .image-container {
    display: flex;
    flex-direction: column; /* 세로 방향으로 정렬 */
  }  

  #saleitem {
    background-color: #f9f9f9;
    padding: 40px 0;
  }
  
  #saleitem .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  #saleitem .section-description {
    margin-bottom: 20px;
    font-size: 18px;
    color: #666;
  }

  #saleitem .product-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  #saleitem .product-description {
    font-size: 14px;
    color: #666;
  }
  
  #saleitem .price {
    font-weight: bold;
    color: #28a745;
  }

  #saleitem .image-container {
    max-height: 500px; /* 원하는 높이 설정 */
    overflow-y: auto; /* 세로 스크롤 활성화 */
    width: 50%; /* 오른쪽 영역을 50%로 지정 */
  }

  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end; /* 우측 정렬 */
    align-items: center;
    gap: 10px; /* 버튼 간 간격 */
  }
  
  .pagination button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: #007bff;
    color: white;
  }
  
  .pagination span {
    font-weight: bold;
  }
  
  .product-description {
    white-space: nowrap;        /* 텍스트를 한 줄로 표시 */
    overflow: hidden;           /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;    /* 넘치는 텍스트를 ...으로 표시 */
    display: block;             /* 블록 요소로 설정 */
    width: 50ch;                /* 최대 20글자까지 표시 */
    font-size: 16px;            /* 글자 크기 고정 */
  }

  .slide-up-enter-active, .slide-down-enter-active {
    transition: transform 0.5s ease;
  }
  .slide-up-leave-active, .slide-down-leave-active {
    transition: transform 0.5s ease;
  }
  .slide-up-enter, .slide-up-leave-to {
    transform: translateY(100%); /* 위로 올라오는 효과 */
  }
  .slide-down-enter, .slide-down-leave-to {
    transform: translateY(-100%); /* 아래로 내려오는 효과 */
  }

  .product-container {
    display: flex;                    /* flex */
    justify-content: end;
    /* justify-content: space-between;   좌우로 요소배치 */
    align-items: center;              /* 요소를 수직중앙에 정렬 */
  }

  /* 마우스오버 상품이미지표시 */
  .nday .nday-body{
    position:absolute;
    bottom:20%;
    left:5%;
    color: white;
  }
  .nday .nday-body h5{
    font-family: 'Black Han Sans', sans-serif;
    font-size:3rem;
    margin-bottom:0;
    text-align: start;
  }

  .nday-price{
    text-align: left;
    font-size:  1.1rem;
  }
  
  .nday .showPdtName{
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: start;
    padding-right:20px;
    font-weight: 600;
    font-size:1.1rem
  }  

/*  
  #saleitem .left-content {
    width: 50%;
  }

  #saleitem .main-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  #saleitem .main-description {
    font-size: 16px;
  }
  
  #saleitem .price-info {
    font-size: 14px;
    color: #333;
  }
*/  
/*  
  #saleitem .card-style {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  #saleitem .product-info {
    padding-top: 8px;
  }
*/



/*--------------------------------------------------------------
	# mid-banner
--------------------------------------------------------------*/
#newMain .mid-banner{
  background-color: #0065bd;
  height: auto;
  margin:10rem 0;
}
#newMain .mid-banner .pc{
  display: block;
}
#newMain .mid-banner .pad{
  display: none;
}
#newMain .mid-banner .mobile{
  display: none;
}
@media (max-width:699px){
  #newMain .mid-banner{
    margin:7rem 0;
  }
  #newMain .mid-banner .pc{
    display: none;
  }
  #newMain .mid-banner .pad{
    display: none;
  }
  #newMain .mid-banner .mobile{
    display: block;
  }
}
@media (min-width:700px) and (max-width:1800px){
  #newMain .mid-banner .pc{
    display: none;
  }
  #newMain .mid-banner .pad{
    display: block;
  }
  #mainewMain .mid-banner .mobile{
    display: none;
  }
}

/* tour product carousel */
.tab_content {
  display: none;
  width: 800px;
  margin: 0 auto;
  min-height: 100px;
  background: #eee;
  box-sizing: border-box;
}

.tab_content img {
  width: 800px;
  box-shadow: 0 10px 12px 4px rgba(0,0,0,0.3);
}

#tourCarousel-container {
  /* padding-bottom: 120px; */
  background: url(../assets/img/taiwan/back2.jpg)no-repeat top;
  background-size: 100%;
  height: 500px;
}

.tourCard{
  height: 400px;
  margin-top: 15%;
  margin-bottom: 15%;
}

/* 엔데이 라이브*/
.nday-live {
  height: 400px;
  width: 300px;
}


/* -------------------------------------------------
  # 메인컨텐츠영역
--------------------------------------------------- */
#newMain .main-left{
  padding-right:1rem
}
@media (max-width:991px){
  #newMain .main-left{
    padding-right:0
  }
}

/* -------------------------------------------------
  # Main Category
--------------------------------------------------- */
#newMain .mainCategory {
  height: 70px;

}

/* -------------------------------------------------
  # 메인카테고리 연동상품 이미지
--------------------------------------------------- */
/* #newMain .pdt-img {
  width: 110px;
  height: 110px;
  border-radius: 15px;
} */

.image-grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 한 줄에 하나씩 배치 */
  grid-auto-rows: minmax(150px, auto); /* 각 행에 150px 크기 설정 */
  max-height: 500px; /* 한 번에 3개씩 보여지도록 높이 설정 */
  overflow-y: auto; /* 스크롤 가능하도록 설정 */
}

.image-grid-item {
  padding: 10px;
}

.pdt-img {
  width: 100px; /* 이미지의 너비 */
  height: 100px; /* 이미지의 높이 */
  object-fit: cover; /* 이미지가 비율에 맞게 크기 조정 */
  display: block;
  margin: 0 auto; /* 이미지 가운데 정렬 */
}



/* -------------------------------------------------
  # 메인 비디오 영역
--------------------------------------------------- */
.video{ 
  width: 100%; 
  height: 300px; 
  overflow: hidden;
  margin: 0px auto; 
  position: relative; 
}
.video video{
  width: 100%;
}
.video-text{ 
  position: absolute; 
  top: 50%; 
  width: 100%; 
}
.video-text p{ 
  margin-top: -24px; 
  text-align: center; 
  font-size: 2rem; 
  color: #ffffff; 
}
@media (max-width:500px){
  .video{ 
    height: 200px; 
  }
  .video-text p{
    font-size: 1.5rem; 
  }
}

/*--------------------------------------------------------------
# 베스트 아이템
--------------------------------------------------------------*/
.bestitem{
  margin-bottom:5rem
}
.bestitem .card{
  border:none;
  overflow: hidden;
  border-radius: 15px;
}
.bestitem .card img{
  width:auto;
  height:35rem;
  object-fit: cover;
  transition: all .7s ease-in-out;
}
.bestitem .card:hover img{
  transform: scale(1.1);
}
.bestitem .card:hover .mbus-icon{
  transform: scale(1);
}
@media (max-width:991px){
  .bestitem .card img{
    height:25rem;
  }
}

.bestitem .card-body{
  position:absolute;
  bottom:0%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,0.5), rgba(0,0,0,0) );
  width:100%;
}
.bestitem .card-title{
  color:#fff;
  height:9rem;
  font-weight: 600;
  font-size:1.3rem;
}
.bestitem .card-title .b-height{
  height:3rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


/*--------------------------------------------------------------
# 상품뱃지
--------------------------------------------------------------*/
.badge {
  /* width: 120px; */
  text-align: center;
}

.badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
.badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

.badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
.badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}



/*--------------------------------------------------------------
# 스마트스토어 라이브 임베디드
--------------------------------------------------------------*/
.iframe-container {
  position: relative;       /* 포지션 조정 */
  width: 100%;              /* 부모 컨테이너의 너비에 맞춤 */
  height: 1000px;           /* 임베디드 사이즈 */
  padding-bottom: 56.25%;   /* 16:9 비율을 유지하기 위한 비율 계산 */
  overflow: hidden;         /* 초과부분 숨김*/
}

.iframe-container iframe {
  position: absolute;
  top: -350px;
  left: 0;
  width: 100%;            /* 부모 컨테이너의 전체 너비 사용 */
  height: 1300px;           /* 부모 컨테이너의 전체 높이 사용 */
  border: none;           /* iframe의 경계선 제거 */
}
</style>