<template>
  <div id="cnotice">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12 mt-4">
            <h3 v-if="this.param == 'E'">이 벤 트</h3>
            <h3 v-else>공지사항</h3>

            <!-- 내용 -->
            <div class="row no-cont">
              <p>총 {{ this.getCurrencyFormat(this.noticeList.length) }}건</p>
              <table class="table">
                <thead>
                  <tr>
                    <th class="col-2">구분</th>
                    <th class="col-7">제목</th>
                    <th class="col-3">등록일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="j" v-for="(notice, j) in this.noticeList">
                    <td class="col-2 nCategory">
                      <span v-if="notice.ctCd === 'NT'"><b style="color:black">공 &nbsp;&nbsp;지</b></span>
                      <span v-else-if="notice.ctCd === 'EV'"><b style="color:darkgreen">이 &nbsp;벤 &nbsp;트</b></span>
                      <span v-else-if="notice.ctCd === 'TR'"><b style="color:darkred">여행관련</b></span>
                      <span v-else-if="notice.ctCd === 'MO'"><b style="color:darkblue">항공선박</b></span>
                      <span v-else-if="notice.ctCd === 'PR'"><b style="color:darkorange">홍보/안내</b></span>
                      <span v-else><b style="color:darkslategrey">기 &nbsp;&nbsp;타</b></span>
                    </td>
                    <td style="text-align: left; padding-left: 1rem;" class="col-7 nTitle">
                      <div>
                        <a href="javascript: void(0)" @click="showArea(j)">
                          <span v-if="notice.ctCd === 'NT'">{{notice.nTitle}}</span>
                          <span v-else-if="notice.ctCd === 'EV'">{{notice.nTitle}}</span>
                          <span v-else-if="notice.ctCd === 'TR'">{{notice.nTitle}}</span>
                          <span v-else-if="notice.ctCd === 'MO'">{{notice.nTitle}}</span>
                          <span v-else-if="notice.ctCd === 'PR'">{{notice.nTitle}}</span>
                          <span v-else>{{notice.nTitle}}</span>
                        </a>
                      </div>
                      <!-- 공지사항내용 & 바로가기 -->
                      <div class="nContent" :id = "j">
                        <div class="container py-1">
                          <pre class="mb-1 text-start p-1">{{notice.nContent}}</pre>
                          <div class="position-relative mb-3 text-center">
                            <!-- <img v-if="notice.addImageFlag == 'Y'" :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.jpg`" class="card-img-top" alt="..." style="width:60%; height:60%; object-fit:cover;" /> -->
                            <img v-if="notice.addImageFlag === 'Y'" :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.jpg`" class="card-img-top" alt="..." style="width:60%; height:60%; object-fit:cover;" />
                            <video v-else-if="notice.addVideoFlag === 'Y'" :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.mp4`" class="card-img-top" alt="..." style="width:60%; height:60%; object-fit:cover;" controls/>                            
                          </div>
                          <p class="row col-lg-3 gopro" v-if="notice.linkFlag === 'Y'">
                            <a :href="`${notice.link}`" class="fs-6 text-center"> 바로가기 </a>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="col-3 nRegDate">
                      {{ this.getDateFormat(notice.nTo) }} 까지
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- pagenation area -->
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous" @click="paging('p')">
                    <i class="bi bi-chevron-bar-left"></i>
                  </a>
                </li>
                <li class="page-item" :key="j" v-for="j in this.cntPage">
                  <a class="page-link" @click="paging(pg = j)">{{j}}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next" @click="paging('n')">
                    <i class="bi bi-chevron-bar-right"></i>
                  </a>
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      nContent: "", //공지사항 내용
      noticeList: [], //공지사항 리스트
      showNoticeFlag: "N",
      //isMobile: false //디바이스종류

      //Paging
      cntProdList: 0,
      cntNoticeList: 0,
      pageSize: 12, //페이지당 데이타수
      page: 1, //offset기준
      cntPage: 1, //페이지네이션 표시페이지수
    };
  },
  created(){
    // console.log("userInfo:",this.$store.state.user);
    // console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);
    
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    //console.log("today:",this.today)
    
    this.param = this.$route.query.param;
    //console.log("param:", this.param)

    this.getNoticeList("Y"); //공지&이벤트 리스트
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }

    //비회원 접근 시 대응처리
    // if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '',
    //     text: '로그인 후 이용해주세요.',
    //     icon: 'warning'
    //   });
    //   this.$router.push({path: '/'});
    // }

    document.getElementById("notice").style.display = "none";
  },  
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },    
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    // //모바일orPC체크
    // checkIfMobile() {
    //   const userAgent = window.navigator.userAgent;
    //   const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    //   return mobileRegex.test(userAgent);
    // },
    
    showArea(j)
    {
      //this.isMobile = this.checkIfMobile();
      this.showNoticeFlag = (this.showNoticeFlag === "Y") ? "N" : "Y"
      //선택된 항목만 보이도록
      document.querySelectorAll(".nContent").forEach((item) => {
        item.style.display = "none";
      });

      if(this.showNoticeFlag == "Y")
      {
        document.getElementById(j).style.display = "block";
      }
      else
      {
        document.getElementById(j).style.display = "none";
      }
    },    
    async getNoticeList(param) 
    {
      if(this.pastFlag === "N" || this.pastFlag == undefined || this.pastFlag == "")
      {
        this.pastFlag = "Y"
      }
      else
      {
        this.pastFlag = 'N'
      }

      let noticeList_tmp = await this.$api("/api/noticeList", {});

      if(this.param == "E")
      {
        this.noticeList = noticeList_tmp.filter((item) => {
          return (item.status == param && item.ctCd == 'EV');
        });
      }
      else
      {
        this.noticeList = noticeList_tmp.filter((item) => {
          return item.status == param;
        });
      }
      console.log("noticeList:",this.noticeList);
    },

    paging(val){
        this.cntNoticeList = this.noticeList.length;

        this.cntPage = Math.ceil(this.cntNoticeList / this.pageSize); //올림처리

        let offSet = 0;
        //console.log("page:",this.page);

        //페이지 초기
        if(val == undefined) {
          this.page = 1;
        }
        else {
          if(val === "p") {
            this.page--;
            if(this.page < 1) {
              this.page = 1;
              this.$swal.fire('', '시작페이지입니다', 'info')
            }
          }
          else if(val === "n") {
            this.page++;
            if(this.page > this.cntPage) {
              this.page = this.cntPage;
              this.$swal.fire('', '마지막페이지입니다', 'info')
            }
          }
          else{
            this.page = val;
          }
        }
        offSet = (this.page-1)*this.pageSize;
        this.noticeList = this.noticeList.slice(offSet, offSet+this.pageSize); //시작idx, 지정번째idx-1

        if(this.noticeList.length < 1) {
          this.$swal.fire('', '데이타가 없습니다', 'info');
          return false;
        }
    },

    goToHome() {
      this.$router.push({path: '/'});
    }
  }
}
</script>


<style>
#cnotice{
  margin:4rem 0 8rem;
}
@media (max-width:992px){
  #cnotice{
    margin:0rem 0 4rem;
  }
}

#cnotice h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}



/*--------------------------------------------------------------
# 내용
--------------------------------------------------------------*/
#cnotice .no-cont{
  margin:3rem 0
}
#cnotice .no-cont .table{
  border-color:#e0e0e0; 
  border-top:2px solid #000;
  text-align: center;
}
#cnotice .no-cont thead th{
  font-size:1rem;
  font-weight:600;
  padding:15px 0;
  background-color: #f8f8f8;
}
#cnotice .no-cont tbody th{
  font-weight: normal;
  padding:10px 0
}


#cnotice .nContent {
  display: none;
}

#cnotice pre {
  white-space:pre-wrap;
}

#cnotice .notice{
  font-size:.8rem;
  color:#b20401;
  border:1px solid #b20401;
  padding:2px 9px;
  margin:0 30px 0 50px
}
#cnotice .event{
  font-size:.8rem;
  color:#080ba6;
  border:1px solid #080ba6;
  padding:2px 5px;
  margin:0 30px 0 50px
}
#cnotice .term{
  font-size:.75rem;
  text-align: center;
}

#cnotice .nContent .gopro{
  float: none; 
  margin:3rem auto 4rem
}
#cnotice .nContent .gopro a{
  color:#fff;
  background-color: #333;
  padding:15px 0;
  font-weight: 600;
  letter-spacing: 2px;
}
#cnotice .nContent .gopro a:hover{
  background-color: #000
}


/*--------------------------------------------------------------
# 버튼
--------------------------------------------------------------*/
#cnotice .page{
  margin-top:3rem
}
#cnotice .page ul li{
  display: inline-block;
  list-style: none;
  padding:0 10px
}
#cnotice .page ul li:hover a{
  color:#777
}
#cnotice .page ul .now{
  background-color: #000;
  border-radius: 50%;
  padding:3px 11px 4px
}
#cnotice .page ul .now a{
  color:#fff;
}
#cnotice .page ul .now:hover a{
  color:#fff
}


/*--------------------------------------------------------------
  # 모바일화면 공지사항
--------------------------------------------------------------*/
/* CSS 파일이나 <style> 태그 안에 추가 */
@media (max-width: 768px) {
  .nCategory, 
  .nTitle
  {
    font-size: .7rem;
    vertical-align: middle;
  }
  .nRegDate
  {
    font-size: .6rem;
    vertical-align: middle;
  }
}
</style>
