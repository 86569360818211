<template>
  <div id="threecourse">
    <!-- 메인사진 -->
    <section class="threecoursemain">
      <div class="th_container">
        <img src="../assets/img/threecourse/title3.png" class="img-fluid">
      </div>
    </section>

    <!--bg carousel-->
    <div class="carousel_main">
      <div class="carousel_wrapper">
        <div class="carousel_slide">
          <img src="../assets/img/threecourse/maru2_bg.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/threecourse/miya_bg.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/threecourse/matsu_bg.jpg" class="" alt="javascript:void(0)">
        </div>
      </div>
      <!--bg carousel-->

      <!--tab-->
      <div class="tab_line">
        <div class="tab_basic">
          <div class="tab_list_wrap">
            <div class="tab_list tab_active" data-tabnum="0">
              <a class="carousel_circle bd fbd" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800">
                NEW
                <br>마루코마 코스
              </a>
              <a class="carousel_circle bd fbd" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>
                NEW
                <br>마루코마 코스
              </a>
            </div>

            <div class="tab_list" data-tabnum="1">
              <a class="carousel_circle bd" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800" >NEW
                <br>다카치호 코스</a>
                <a class="carousel_circle bd" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>NEW
                <br>다카치호 코스</a>
            </div>

            <div class="tab_list" data-tabnum="2">
              <a  class="carousel_circle bd last" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800" >NEW
                <br>마쓰야마 코스
              </a>
              <a  class="carousel_circle bd last" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>NEW
                <br>마쓰야마 코스
              </a>
            </div>
          </div>
        </div>
        <div id="three_tab_container">
          <div class="three_tab_content">
            <img src="../assets/img/threecourse/marukoma.jpg" alt="">
          </div>
          <div class="three_tab_content">
            <img src="../assets/img/threecourse/miyazaki.jpg" alt="">
          </div>
          <div class="three_tab_content">
            <img src="../assets/img/threecourse/matsuyama.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--tab-->


    <!-- 예약 -->
    <div id="reser0" class="th_container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-24111959ZCC','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser1" class="th_container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-20241122-1732249762453','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser2" class="th_container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-24112101RNC','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  // DB에서 가져온 데이타
  data() {
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    this.chkSize = window.innerWidth
    //alert(this.chkSize) 화면 사이즈 체크
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }   
  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },
  mounted() {

    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요', 
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'}); 
    // }



  
    // tab---------------------------------------------------------------------------
    var tabList = document.querySelectorAll('.tab_list_wrap .tab_list')

    Array.prototype.forEach.call(tabList, function (list) {
      list.children[0].addEventListener('click', function () {

        var tabContent = document.querySelectorAll('#three_tab_container .three_tab_content')
        var tabNum = this.parentElement.getAttribute('data-tabnum')

        Array.prototype.forEach.call(tabContent, function (cont, i) {
          cont.style.display = 'none'
          tabList[i].className = 'tab_list'
        })
        tabContent[tabNum].style.display = 'block'

        if (list.className.indexOf('tab_active') == -1) {
          list.className = 'tab_list tab_active'
        }
      })
    })
    
    //tab----------------------------------------------------------------------------

    //document.querySelector('[data-tabnum="0"]').addEventListener('click', function() {
    //document.querySelector('#reser0').style.display = 'block';
//});
      




    //carousel-----------------------------------------------------------
    const swiper = document.querySelector('.carousel_wrapper');
    const bullets = document.querySelectorAll('.carousel_circle');

    let currentSlide = 3;

    function showSlide(slideIndex) {
      swiper.style.transform = `translateX(-${slideIndex * 100}vw)`;
      currentSlide = slideIndex;

      bullets.forEach((bullet, index) => {
        if (index === currentSlide) {
          bullet.classList.add('active');
        } else {
          bullet.classList.remove('active');
        }
      });
    }
    bullets.forEach((bullet, index) => {
      bullet.addEventListener('click', () => {
        showSlide(index);
      });
    });

    showSlide(0);
    //carousel---------------------------------------------------------------
    window.addEventListener('scroll', function() {
    var tabBasic = document.querySelector('.tab_basic');
    var scrollPosition = window.scrollY || window.pageYOffset;
    var windowWidth = window.innerWidth;

    

    if (scrollPosition > 1300 && windowWidth >= 1921 && windowWidth <= 2560) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
      } else if (scrollPosition > 940 && windowWidth >= 800 && windowWidth <= 1920) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
      } else {
    tabBasic.style.position = 'static';
      }
});






for (let i = 1; i <= 2; i++) {
    document.querySelector('[data-tabnum="' + i + '"]').addEventListener('click', function() {
        // 새로 클릭한 요소만 block으로 변경
        for (let j = 0; j <= 2; j++) {
            if (j !== i) {
                document.querySelector('#reser' + j).style.display = 'none';
            }
        }
        document.querySelector('#reser' + i).style.display = 'block';
    });
    }

  }
}

</script>

<style>
.th_container{
  width: 100%;
}
.th_container img{
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.firstimg{
  position: relative;
  top: 0;
  left: 0;
  z-index: 8;
}
/* carousel */
.carousel_main {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  user-select: none;
}

.carousel_wrapper {
  display: flex;
  transition: transform 1s;
}

.carousel_slide {
  flex: 0 0 100%;
  /* 이미지 크기 설정 */
  position: relative;
}
.carousel_slide>img{
  width: 100vw;
  height: 100vh;
}

/*tabmenu*/
.tab_line {
  width: 100%;
  position: relative;
}

.tab_basic {
  margin: 0 auto;
  width: 100%;
  height: 10vh;
  background-color: rgb(175, 50, 0);
  position: sticky;
  top: 0;
  z-index: 100;
}

.tab_list_wrap {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}

.tab_list {
  display: inline-block;
  width: 16.6666%;
  height: 100%;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}


.tab_active {
  background-color: rgb(133, 42, 0);
}
.tab_active a{
  color: rgb(245, 188, 42);
}

.tab_list:hover {
  border-bottom: 8px solid rgb(133, 42, 0);
  border-right: -1px solid #fff;
}

.tab_list a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #ffffff;
}
.bd{
  border-right: 1px solid #fff;
}
.fbd{
  border-left: 1px solid #fff;
}
.bd:nth-child(2){
  border: 0px;
}



.three_tab_content {
  display: none;
  width: 800px; /* 기본 너비 */
  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;
}

.three_tab_content:nth-child(1){
  width: 100%;
  display: block;
}
.three_tab_content:nth-child(2){
  width: 100%;
  display: none;
}
.three_tab_content:nth-child(3){
  width: 100%;
  display: none;
}

.three_tab_content img {
  width: 800px; /* 이미지 너비를 100%로 설정하여 부모 요소에 맞게 조정 */
  box-shadow: 0 10px 12px 4px rgba(0,0,0,0.3);
  height: auto;
}

/* 모바일 화면을 위한 미디어 쿼리 */
@media (max-width: 800px) {
  .three_tab_content {
    width: 100%; /* 모바일에서는 너비를 100%로 설정 */
  }
}

#three_tab_container {
  background: url(../assets/img/threecourse/bg.jpg)no-repeat top;
}
/*tapmenu-end*/
#threecourse{
  margin-top: -56px;

}
/* 메인 */
#threecourse .threecoursemain img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 30%
}

#threecourse .threecoursemain img:hover {
  box-shadow: 5px 5px 5px 0.3;
}

#threecourse .threecoursemain p {
  animation: new 20s;
  display: block;
  position: absolute;
  top: 27%;
  left: 28%;
  z-index: 90;
  color: #fff;
  font-size: 35px;
  font-family: 'KCC-Jeongbeom';
}

/* 예약 */
#reser1{
  display: none;
}
#reser2{
  display: none;
}
.resergo{
  margin-bottom: 5rem;
}
#threecourse .resergo {
  margin-top: 5rem;
  text-align: center;
}

#threecourse .resergo .go {
  transition: all 0.5s;
  box-shadow: 0px 8px 0px rgb(141, 141, 141);
  border: solid 1px rgb(167, 167, 167);
}

#threecourse .resergo a {
  color: #000;
  transition: all 0.5s;
  display: block;
  width: 250px;
  padding: 20px 0;
  margin: 0 auto;
}

#threecourse .resergo a:hover .go {
  background-color: rgb(133, 42, 0);
  box-shadow: none;
  transform: translate(0px, 10px);
  color: #fff;
}

.go {
  padding: 20px 0;
  border-radius: 10px;
  display: block;
}
@media (max-width: 375px) {
  @keyframes fadeUpRight {
    0%{right: 11%; top: 11%;}
    50%{right:13%; top: 13%;}
    100%{right:11%; top:11%;}
  }
  .tab_list a {
    padding-top: 12%;
    font-size: 17px;
  }

  .cloud1 {
    top: 15%;
    left: 0%;
  }

  .cloud1 img {
    width: 40vw;
  }

  .cloud2 {
    top: 25%;
    right: 2%;
  }

  .cloud2 img {
    width: 35vw;
  }

  .airplane {
    top: 15%;
    right: 11%;
  }

  .airplane img {
    width: 25vw;
  }
}

@media (max-width: 767px) and (min-width: 376px) {
  .tab_list a {
    padding-top: 17%;
    font-size: 19px;
  }
}

@media (max-width:375px) {
  #threecourse .threecoursemain p {
    top: 13%;
    left: 8%;
    font-size: 15px;
  }
  #threecourse .threecoursemain img{
    width: 60%;
    top: 24%;
  }
}
@media (max-width:360px) {
  #threecourse .threecoursemain p {
    top: 13%;
    left: 8%;
    font-size: 15px;
  }
  #threecourse .threecoursemain img{
    width: 60%;
    top: 22%;
  }
}

@media (min-width:375px) and (max-width:767px) {
  #threecourse .threecoursemain p {
    top: 11%;
    left: 8%;
    font-size: 18px;
  }
  #threecourse .threecoursemain img{
    width: 63%;
    top: 26%;
  }
}

@media(max-width: 767px) {
  .carousel_slide img{
    width: 100%;
  }
  #three_tab_container {
    padding-bottom: 0;
  }
  .tab_basic {
    height: 100%;
  }
  .tab_list{
    display: inline-flex;
    flex-wrap: wrap;
  }
  .tab_list a{
    padding: 10% 0;
  }
  .last{
  border: none;
  }
  .top{
  border-top: 1px solid #fff;
  }
}
@media (max-width:1500px) and (min-width: 1201px) {
  #threecourse .threecoursemain img {
    width: 50%;
    top:59%;

  }
}
@media (max-width:992px) and (min-width: 767px) {
  #threecourse .threecoursemain img {
    width: 50%;
    top:59%;

  }
}

@media (min-width:768px) and (max-width:1200px) {
  #threecourse .threecoursemain img {
    width: 50%;
    top:57%;
  }
}

@media (max-width:767px) and (min-width:750px) {
  #threecourse .threecoursemain {
    width: 100%;
  }
  #threecourse .threecoursemain img{
    top:40%;
    width: 50%;
  }
}
@media (max-width:749px) and (min-width:710px) {
  #threecourse .threecoursemain img{
    top:40%;
    width: 50%;
  }
}
@media (max-width:709px) and (min-width:601px) {
  #threecourse .threecoursemain img{
    top:32%;
    width: 50%;
  }
}
@media (max-width:599px) and (min-width:549px) {
  #threecourse .threecoursemain img{
    top:30%;
    width: 50%;
  }
}
@media (max-width:548px) and (min-width:500px) {
  #threecourse .threecoursemain img{
    top:28%;
    width: 50%;
  }
}
@media (max-width:499px) and (min-width:450px) {
  #threecourse .threecoursemain img{
    top:25%;
    width: 50%;
  }
}
@media (max-width:449px) and (min-width:375px) {
  #threecourse .threecoursemain img{
    top:25%;
    width: 50%;
  }
}
@media (max-width:375px) and (min-width:335px) {
  #threecourse .threecoursemain img{
    top:20%;
    width: 50%;
  }
}
@media (max-width:334px) and (min-width:300px) {
  #threecourse .threecoursemain img{
    top:20%;
    width: 50%;
  }
}

@media (max-width:767px) {
  .tab_list_warp .tab_basic .tab_list.tab_active .tab_list a {
    width: 100%;
  }
  .tab_list:hover {
  border-bottom: none;
  }
}

@media (max-width:767px) {
  .tab_list_wrap {
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    padding: 0;
  }
}

@media (max-width:767px) {
  .tab_list {
    width: 33.33%;
    font-size: 12px;
    padding: 0px;
  }
  .fbd{
  border-left: none;
}
}

@media (max-width:767px) {
  .three_tab_content img {
    text-align: center;
    width: 100% !important;
    display: block;
    height: auto;
    
  }
}

@media (max-width:767px) {
  .three_tab_content {
    width: 100%;
    padding: 0px;
  }
}

@media (min-width:768px) {
  .tab_list a {
    padding-top: 7%;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .carousel_slide img{
    width: 133%;
    height: auto;
    margin-top: 35px;
  }
}
@media (max-width:2560px) and (min-width:1921px){
  .tab_line{
    top: -210px;
  }
}
@media (max-width:1920px){
  .tab_line{
    top: -175px;
  }
  .tab_list a{
     padding-top: 13px; 
    }
}
@media (max-width:1919px){
  .tab_line{
    top: 0;
  
  }
}
@media (max-width:1024px){
  .tab_list {
  width: 33.33%;
}
}
</style>

