<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>공지상세</u></h2>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">공지제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_nTitle" v-model="this.notice.nTitle">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지내용<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" 
            v-model="this.notice.nContent"></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <!-- <date-picker class="form-control" v-model="this.notice.nFrom" ref="txt_fromDate" type="date" lang="en"></date-picker> -->
            <input type="date" class="form-control" v-model="this.notice.nFrom" ref="txt_fromDate">
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="this.notice.nTo" ref="txt_endDate">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">바로가기</label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_nTitle" v-model="this.notice.link" disabled>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          이미지 / 동영상 (택1)
        </label>
      
        <div class="col-md-9" 
          v-if="(this.notice.addImageFlag === 'N' && this.notice.addVideoFlag === 'N') || (this.notice.addImageFlag == null && this.notice.addVideoFlag == null)
            || (this.notice.addImageFlag === 'N' && this.notice.addVideoFlag == null) || (this.notice.addImageFlag === null && this.notice.addVideoFlag == 'N')
          ">
          <div class="col">
            <!-- 이미지 업로드 버튼 -->
            <div class="mb-3">
              <center>
                <button
                  type="button"
                  class="btn btn-md btn-success"
                  style="width: 270px;"
                  :disabled="activeUpload === 'video'"
                  @click="toggleUpload('image')"
                >
                  이미지첨부&nbsp;&nbsp;(최대 2MB jpg)
                </button>
              </center>
            </div>

            <!-- 이미지 업로드 필드 -->
            <div class="mb-3 row" v-if="activeUpload === 'image'">
              <input type="file" class="form-control" id="fileSelectorImage" accept="image/jpeg">
              <div class="alert alert-secondary" role="alert" style="font-size:small;">
                <div>File 확장자: jpg</div>
              </div>
            </div>

            <!-- 동영상 업로드 버튼 -->
            <div class="mb-3">
              <center>
                <button
                  type="button"
                  class="btn btn-md btn-info"
                  style="width: 270px;"
                  :disabled="activeUpload === 'image'"
                  @click="toggleUpload('video')"
                >
                  동영상첨부&nbsp;&nbsp;(최대 20MB mp4)
                </button>
              </center>
            </div>

            <!-- 동영상 업로드 필드 -->
            <div class="mb-3 row" v-if="activeUpload === 'video'">
              <input type="file" class="form-control" id="fileSelectorVideo" accept="video/mp4">
              <div class="alert alert-secondary" role="alert" style="font-size:small;">
                <div>File 확장자: mp4</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-9" v-else>
          <!-- <div class="col-md-9" v-else-if="(this.notice.addImageFlag === 'Y' || this.notice.addVideoFlag === 'Y')">   -->
          <div class="col">
            <div class="form-control" type="file" accept="image/jpg">
              <div class="position-relative">
                <img v-if="this.notice.addImageFlag === 'Y'" :src="`/download/notice/${this.notice.ctCd}/${this.notice.noticeCode}.jpg`" class="img-fluid" />
                <video v-else-if="this.notice.addVideoFlag === 'Y'" :src="`/download/notice/${this.notice.ctCd}/${this.notice.noticeCode}.mp4`" class="img-fluid" controls/>
                <div>
                  <p class="position-absolute top-0 end-70" style="cursor:pointer;" @click="sealingImage(this.noticeCode, 'N')">
                    <i class="bi bi-eye-slash text-warning fs-3"></i></p>
                </div>                
              </div>
              <!-- 이미지 업로드 필드 -->
              <div class="mt-2 row"  v-if="this.notice.addImageFlag === 'Y'">
                <input type="file" class="form-control" id="fileSelectorImage" accept="image/jpeg">
                <div class="alert alert-secondary mt-1" role="alert" style="font-size:small;">
                  <div>File 확장자: jpg (최대 2MB) </div>
                </div>
              </div>

              <!-- 동영상 업로드 필드 -->
              <div class="mt-2 row"  v-if="this.notice.addVideoFlag === 'Y'">
                <input type="file" class="form-control" id="fileSelectorVideo" accept="video/mp4">
                <div class="alert alert-secondary mt-1" role="alert" style="font-size:small;">
                  <div>File 확장자: mp4 (최대 20MB)</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1" v-if="this.notice.nStatus != 'N'">
          <button type="button" class="btn btn-lg btn-success" @click="noticeUpdate();">변경</button>
        </div>
        <div class="col-auto d-grid p-1" v-else>
          <button type="button" class="btn btn-lg btn-success" disabled>변경</button>
        </div>
        
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">공지리스트</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>

</template>

<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import moment from 'moment';
import { deleteFile } from '../assets/js/commonFunctions'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      notice: {},
      showImage: "",               //이미지표시여부

      activeUpload: null,         // 'image' 또는 'video' 중 하나만 활성화
      // notice: {
      //   nTitle: "",
      //   nContent: "",
      //   nFrom: "",
      //   nTo: "",
      //   insDate: "",
      //   insId: "",
      //   nUseFlag: "" //공지상태 default: 비공지
      // }
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    //this.seqNotice = this.$route.query.seqNotice;
    this.noticeCode = this.$route.query.seqNotice;
    //console.log("seqNotice:", this.seqNotice);
    this.getNoticeDetail();
  },  
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getNoticeDetail() {
      let nDetail = await this.$api("/api/noticeDetail", {param: [this.noticeCode]});
      if(nDetail.length > 0) {
        this.notice = nDetail[0];
      }
      this.notice.nFrom = this.getDateFormat(this.notice.nFrom);
      this.notice.nTo = this.getDateFormat(this.notice.nTo);
      if(this.notice.status === "Y") {
        this.notice.nStatus = "Y";
        //alert("수정불가")
      }
      console.log("notice:",this.notice)
    },
    goToList() 
    {
      this.$router.push({path: '/noticeList'});
    },
    // changePdtCategory() {
    //   this.catCode = this.productInfo.catCode;
    // },

    toggleUpload(type) {
      this.activeUpload = this.activeUpload === type ? null : type;

      //이미지와 동영상 택1
      // if(type === "image")
      // {
      //   this.notice.addImageFlag = (this.notice.addImageFlag === "N") ? "Y" : "N"
      //   this.notice.addVideoFlag = "N"
      // }
      // else if(type === "video")
      // {
      //   this.notice.addVideoFlag = (this.notice.addVideoFlag === "N") ? "Y" : "N"
      //   this.notice.addImageFlag = "N"
      // }
      // else
      // {
      //   this.notice.addImageFlag = "N"
      //   this.notice.addVideoFlag = "N"
      // }
    },

    // toggleUpload(type) {
    //   this.activeUpload = this.activeUpload === type ? null : type;

    //   //이미지와 동영상 택1
    //   if(type === "image")
    //   {
    //     this.notice.addImageFlag = (this.notice.addImageFlag === "N") ? "Y" : "N"
    //     this.notice.addVideoFlag = "N"
    //   }
    //   else if(type === "video")
    //   {
    //     this.notice.addVideoFlag = (this.notice.addVideoFlag === "N") ? "Y" : "N"
    //     this.notice.addImageFlag = "N"
    //   }
    //   else
    //   {
    //     this.notice.addImageFlag = "N"
    //     this.notice.addVideoFlag = "N"
    //   }
    // },

    async noticeUpdate()
    {
      if(!this.notice.nTitle) {
        this.$refs.txt_nTitle.focus();
        return this.$swal.fire("필수누락: 공지제목");
      }
      if(!this.notice.nContent) {
        this.$refs.txt_nContent.focus();
        return this.$swal.fire("필수누락: 공지내용");
      }
      if(!this.notice.nFrom) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("필수누락: 개시일");
      }
      if(!this.notice.nTo) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("필수누락: 종료일");
      }
      if(this.notice.nStart > this.notice.nEnd) {
        return this.$swal.fire("입력오류: 개시일&종료일");
      }
      this.notice.nFrom = moment(this.notice.nFrom).format("YYYY-MM-DD");
      this.notice.nTo = moment(this.notice.nTo).format("YYYY-MM-DD");

      this.notice.updId = this.user.handlerId;

      console.log("this.notice:", this.notice);

      // 동영상 업로드 처리
      if (this.activeUpload === "video") {

        let videoFile = document.getElementById('fileSelectorVideo').files;
        
        if (videoFile && videoFile[0].size > 20 * 1024 * 1024 ) 
        {
          this.$swal.fire("","동영상 업로드는 20메가 이하로 제한됩니다", "warning")
          return false;
        }
      }

      let addImageFlag = ""
      let addVideoFlag = ""
      // 이미지 업로드 처리
      if (this.activeUpload === "image") {
        const imgFile = document.getElementById('fileSelectorImage').files;
        if (imgFile.length > 0) 
        {
          await this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode, "image");
          addImageFlag = "Y"
          addVideoFlag = "N"
        }
      }
      // 동영상 업로드 처리
      else if (this.activeUpload === "video") {
        const videoFile = document.getElementById('fileSelectorVideo').files;
        if (videoFile.length > 0) 
        {
          await this.uploadFile(videoFile, this.notice.ctCd, this.notice.noticeCode, "video");
          addImageFlag = "N"
          addVideoFlag = "Y"
        }
      }
      //그 외 내용업로드 -> 플레그유지
      else
      {
        addImageFlag = this.notice.addImageFlag
        addVideoFlag = this.notice.addVideoFlag
      }      

      let resUpd_notice = await this.$api("/api/noticeUpdate2", {param: [
        this.notice.nTitle, this.notice.nContent, this.notice.nFrom, this.notice.nTo, this.notice.updId, addImageFlag, addVideoFlag, this.noticeCode
      ]});
      
      if(resUpd_notice.affectedRows < 0)
      {
        this.$swal.fire('','업로드 정보의 저장에 오류가 발생했습니다. 재시도해 주세요', 'warning');
        return false
      }
      else
      {
        this.$swal.fire('','업로드 정보가 저장되었습니다.', 'success');
        location.reload(true)
      }
    },

    /** 이미지 or 동영상 사본등록 */
    async uploadFile(file, id, passNo, fileType) {
      // 파일사이즈 크기보정 펑션호출(파일크기한도: 이미지 2메가/동영상 20메가)
      // 동영상 크기한도: 20메가체크 => 압축 api의 초기화 불능으로 압축기능 적용은 연기(2024-11-28)
      let files = ""

      if (fileType === "video" && file[0].size > 20 * 1024 * 1024) 
      {
        this.$swal.fire("","동영상 업로드는 20메가 미만으로 제한됩니다", "warning")
        return false;
      }
      else
      {
        files = await this.compressFile(file[0], fileType)
      }
      
      let fileLength = 0
      let fileType_tmp = "";
      let licenseFile = "";
      //let uploadedFile = "";
      let data = null;

      if (files) {
        fileLength    = files.name.length;
        fileType_tmp  = files.name.lastIndexOf(".");
        let extension = files.name.substring(fileType_tmp + 1, fileLength).toLowerCase();
        //this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();

        // 확장자 검증
        if (fileType === "image" && (extension !== "jpg" && extension !== "jpeg")) 
        {
          return this.$swal("이미지 파일은 jpg, jpeg, png 형식만 지원합니다.");
        }
        else if (fileType === "video" && extension !== "mp4")
        {
          return this.$swal("동영상 파일은 mp4 형식만 지원합니다.");
        }

        licenseFile = `${passNo}.${extension}`;
        data = await this.$base64(files);
      }

      console.log("licenseFile:", licenseFile)

      const { error } = await this.$api(`/upload/notice/${id}/${licenseFile}`, { data });

      console.log(`/upload/notice/${id}/${licenseFile}`);
      
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      // else{
      //   this.$swal("업로드 완료");
      //   this.$router.push({path: '/noticeList'});
      // }
    },

    async compressFile(file, fileType) 
    {
      // 이미지 크기한도: 2메가
      if (fileType === "image" && file.size > 2 * 1024 * 1024) 
      //if (fileType === "image" && file.size > 2000000)
      {
        console.log("이미지 압축 중...");
        return await this.compressImage(file);
      } 
      // 동영상 크기한도: 20메가
      else if (fileType === "video" && file.size > 20 * 1024 * 1024) 
      {
        // console.log("동영상 압축 중...");
        // return await this.compressVideo(file);           // 압축api의 초기화 불능으로 적용홀딩(2024-11-28)
        this.$swal.fire("","동영상 업로드는 20메가 미만으로 제한됩니다", "warning")
        return false;
      }
      return file; // 크기 초과하지 않으면 원본 파일 반환
    },

    async compressImage(file) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const maxDimension = 1920;
            let width = img.width;
            let height = img.height;

            if (width > maxDimension || height > maxDimension) {
              if (width > height) {
                height = Math.round((height * maxDimension) / width);
                width = maxDimension;
              } else {
                width = Math.round((width * maxDimension) / height);
                height = maxDimension;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                if (blob.size > 2 * 1024 * 1024) {
                  reject(new Error("이미지를 2MB 이하로 압축할 수 없습니다."));
                } else {
                  resolve(new File([blob], file.name, { type: "image/jpeg" }));
                }
              },
              "image/jpeg",
              0.7 // 품질 설정
            );
          };
          img.src = event.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    sealingImage(nSeqNo, flag) {
      this.$swal.fire({
        text: '첨부파일을 삭제합니까?',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`
      }).then(async (result) => {
        if (result.isConfirmed) 
        {
          // let addFlag = ""
          // if(this.notice.addImageFlag === "Y")
          // {
          //   addFlag = "image"
          // }
          // else if(this.notice.addVideoFlag === "Y")
          // {
          //   addFlag = "video"
          // }
          // else
          // {
          //   this.$swal.fire("", "첨부파일의 종류가 확인되지 않습니다.", "warning")
          //   return false
          // }
          
          //공지는 동영상 또는 이미지 택1이므로 분기가 필요없다
          let result = await this.callDeleteFile()

          if(result === "OK")
          {
            await this.$api("/api/noticeImageSealing_new",{param:[ this.user.handlerId, flag, flag, nSeqNo ]});
            this.$swal.fire('', '파일삭제완료', 'success').then(() => {
              location.reload(true)
            })
          }
          else
          {
            this.$swal.fire('', '파일삭제실패. 재시도해주시고, 반복실패되면 관리자에 문의해주세요', 'error')
            return false
          }
        } 
      });
    },

    //파일삭제함수 공통함수호출
    async callDeleteFile()
    {
      console.log(this.notice.addImageFlag, "//", this.notice.addVideoFlag)
      let fileExt = ""
      if(this.notice.addImageFlag === "Y" && (this.notice.addVideoFlag === "" || this.notice.addVideoFlag == null || this.notice.addVideoFlag === "N"))
      {
        fileExt = "jpg"
      }
      else if(this.notice.addVideoFlag === "Y" && (this.notice.addImageFlag === "" || this.notice.addImageFlag == null || this.notice.addImageFlag === "N"))
      {
        fileExt = "mp4"
      }
      
      let targetPath = ""
      let fileName = ""
      let retVal = ""

      targetPath = `/notice/${this.notice.ctCd}/`
      fileName = `${this.notice.noticeCode}.${fileExt}`
      console.log("targetPath:", targetPath, "fileName:", fileName)

      retVal = await deleteFile(this.$api, targetPath, fileName);
      console.log("retVal:", retVal)

      return retVal
    },
  }
}
</script>