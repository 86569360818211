<template>
  <div :class="{'hidden-banner': isHidden, 'not-hidden-banner': !isHidden}">
    <div class="remote-control banner1">
      <button @click="onClick">Banner1</button>
    </div>
    <div class="remote-control banner2 mt-2">
      <button @click="onClick">Banner2</button>
    </div>
    <div class="remote-control banner3 mt-2">
      <button @click="onClick">Banner3</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* 우측 배너 */
.remote-control button {
  border: none;
  border-radius: 5px;
  padding: 30px;
  cursor: pointer;
  width: 310px;
  height: 110px;
}

.banner1 button{
  background-color: #007bff;
  color: #fff;
}

.banner2 button{
  background-color: #044c12;
  color: #fff;
}

.banner3 button{
  background-color: #5e0749;
  color: #fff;
}

.remote-control button:hover {
  background-color: #0056b3;
}
</style>
