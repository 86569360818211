<template>
  <div>
    <main id="main">
    <!-- Top content -->
    <div class="top-content">
      <!-- Carousel -->
      <div id="carousel-example" class="carousel slide front" data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="4" aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="5" aria-label="Slide 6"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="6" aria-label="Slide 7"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="7" aria-label="Slide 8"></button>
          <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="8" aria-label="Slide 9"></button>
          <!-- <button type="button" data-bs-target="#carousel-example" data-bs-slide-to="9" aria-label="Slide 10"></button> -->
        </div>
        <div class="carousel-inner">

          <!-- 0 -->
          <div class="carousel-item active">
            <a href="/membershippage">
              <img src="../assets/img/main/membershiprenewal.gif" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/membershiprenewal_mo.gif" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
            </a>
          </div>

          <div class="carousel-item">
            <a href="/threecourse">
              <img src="../assets/img/main/threecourselol.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/threecourselol_mobile.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg" style="color:#000">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/setsetset">
              <img src="../assets/img/setsetsetmain.jpg" class="d-block vw-100 vh-100 mimg" alt="slide-img-1">
              <div class="carousel-caption cimg">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/marukoma">
              <img src="../assets/img/main/marukoma_mainlol_pc.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/marukoma_mainlol_mobile.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/bloggertrip">
              <img src="../assets/img/main/bloggertrip-pc.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/bloggertrip-mo.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg" style="color:a#000">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/bullfight">
              <img src="../assets/img/main/bull_lol.jpg" class="d-block vw-100 vh-100 mimg" alt="slide-img-1">
              <div class="carousel-caption cimg">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/detail?productId=L-2024125-1706174265647&salesRoute=L">
              <img src="../assets/img/main/ddd.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/dda.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg" style="color:#000">
              </div>
            </a>
          </div>

          <div class="carousel-item">
            <a href="/yamaguchi">
              <img src="../assets/img/main/yamaguchi_mrl.jpg" class="d-block vw-100 vh-100 mimg" alt="slide-img-1">
              <div class="carousel-caption cimg">
                <!-- <p class="cap">기타규슈 출발, 아름다움의 대명사 야마구치로</p>
                <p class="font-bold33" style="font-weight: bold;">부관훼리 맞춤 시모노세키항 경유</p> -->
              </div>
            </a>
          </div>

          <!-- <div class="carousel-item">
            <a href="/detail?productId=L-2024513-1715564826517&salesRoute=L">
              <img src="../assets/img/main/iranroll.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/iranroll-m.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg">
              </div>
            </a>
          </div> -->

          <div class="carousel-item">
            <a href="/kinmen">
              <img src="../assets/img/main/kinmenroll.jpg" class="vw-100 vh-100 mimg pc" alt="slide-img-1">
              <img src="../assets/img/main/kinmenroll_mobile.jpg" class="vw-100 vh-100 mimg mobile" alt="slide-img-1">
              <div class="carousel-caption cimg" style="color:a#000">
                <!--<p class="cap"></p>
                <p class="font-bold33" style="font-weight:bold;" ></p>-->
              </div>
            </a>
          </div>
          
        </div>
        
        <button class="carousel-control-prev" type="button" data-bs-target="#carousel-example" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carousel-example" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- End carousel -->
    </div>

    <!-- ======= Main ======= -->
    <!-- <main> -->
      <!-- ======= Qmenu Section ======= -->
      <section class="qmenu" id="qmenu">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="row text-center">
                <div class="col-md-4 col-4">
                  <div class="box align-self-end">
                    <a href="javascript:void(0)" @click="showNdt('ND'); scrollToElement('saleitem')" class="top">
                      <div v-if="this.sellerFlag === 'nd'">
                        <!-- <img src="../assets/img/new-test/Ndaytrip2.png" class="ntrip black" alt=""> -->
                        <img src="../assets/img/Ndaytrip.png" class="ntrip" alt="">
                      </div>
                      <div v-else>
                        <img src="../assets/img/new-test/Ndaytrip2.png" class="ntrip black" alt="">
                        <img src="../assets/img/Ndaytrip.png" class="ntrip color" alt="">
                      </div>
                      <!-- <img src="../assets/img/new-test/Ndaytrip2.png" class="ntrip black" alt="">
                      <img src="../assets/img/Ndaytrip.png" class="ntrip color" alt=""> -->
                    </a>
                      <h4 class="my-2">원데이버스투어
                        <a href="javascript: void(0)" @click="popover('ndaytrip')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="bottom"
                      title="2017년 오픈하여 지금까지 높은 고객 만족과 리뷰가 증명합니다. 
지역 전문 한국인 가이드가 동행, 여행 기간 중 1일을 알차게, 간편하게, 실속있게 
여행 할 수 있는 현지 일일 버스투어 브랜드 입니다"> <i class="bi bi-question-circle text-sm-muted"></i>
                        </a>
                      </h4> 
                  </div>
                </div>

                <div class="col-md-4 col-4" >
                  <div class="box align-self-end">
                    <a href="javascript: void(0)" @click="showNdt('NR'); scrollToElement('saleitem')">
                      <div v-if="this.sellerFlag === 'nr'">
                        <img src="../assets/img/Nthere.png" class="nthere" alt="">
                      </div>
                      <div v-else>
                        <img src="../assets/img/new-test/Nthere2.png" class="nthere black" alt="">
                        <img src="../assets/img/Nthere.png" class="nthere color" alt="">
                      </div>
                    </a>
                      <h4 class="my-2"> 현지 프라이빗 여행
                        <a href="javascript: void(0)" @click="popover('nthere')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="bottom"
                    title=" 대규모 모객성 투어가 아닌 가족, 친구, 연인, 모임 단위의 1개 그룹만 케어하며,
개별적으로 찾아가기 힘든 지역 및 숙박시설, 관광지를 중점으로 
지역 전문 한국인 가이드, 전용차량, 숙박이 포함된 프라이빗 여행 브랜드 입니다.">
                          <i class="bi bi-question-circle text-sm-muted"></i>
                        </a>
                      </h4>
                  </div>
                </div>

                <div class="col-md-4 col-4">
                  <div class="box align-self-end">
                    <a href="javascript: void(0)" @click="showNdt('VR'); scrollToElement('saleitem')">
                      <div v-if="this.sellerFlag === 'vr'">
                        <img src="../assets/img/Variety.png" class="variety" alt="">
                      </div>
                      <div v-else>
                        <img src="../assets/img/new-test/Variety2.png" class="variety black" alt="">
                        <img src="../assets/img/Variety.png" class="variety color" alt="">
                      </div>
                    </a>
                      <h4 class="my-2"> 기획전 테마여행
                        <a href="javascript: void(0)" @click="popover('nvariety')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="bottom"
                    title="일반적인 패키지 여행이 아닌 시즌별, 기간별 한정 이벤트여행, 기획여행, 테마여행 상품 브랜드 입니다.">
                          <i class="bi bi-question-circle text-sm-muted"></i>
                        </a>
                      </h4>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section><!-- End Qmenu Section -->

      <!-- ======= 상품 ======= -->
      <section id="saleitem" class="saleitem">
        <!-- 엔데이트립 -->
        <div>
          <div v-if="this.sellerFlag === 'nd'" class="my-5">
            <div class="section-title">
              <p>최고의 1일, 엔데이트립</p>
            </div>
            
            <div class="scrolling-wrapper">
              <div class="row scrolling-content gx-2">
                <button @click="scrollLeft_btn()" class="scroll-btn left"><b><i class="bi bi-caret-left"></i></b></button>

                <div class="col-12 col-sm-6 col-lg-4 px-3 mb-5" :key="i" v-for="(product, i) in productListForSales_ndaytrip_main">
                  <div class="nday">
                    <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">

                      <!-- 터치스크린기능 -->
                      <div v-touch:swipe.left="onSwipeLeft" v-touch:swipe.right="onSwipeRight">
                        <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="img-fluid hover-zoom" alt="..."/>
                        <img v-else :src="`/download/noImage/noImage.jpg`" class="img-fluid">

                        <!-- 뱃지표시 -->
                        <div class="badge">
                          <div v-if="product.badgeFlag === 'Y'">
                            <div class="badge1" style="width:140px;">
                              <small class="badge1-text" style="font-size: 1rem;">{{ product.badge }}</small>
                            </div>
                          </div>
                          <div v-if="product.badge2Flag === 'Y'">
                            <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width:140px;">
                              <small class="badge1-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left:10rem;width:140px;">
                              <small class="badge2-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                            </div>
                          </div>
                        </div>

                        <div class="nday-body">
                          <h5 class="nday-title">
                            {{ product.arName }}
                          </h5>
                          <p class="showPdtName">
                            {{ product.pdtNameKor }}
                          </p>

                          <div class="nday-price">
                            <div v-if="this.user.memLevel === 'P'">
                              <div v-if="product.dispPrice === 'mem'">
                                <div v-if="product.sMinPrice === product.memP">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                                </div>
                                <div v-else>
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                                  <small><font>원~</font></small>
                                  <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0"> (결제 후,</small>
                                  <b class="mx-1" style="color:#ffce00;font-size: 0.9rem;" v-if="product.memP*1 > 0">{{ getCurrencyFormat(product.memP)}}</b>
                                  <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0">원 환급)</small>
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="product.promoStatus === 'Y'">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                  <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div v-else-if="product.promoStatus === 'N'">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">                                  
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div v-else-if="product.promoStatus === 'E'">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">                                  
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div>
                            </div>

                            <div v-else-if="this.user.memLevel === 'S'">
                              <div v-if="product.dispPrice === 'mem'">
                                <div v-if="product.dcForStd > 0">
                                  <div>
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                    <b class="mx-1">
                                      {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.dcForStd/100)))}} <small><font> 원~</font></small>
                                    </b>
                                  </div>
                                  <div>
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <b class="mx-1" v-if="product.sMinPrice === product.memP">0원! <span style="font-size:small;">(연간무제한)</span></b>
                                    <b class="mx-1" v-else>{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}}
                                      <small><font> 원~</font></small>
                                    </b>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-if="product.sMinPrice === product.memS">
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                    <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                                  </div>
                                  <div v-else>
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                    <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice)}} <small><font>원~</font></small></b>
                                    <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0"> (결제 후, </small>
                                    <b class="mx-1" style="color:slategrey;font-size: 0.9rem;" v-if="product.memS*1 > 0">{{ getCurrencyFormat(product.memS)}}</b>
                                    <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0">원 환급) </small>
                                  </div>
                                  <div>
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <b class="mx-1" v-if="product.sMinPrice === product.memP">
                                      0원! <span style="font-size:small;">(연간무제한)</span>
                                    </b>
                                    <b class="mx-1" v-else>{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}}
                                      <small><font> 원~</font></small>
                                    </b>
                                  </div>
                                </div>
                              </div>

                              <div v-else>
                                <div v-if="product.promoStatus === 'Y'">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                  <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div v-else-if="product.promoStatus === 'N'">
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div v-else-if="product.promoStatus === 'E'">
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div>
                            </div>
                            
                            <!-- 비로그인, 일반회원 멤버십표시 -->
                            <div v-else>
                              <!-- 일반회원가격(default) -->
                              <div class="my-1" v-if="product.promoStatus === 'Y'">
                                <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div class="my-1" v-else-if="product.promoStatus === 'E'">
                                <div class="my-1">
                                  <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div> 
                              </div>
                              <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                <div class="my-1" v-if="(product.dcForMem_S > 0)">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">
                                    {{ getCurrencyFormat(product.pPrice)}} <small><font>원~</font></small>
                                  </b>
                                </div>
                                <div v-else class="my-1">
                                  <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                  <b class="mx-1">
                                    {{ getCurrencyFormat(product.pPrice)}} <small><font>원~</font></small>
                                  </b>
                                </div>
                              </div>

                              <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                                <div class="my-1" v-if="product.promoStatus === 'E'">
                                  <div>
                                    <div class="my-1">
                                      <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                      <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                      <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                      <small><font>원~</font></small>
                                    </div>
                                    <div class="my-1">
                                      <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                      <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                      <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                      <small><font>원~</font></small>
                                    </div>
                                  </div> 
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                  <div>
                                    <div class="my-1">
                                      <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                      <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                      <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                      <small><font>원~</font></small>
                                    </div>
                                    <div class="my-1">
                                      <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                      <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                      <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                      <small><font>원~</font></small>
                                    </div>
                                  </div> 
                                </div>
                              </div>

                              <!-- 스탠다드멤버십 상품 -->
                              <div class="my-1" v-if="product.memLevel_S === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1" v-if="getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100))) == 0">
                                  0원! <span style="font-size:small;">(연간무제한)</span>
                                </b>
                                <b class="mx-1" v-else>
                                  {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100)))}} <small><font> 원~</font></small>
                                </b>
                              </div>

                              <!-- 프리미엄멤버십 상품 -->
                              <div class="my-1" v-if="product.memLevel_P === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                                <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && (product.dcForStd > 0)">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">
                                    {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.dcForStd/100)))}} <small><font> 원~</font></small>
                                  </b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1" v-if="getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100))) == 0">
                                    0원! <span style="font-size:small;">(연간무제한)</span>
                                  </b>
                                  <b class="mx-1" v-else>
                                    {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}} <small><font> 원~</font></small>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <button @click="scrollRight" class="scroll-btn right"><b><i class="bi bi-caret-right"></i></b></button>
              </div>
            </div>
            
          </div>
        </div>

        <div class="container">
          <!-- NThere -->
          <div v-if="this.sellerFlag === 'nr'" class="my-5 nthere">
            <div class="section-title">
              <p>엔데어 추천상품</p>
            </div>
            <div class="row">
              <div class="row gx-2">
                <div class="col-12 col-lg-6 mb-1" :key="i" v-for="(product, i) in productListForSales_nthere">
                  <div class="card">
                    <div class="nther-img">
                      <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                      <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                    </div>
                    <!-- 뱃지표시 -->
                    <div class="badge">
                      <div v-if="product.badgeFlag === 'Y'">
                        <div class="badge1" style="width:140px;">
                          <small class="badge1-text" style="font-size: 1rem;">{{ product.badge }}</small>
                        </div>
                      </div>
                      <div v-if="product.badge2Flag === 'Y'">
                        <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width:140px;">
                          <small class="badge1-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                        </div>
                        <div v-else class="badge2" style="margin-left:10rem;width:140px;">
                          <small class="badge2-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="card-body mt-0">                     
                      <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">
                        <h5 class="card-title">
                          <div v-if="product.cityName != undefined">
                            <small><small><small><small class="align-top"></small></small></small></small>{{ product.pdtNameKor }}
                          </div>
                          <div v-else>
                            {{ product.pdtNameKor }}
                          </div>
                        </h5>
                      </a>
                      <div class="card-text" style="font-size: small; color:slategray">
                        <p>{{ product.pdtRemarks }}</p>
                      </div>

                      <div class="card-price">
                        <!-- 프리미엄+이외상품구매 -->
                        <div v-if="this.user.memLevel === 'P'">
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                          <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div v-else-if="this.user.memLevel === 'S'">
                          <div>
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                        </div>

                        <!-- 비로그인 또는 일반회원 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div class="my-1" v-if="(product.dcForMem_S > 0)">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div v-if="product.memDC === 'Y'">
                        <label style="background-color: dodgerblue; color:white;" class="memLabel">Membership 할인</label>
                      </div>  -->
                    </div>
                  </div>
                </div>

                <!-- 더보기 -->
                <div class="col-lg-12 d-flex justify-content-end">
                  <div id="section-flters">
                    <div v-if="this.more_nr === 'N' || this.more_nr === ''">
                      <a href="javascript: void(0)" @click = "getProductList('X','','nr')">
                        더보기
                      </a>
                    </div>
                    <div v-else>
                      <a href="javascript: void(0)" @click = "getProductList('','','nr')">
                        접기
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Variety -->
          <div v-else-if="this.sellerFlag === 'vr'" class="my-5 variety">
            <div class="section-title">
              <p>버라이어티팩</p>
              <span>일반적인 패키지 여행이 아닌 시즌별, 기간별 한정 이벤트여행, 기획여행, 테마여행 상품 브랜드입니다.</span>
            </div>
            <div class="row">
              <div class="row">
                <div class="col-12">
                  <!-- 캐러셀 -->
                  <a href="javascript: void(0)">
                    <div id="carouselvarietyControls" class="carousel slide mx-auto" data-bs-ride="carousel">
                      <div class="carousel-inner">
                        <div :class="`carousel-item ${j==0 ? 'active' : ''}`" :key="j" v-for="(product, j) in productListForSales_variety" id="vrImage">  
                          <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top vrBox" @click='goToDetail(product.pdtCode,product.salesRoute)' alt="..."/>
                          <div class="vtext">
                            <!-- 상품명 -->
                            <div class="vname">
                              {{ product.pdtNameKor }}
                            </div>
                            <!-- 가격 -->
                            <div class="vprice">
                              <div v-if="this.user.memLevel === 'P'">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div v-else-if="this.user.memLevel === 'S'">
                                <div>
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>  
                                </div>
                                <div>
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>  
                                </div>
                              </div>

                              <!-- 로그인전 멤버십가격표시 -->
                              <div v-else style="text-align:left; padding-left: 4rem;">
                                <!-- 일반회원가격(default) -->
                                <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                                  <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                  <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                  <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'E'">
                                  <div>
                                    <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                    <small><font>원~</font></small>
                                  </div> 
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                  <div class="my-1" v-if="(product.dcForMem_S > 0)">
                                    <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                  <div v-else class="my-1">
                                    <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                    <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                                    <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                                    <small><font>원~</font></small>
                                  </div>
                                </div>

                                <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                                  <div class="my-1" v-if="product.promoStatus === 'E'">
                                    <div>
                                      <div class="my-1">
                                        <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                        <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                        <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                        <small><font>원~</font></small>
                                      </div>
                                      <div class="my-1">
                                        <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                        <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                        <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                        <small><font>원~</font></small>
                                      </div>
                                    </div> 
                                  </div>
                                  <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                    <div>
                                      <div>
                                        <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                        <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                        <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                        <small><font>원~</font></small>
                                      </div>
                                      <div>
                                        <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                        <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                        <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                        <small><font>원~</font></small>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="prev">
                        <div class="back">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </div>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="next">
                        <div class="back">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </div>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- 엔타비 -->
          <div class="ntabi" v-if="sellerFlag === 'nt' || sellerFlag === 'nd' || sellerFlag === 'nr' || sellerFlag === 'vr'">
            <div class="section-title">
              <p>엔타비 추천상품</p>
            </div>
            <div class="row">
              <div class="row gx-2">
                <div class="col-12 col-md-6 col-lg-4 mb-1" :key="i" v-for="(product, i) in this.productListForSales_ntabi">
                  <div class="card">
                    <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                    <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                    <!-- 뱃지표시 -->
                    <div class="badge">
                      <div v-if="product.badgeFlag === 'Y'">
                        <div class="badge1" style="width:140px;">
                          <small class="badge1-text" style="font-size: 1rem;">{{ product.badge }}</small>
                        </div>
                      </div>
                      <div v-if="product.badge2Flag === 'Y'">
                        <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width:140px;">
                          <small class="badge1-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                        </div>
                        <div v-else class="badge2" style="margin-left:10rem;width:140px;">
                          <small class="badge2-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                        </div>
                      </div>
                    </div>
                    
                    <!-- 카드 제목 -->
                    <div class="card-body mt-1">
                      <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">
                        <h5 class="card-title">
                          <div v-if="product.cityName != undefined">
                            <small><small><small><small class="align-top"></small></small></small></small>{{ product.pdtNameKor }}
                          </div>
                          <div v-else>
                            {{ product.pdtNameKor }}
                          </div>
                        </h5>
                      </a>
                      <!-- 카드내용 -->
                      <div class="card-text">
                        <p>{{ product.pdtRemarks }}</p>
                      </div>
                      <!-- 카드 금액 -->
                      <div class="card-price">
                        <!-- 프리미엄+이외상품구매 -->
                        <div v-if="this.user.memLevel === 'P'">
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                          <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div v-else-if="this.user.memLevel === 'S'">
                          <div>
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                        </div>

                        <!-- 비로그인, 일반회원의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div v-if="(product.dcForMem_S > 0)">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 더보기 -->

                <div class="col-lg-12 d-flex justify-content-end">
                  <div id="section-flters">
                    <div v-if="this.more_nt === 'N' || this.more_nt === ''">
                      <a href="javascript: void(0)" @click = "getProductList('X','','nt')">
                        더보기
                      </a>
                    </div>
                    <div v-else>
                      <a href="javascript: void(0)" @click = "getProductList('','','nt')">
                        접기
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <!-- ======= 배너 ======= -->
      <div class="banner1 d-flex justify-content-center">
        <a href="/membershipPage">
          <img src="../assets/img/new-test/banner3.jpg" class="img-fluid pc">
          <img src="../assets/img/new-test/banner3-2.jpg" class="img-fluid pad">
          <img src="../assets/img/new-test/banner3-3.jpg" class="img-fluid mobile">
        </a>
      </div>


      <!-- ======= 베스트아이템 ======= -->
      <section id="bestitem" class="bestitem mb-5">
        <div class="container">
          <div class="section-title">
            <p><b class="text-danger" style="font-family:Arial Black, Verdana, Geneva, Tahoma, sans-serif; font-size:2.5rem;">
              <i>BEST SELLER</i>
            </b></p>
          </div>
          <div class="row gx-2 mt-3">
            <div class="col-12 col-md-6 col-lg-4 my-2" :key="i" v-for="(product, i) in this.productListForSales_best">
              <a class="card" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">
                <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                <!-- 뱃지표시 -->
                <div class="badge">
                  <div v-if="product.badgeFlag === 'Y'">
                    <div class="badge1" style="width:140px;">
                      <small class="badge1-text" style="font-size: 1rem;">{{ product.badge }}</small>
                    </div>
                  </div>
                  <div v-if="product.badge2Flag === 'Y'">
                    <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width:140px;">
                      <small class="badge1-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                    </div>
                    <div v-else class="badge2" style="margin-left:10rem;width:140px;">
                      <small class="badge2-text" style="font-size: 1rem;">{{ product.badge2 }}</small>
                    </div>
                  </div>
                </div>
                <!-- 카드 제목 -->
                <div class="card-body">
                  <h5 class="card-title">
                    <div class="b-height" v-if="product.cityName != undefined">
                      <small><small><small><small class="align-top"></small></small></small></small>{{ product.pdtNameKor }}
                    </div>
                    <div v-else>
                      {{ product.pdtNameKor }}
                    </div>
                    <div class="mt-3">
                      <div v-if="this.user.memLevel === 'P'">
                        <div v-if="product.dispPrice === 'mem'">
                          <div v-if="product.sMinPrice === product.memP">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                          </div>
                          <div v-else>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">
                              {{ getCurrencyFormat(product.sMinPrice)}}</b>
                            <small><font>원~</font></small>
                            <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0"> (결제 후,</small>
                            <b class="mx-1" style="color:#ffce00;font-size: 0.9rem;" v-if="product.memP*1 > 0">{{ getCurrencyFormat(product.memP)}}</b>
                            <small style="color:white;font-size: 0.8rem;" v-if="product.memP*1 > 0">원 환급)</small>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="product.promoStatus === 'Y'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'N'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'E'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>
                      </div>

                      <div v-else-if="this.user.memLevel === 'S'">
                        <div v-if="product.dispPrice === 'mem'">
                          <div v-if="product.dcForStd > 0">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice-(product.sMinPrice*(product.dcForStd/100)))}}</b>
                          </div>
                          <div v-else>
                            <div v-if="product.sMinPrice === product.memS">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                            </div>
                            <div v-else>
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><font>원~</font></small>
                              <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0"> (결제 후, </small>
                              <b class="mx-1" style="color:slategrey;font-size: 0.9rem;" v-if="product.memS*1 > 0">{{ getCurrencyFormat(product.memS)}}</b>
                              <small style="color:white;font-size: 0.8rem;" v-if="product.memS*1 > 0">원 환급) </small>
                            </div>
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <b class="mx-1" v-if="product.sMinPrice === product.memP">0원! <span style="font-size:small;">(연간무제한)</span></b>
                            <b class="mx-1" v-else>{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}}
                              <small><font> 원~</font></small>
                            </b>
                          </div>
                        </div>
                        
                        <div v-else>
                          <div v-if="product.promoStatus === 'Y'">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'N'">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div v-else-if="product.promoStatus === 'E'">
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>
                      </div>
                      <!-- 로그인전 상품도 멤버십표시 -->
                      <div v-else>
                        <!-- 일반회원가격(default) -->
                        <div class="my-1" v-if="product.promoStatus === 'Y'">
                          <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                          <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                            <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div class="my-1" v-else-if="product.promoStatus === 'E'">
                          <div>
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div> 
                        </div>
                        <div class="my-1" v-else-if="product.promoStatus === 'N'">
                          <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                          <div class="my-1" v-if="(product.dcForMem_S > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else>
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                        </div>

                        <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                          <div class="my-1" v-if="product.promoStatus === 'E'">
                            <div class="my-1">
                              <div>
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div>
                              <div class="my-1">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                <small><font>원~</font></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                <small><font>원~</font></small>
                              </div>
                            </div> 
                          </div>
                        </div>

                        <!-- 스탠다드멤버십 상품 -->
                        <div class="my-1" v-if="product.memLevel_S === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                          <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                          <b class="mx-1" v-if="product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100)) == 0">
                            0원! <span style="font-size:small;">(연간무제한)</span>
                          </b>
                          <b class="mx-1" v-else>
                            {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_S/100)))}} <small><font> 원~</font></small>
                          </b>
                        </div>

                        <!-- 프리미엄멤버십 상품 -->
                        <div class="my-1" v-if="product.memLevel_P === 'Y' && ((product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null))">
                          <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && (product.dcForStd > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.dcForStd/100)))}}</b>
                            <small><font> 원~</font></small>
                          </div>
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                          <b class="mx-1" v-if="product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)) == 0">
                            0원! <span style="font-size:small;">(연간무제한)</span>
                          </b>
                          <b class="mx-1" v-else>
                            {{ getCurrencyFormat(product.sMinPrice*1 - (product.sMinPrice*(product.maxDC_P/100)))}} <small><font> 원~</font></small>
                          </b>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
              </a>
            </div>

            <!-- 더보기 -->
            <div class="col-lg-12 d-flex justify-content-end">
              <div id="section-flters">
                <div v-if="this.more_be === 'N' || this.more_be === ''">
                  <a href="javascript: void(0)" @click = "getProductList('X','','be')">
                    더보기
                  </a>
                </div>
                <div v-else>
                  <a href="javascript: void(0)" @click = "getProductList('','','be')">
                    접기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </main>
  </div>
</template>

<script>
import { recentView } from '../assets/js/recentView';

export default {
  data() {
    return {
      productListForSales: [], //판매용 상품리스트
      productListForSales_ntabi: [],
      productListForSales_ndaytrip: [],
      productListForSales_ndaytrip_main: [],
      productListForSales_nthere: [],
      productListForSales_variety: [],
      productListForSales_best: [],   //베스트아이템

      // noticeList: [], //공지사항 리스트
      // pastFlag: "", //과거플래그
      sellerFlag: "nt", //판매처(nt / nd)
      more_nt: "N",
      more_nd: "",
      more_nr: "",
      more_vr: "",
      more_be: "N",      //베스트상품

      memLv: "",         //멤버십등급(S,P)
      memDC: [0,0,0,],    //멤버십환급율(성인,소인,유아)

      // maxDC_P: 0,        //프리미엄 최대환급율
      // maxDC_S: 0,        //스탠다드 최대환급율
      // dcForMem_P: 0,     //프리미엄 일반상품 할인율
      // dcForMem_S: 0,     //스탠다드 일반상품 할인율
      // dcForStd: 0,       //프리미엄전용을 스탠다드가 구매할 경우 할인율
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
      price_memP: 0,        //프리미엄 환급액
      price_memS: 0,        //스탠다드 환급액
      price_dcForStd: 0,    //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,  //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,  //일반상품을 스탠다드가 구매시 할인액
    }; 
  },
  created() {
    this.getProductList();
    //this.getNoticeList("Y"); //공지&이벤트 리스트
  },
  mounted() {
    //캐러셀 순서랜덤화
    // this.randomizeActiveCarouselItem();
    this.showNdt('ND')      //페이지 초기오픈
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다  
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    },
  },  
  methods: {
    //캐러셀 랜덤동작
    // randomizeActiveCarouselItem() {
    //   const carouselInner = document.querySelector(".carousel-inner");
    //   const carouselItems = carouselInner.querySelectorAll(".carousel-item");
    //   const randomIndex = Math.floor(Math.random() * carouselItems.length);

    //   //active를 제거
    //   carouselItems.forEach((item) => item.classList.remove("active"));

    //   //랜덤하게 active를 추가
    //   carouselItems[randomIndex].classList.add("active");
    // },

    //첫번째를 제외한 나머지 랜덤화
    randomizeActiveCarouselItem() {
      const carouselInner = this.$el.querySelector(".carousel-inner");
      const carouselItems = carouselInner.querySelectorAll(".carousel-item");
      const itemsCount = carouselItems.length - 1;
      const randomIndex = 1 + Math.floor(Math.random() * itemsCount);

      carouselItems.forEach((item, index) => {
        if (index !== 0) {
          item.classList.remove("active");
        }
      });

      carouselItems[randomIndex].classList.add("active");
    },


    onSwipeLeft(){
      console.log("SwipeLeft")
    },
    onSwipeRight(){
      console.log("SwipeRight")
    },

    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value){
      return this.$currencyFormat(value);
    },

    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    
    popover(param){
      if(param === "ndaytrip")
      {
        this.$swal.fire({
                    html: '<small class="text-muted"><small>2017년 오픈하여 지금까지 높은 고객 만족과 리뷰가 증명합니다.<br>지역 전문 한국인 가이드가 동행, 여행 기간 중 1일을 알차게, 간편하게, 실속있게 여행 할 수 있는 현지 일일 버스투어 브랜드 입니다</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })        
      }
      else if(param === "nthere")
      {
        this.$swal.fire({
                    html: '<small class="text-muted"><small>대규모 모객성 투어가 아닌 가족, 친구, 연인, 모임 단위의 1개 그룹만 케어합니다.<br> 개별적으로 찾아가기 힘든 지역 및 숙박시설, 관광지를 중점으로 지역 전문 한국인 가이드, 전용차량, 숙박이 포함된 프라이빗 여행 브랜드 입니다.</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })        
      }
      else if(param === "nvariety")
      {
        this.$swal.fire({
                    html: '<small class="text-muted"><small>일반적인 패키지 여행이 아닌 시즌별, 기간별 한정 이벤트여행, 기획여행, 테마여행 상품 브랜드 입니다.</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })        
      }      
    },
    showNdt(flag){
      //this.sellerFlag = (this.sellerFlag === "nt") ? "nd" : "nt";
      if(flag === "ND")
      {
        this.sellerFlag = (this.sellerFlag != "nd") ? "nd" : "nt"
      }
      else if(flag === "NR")
      {
        this.sellerFlag = (this.sellerFlag != "nr") ? "nr" : "nt"
      }
      else if(flag === "VR")
      {
        this.sellerFlag = (this.sellerFlag != "vr") ? "vr" : "nt"
      }
      this.getProductList("","",this.sellerFlag);
    },

    async getProductList(p1, p2, p3)    //p1(X:더보기) p2() p3(nt nd nr be)
    {
      //멤버십할인정보체크
      if(this.user.memNo > 999)
      {
        this.memLv = this.user.memLevel   //멤버십등급
      }

      //더보기 처리로직
      if(p3 === "nt")
      {
        this.more_nt = (this.more_nt == 'N') ? "Y" : "N"
      }
      // if(p3 === "nd")
      //   {
      //     this.more_nd = (this.more_nd == 'N') ? "Y" : "N"
      //   }
      if(p3 === "nr")
      {
        this.more_nr = (this.more_nr == 'N') ? "Y" : "N"
      }
      // if(p3 === "vr")
      //   {
      //     this.more_vr = (this.more_vr == 'N') ? "Y" : "N"
      //   }
      if(p3 === "be")
      {
        this.more_be = (this.more_be == 'N') ? "Y" : "N"
      }

      if(p1 === "X")
      {
        let sMinPrice = 0;
        let sMargin = 0;

        //추천상품 더보기
        let productListForSales_tmp = await this.$api("/api/productListForSales", {param: ['%','%']});

        //마진포함 가격
        let i = 0;
        while(i < productListForSales_tmp.length)
        {
          if(productListForSales_tmp[i].marginMethod === "P")
          {
            sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
          }
          else
          {
            sMargin = productListForSales_tmp[i].basicMargin;
          }
          sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
          productListForSales_tmp[i].sMinPrice = sMinPrice;
          i++;
        }

        this.productListForSales = productListForSales_tmp.filter((item) => {
          return (item.mdChoice == "Y" && (item.assoFlag == null || item.assoFlag == ""));
        });

        if(p3 === 'nr')
        {
          let productListForSales_nthere = this.productListForSales.filter((item) => {
            return (item.nrFlag === 'Y');
          });
          this.productListForSales_nthere = productListForSales_nthere;
          //console.log("productListForSales_nthere:",productListForSales_nthere)
        }
        if(p3 === 'be')
        {
          let productListForSales_best = this.productListForSales.filter((item) => {
            return (item.bestFlag === 'Y');
          });
          this.productListForSales_best = productListForSales_best;
          //console.log("productListForSales_nthere:",productListForSales_nthere)
        }        

        //todo: 엔데트사이트 가동 시, 엔타비에서는 엔데트가 보이지 않도록 조치
        else
        {
          let productListForSales_ntabi = this.productListForSales.filter((item) => {
            return (item.sellerCode_nt === 'Y');
          });
          this.productListForSales_ntabi = productListForSales_ntabi;
        }
      }

      //기본보기
      else
      {
        let ntCd = "";
        let sRoute = "";
        let sMinPrice = 0;    //최소상품가
        let sMargin = 0;
        ntCd = (p1 == undefined || p1 == "") ? "%" : p1;
        sRoute = (p2 == undefined || p2 == "") ? "%" : p2;

        //console.log("params:", ntCd,sRoute)

        let productListForSales_tmp = await this.$api("/api/productListForSales", {param: [ntCd, sRoute]});
        //console.log("productListForSales_tmp:",productListForSales_tmp)
        
        let i = 0;
        while(i < productListForSales_tmp.length)
        {
          if(productListForSales_tmp[i].marginMethod === "P")
          {
            sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
          }
          else
          {
            sMargin = productListForSales_tmp[i].basicMargin;
          }
          sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
          productListForSales_tmp[i].sMinPrice = sMinPrice;

          i++;
        }

        this.productListForSales = productListForSales_tmp.filter((item) => 
        {
          return (item.mdChoice == "Y" && (item.assoFlag == null || item.assoFlag == ""));  //연계상품제외
        });

        if(p3 == 'nd')    //엔데트상품
        {
          let productListForSales_ndaytrip = this.productListForSales.filter((item) => {
            return (item.sellerCode_nd === 'Y');
          });
          this.productListForSales_ndaytrip = productListForSales_ndaytrip
          this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
            return item.pdtCode != 'L-2023921-1695276228098'
          })
          //console.log("productListForSales_ndaytrip:", this.productListForSales_ndaytrip)
        }

        else if(p3 == 'nr')
        {
          let productListForSales_nthere = this.productListForSales.filter((item) => {
            return (item.nrFlag === 'Y');
          });
          //console.log("productListForSales_nthere:",productListForSales_nthere)
          this.productListForSales_nthere = productListForSales_nthere.slice(0,2)
        }
        else if(p3 == 'vr')
        {
          let productListForSales_variety = this.productListForSales.filter((item) => {
            return (item.vrFlag === 'Y');
          });
          //console.log("productListForSales_variety:",productListForSales_variety)
          this.productListForSales_variety = productListForSales_variety
        }        
        else
        {
          let productListForSales_ntabi = this.productListForSales.filter((item) => {
            return (item.sellerCode_nt === 'Y');
          });
          this.productListForSales_ntabi = productListForSales_ntabi
          //console.log("productListForSales_ntabi:",this.productListForSales_ntabi)
          this.productListForSales_ntabi = this.productListForSales_ntabi.slice(0,3)

          //베스트상품
          let productListForSales_best = this.productListForSales.filter((item) => {
            return (item.bestFlag === 'Y');
          });
          this.productListForSales_best = productListForSales_best
          //console.log("productListForSales_best:", this.productListForSales_best)

          this.productListForSales_best = this.productListForSales_best.slice(0,3)
        }
        
        //this.productListForSales = this.productListForSales.slice(0, 4);
      }

      //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      let maxDC_P = 0;        //프리미엄 최대환급율
      let maxDC_S = 0;        //스탠다드 최대환급율
      let dcForMem_P = 0      //이외상품 프리미엄 할인율
      let dcForMem_S = 0      //이외상품 스탠다드 할인율
      let dcForStd = 0        //프리이엄전용을 스탠다드가 구매시 할인율

      let marginPrice = 0;    //마진가격
      let promoPrice = 0;     //프로모션금액

      let price_memP = 0      //프리미엄 환급액
      let price_memS = 0      //스탠다드 환급액

      let price_dcForStd = 0  //프리이엄전용을 스탠다드가 구매시 할인액

      let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      //console.log(dcForMem_P,"/",dcForMem_S,"/",dcForStd)

      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        ////마진에 따른 마진가격
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        ////프로모션 상태에 따른 프로모션가격
        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice = (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice = this.productListForSales[i].promoRate;
          }

          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
          //console.log("promoPrice:",this.promoPrice)
        }

        //멤버십상품의 멤버십등급에 따른 멤버십환급가
        if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
        {
          //최대환급율
          maxDC_P = (this.productListForSales[i].maxDC_P == undefined) ? 0 : this.productListForSales[i].maxDC_P*1
          maxDC_S = (this.productListForSales[i].maxDC_S == undefined) ? 0 : this.productListForSales[i].maxDC_S*1
          //프리미엄전용을 스탠다드가 구매시 할인율
          dcForStd = (this.productListForSales[i].dcForStd == undefined) ? 0 : this.productListForSales[i].dcForStd*1

          //환급액
          price_memP = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_P / 100);
          price_memS = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_S / 100);

          //소숫점 절사
          this.price_memP = price_memP.toFixed(0)*1   //price_memP*1
          this.price_memS = price_memS.toFixed(0)*1   //price_memS*1
          
          //프리미엄전용을 스탠다드가 구매시 할인액 
          price_dcForStd = (this.productListForSales[i].minPrice+marginPrice)*(dcForStd / 100); 
          this.price_dcForStd = price_dcForStd.toFixed(0)*1
        } 

        //일반상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        if(this.productListForSales[i].salesRoute == "T" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
        {   
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          //할인방식에 따라 분기처리
          if(this.productListForSales[i].dcRateForMem_P === "P")
          {
            price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          }
          else
          {
            price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
          }
          
          if(this.productListForSales[i].dcRateForMem_S === "P")
          {
            price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
          }
          else
          {
            price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
          }

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }
        //멤버십미지정 로컬투어
        else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
        {
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
          
          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }

        //화면표시용 가격
        //구매자 멤버십
        if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
        {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격" 
            
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if(this.price_memP >= this.promoPrice && this.price_memP > 0)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memP = this.price_memP  //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율

                  this.productListForSales[i].memS = this.price_memS  //Std환급액
                }
                else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S' && this.price_memS > 0)
              {
                if(this.price_memS >= this.promoPrice)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memS = this.price_memS  //환급액

                  this.productListForSales[i].memP = this.price_memP  //Pre환급액                  
                }
                else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }

            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //STD환급액
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
              else if(this.user.memLevel === 'S')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //PRE환급액
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
            }
          }

          //멤버십+일반상품
          else
          {
            //일반상품지정(패키지, 로컬+엔데어 || 버라이어티)
            if(this.productListForSales[i].salesRoute == 'T' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"     //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"     //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"     //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                }
              }
            }
            
            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
            {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
              }
            }
          }
        }

        //구매자 비멤버십
        else
        {
          //프로모션이 있으면 할인처리
          if (this.productListForSales[i].promoStatus === "Y")
          {
            this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
              //console.log(`pPrice${i}: ${this.pPriceY}`);
              this.productListForSales[i].pPrice = this.pPriceY;
          } 
          else if (this.productListForSales[i].promoStatus == "E") 
          {
            this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceE;
          } 
          else if (this.productListForSales[i].promoStatus == "N") 
          {
            this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceN;
          }
        }
      }
      //console.log("productListForSales:", this.productListForSales)      
    },

    // async clickProduct(pCode){
    //   if(this.user.email != undefined)
    //   {
    //     //alert(this.user.email +"/"+ pCode)
    //     await recentView(this.$api, this.user.email, pCode)
    //   }
    // },
    async goToDetail(productId, salesRoute) {
      if(this.user.email != undefined)
      {
        await recentView(this.$api, this.user.email, productId)
      }      
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    
    scrollLeft_btn() {
      const container = this.$el.querySelector('.scrolling-content');
      const itemWidth = container.querySelector('.col-12.col-sm-6.col-lg-4.px-3.mb-5').offsetWidth;

      container.scrollBy({
        left: -itemWidth, 
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$el.querySelector('.scrolling-content');
      const itemWidth = container.querySelector('.col-12.col-sm-6.col-lg-4.px-3.mb-5').offsetWidth;

      container.scrollBy({
        left: itemWidth,
        behavior: 'smooth'
      });
    },
  }
};
</script>

<style>
@import "../assets/css/style.css";
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

/** 멤버십라벨 */
.memLabel {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 1rem;
  border-radius: 5%;
  color: white;
}


/*--------------------------------------------------------------
	# 메인 카로셀
--------------------------------------------------------------*/
#main .top-content {
  width: 100%;
  padding: 0;
  height:auto;
  margin-top:110px
}
#main .top-content .carousel-inner {
  position: relative;
  top: -70px;
  width: 100%;
  overflow: hidden;
  margin-bottom:-100px;
}
#main .top-content .front .carousel-control-prev, 
.top-content .front .carousel-control-next{
  bottom: 70px;
}
#main .top-content .carousel-control-prev {
  border-bottom: 0;
}
#main .top-content .carousel-control-next {
  border-bottom: 0;
}
#main .top-content .carousel-indicators{
  position: absolute;
  right: 0;
  bottom: 70px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
#main .top-content .carousel-indicators li {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
}

#main .carousel-item .pc{
  display: block;
}
#main .carousel-item .mobile{
  display: none;
}

@media (max-width:991px) {
  #main .top-content {
    margin-top:0
  }
  #main .top-content .carousel-inner .mimg{
    height:86vh !important
  }
  #main .carousel-item .pc{
    display: none;
  }
  #main .carousel-item .mobile{
    display: block;
  }
}
@media (max-width: 767px) {
  #main .top-content .carousel-indicators {
    display: none;
  }
  #main .top-content .carousel-inner .mimg{
    height:80vh !important
  }
}
@media (max-width: 575px) {
  #main .top-content .carousel-inner{
    top:0;
    margin-top:-16px
  }
  #main .top-content .carousel-inner .mimg{
    height:77vh !important
  }
  /* .top-content .carousel-item .mimg{
    border-radius:30px;
    border:1rem solid #fff
  } */
}



/* 메인글자 */
#main .carousel-item .cimg {
  text-align: left;
  bottom: 10%;
  font-size:1.6rem;
  animation: fadeInUp 1.3s;
}
#main .carousel-item .cimg .cap{
  font-family: 'Black Han Sans', sans-serif;
  font-size:4.2rem;
  margin-bottom:0
}
@media (max-width: 1300px){
  #main .carousel-item .cimg {
    font-size:1.5rem;
  }
  #main .carousel-item .cimg .cap{
    font-size:3.5rem;
  }
}
@media (max-width: 950px) {
  #main .carousel-item .cimg {
    font-size:1.3rem;
  }
  #main .carousel-item .cimg .cap{
    font-size:2.8rem;
    line-height: 45px;
  }
}
@media (max-width:500px) {
  #main .carousel-item .cimg{
    left:5%;
    bottom: 0;
    font-size:.8rem
  }
  #main .carousel-item .cimg .cap{
    font-size:2rem;
    line-height: 30px;
    margin-bottom:10px
  }
}



/*--------------------------------------------------------------
# Qmenu
--------------------------------------------------------------*/
.qmenu {
  margin-top: 8rem;
}
.qmenu h4 {
  font-size: .78rem;
}
.qmenu .box{
  position:relative
}
.qmenu .color{
  position:absolute;
  top:0;
  left:50%;
  transform:translateX(-50%);
  opacity: 0;
  transition: all .8s;
}
.qmenu .black{
  transition: all .5s;
}
.qmenu .box:hover .color{
  opacity: 1;
}
.qmenu .box:hover .black{
  opacity: 0;
}


@media (min-width: 250px) {
  .qmenu .ntrip {
    width: auto;
    height: 20px;
  }
  .qmenu .nthere {
    width: auto;
    height: 20px;
  }
  .qmenu .variety {
    width: auto;
    height: 20px;
  }
}
@media (min-width: 300px) {
  .qmenu .ntrip {
    width: auto;
    height: 23px;
  }
  .qmenu .nthere {
    width: auto;
    height: 23px;
  }
  .qmenu  .variety {
    width: auto;
    height: 23px;
  }
}
@media (min-width: 400px) {
  .qmenu .ntrip {
    width: auto;
    height: 27px;
  }
  .qmenu .nthere {
    width: auto;
    height: 27px
  }
  .qmenu .variety {
    width: auto;
    height: 27px;
  }
}
@media (min-width: 600px) {
  .qmenu .ntrip {
    width: auto;
    height: 35px;
  }
  .qmenu .nthere {
    width: auto;
    height: 35px
  }
  .qmenu .variety {
    width: auto;
    height: 35px;
  }
}



/*--------------------------------------------------------------
# 엔타비
--------------------------------------------------------------*/
#main .ntabi{
  margin-top:6rem
}
#main section {
  padding: 10px 0;
  overflow: hidden;
}

#main .section-title {
  text-align:center;
  margin-bottom: 2rem;
}
#main .section-title p {
  font-size: 2rem;
  font-weight: 600;
  color: #000;
}

#saleitem {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  #saleitem {
    margin-bottom: 45px;
  }
}

#main .saleitem .card{
  border:1px solid #ddd;
  margin-bottom:20px
}

#main .saleitem .card img {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
  top: 0;
}

#main .saleitem .card-img,
#main .saleitem .card-img-top,
#main .saleitem .card-img-bottom {
  width: 100%;
  height: 350px ;
  object-fit: cover;
}
@media (max-width:991px){
  #main .saleitem .card-img,
  #main .saleitem .card-img-top,
  #main .saleitem .card-img-bottom {
    height: 280px ;
  }
}

#main .saleitem .card .card-title {
  font-size: 1.2rem;
  line-height:25px;
  font-weight: 600;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
  height: 50px
}
#main .saleitem .card:hover .card-title {
  color: #76993D;
}


#main .saleitem .card .card-body {
  text-align: left;
  height:16.5rem
}
#main .saleitem .card-body .card-text{
  margin:10px 0;
  height:45px;
  font-size:.9rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#main .saleitem .card-body .card-price {
  margin: 7px 0;
  font-size: 1.2rem;
  font-weight: 600;
}




/*--------------------------------------------------------------
# 엔데이트립
--------------------------------------------------------------*/
#main .nday{
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  height:35rem;
}

#main .nday img{
  width:auto;
  height:35rem;
  object-fit: cover;
  border-radius: 20px;
  transition: all .7s ease-in-out;
}
#main .nday a:hover img {
  transform: scale(1.1);
}
#main .nday a:hover .mbus-icon {
  transform: scale(1);
}

#main .nday .nday-body{
  position:absolute;
  bottom:5%;
  left:5%;
  color:#fff
}
#main .nday .nday-body h5{
  font-family: 'Black Han Sans', sans-serif;
  font-size:3rem;
  margin-bottom:0
}

#main .nday .nday-price{
  text-align: left;
  font-size:1.3rem;
}

/* .showPdtName {
  max-width: 200px;
    white-space: nowrap; 줄바꿈금지
    overflow: hidden; 초과텍스트 히든처리
    text-overflow: ellipsis; 생략부호 추가 
} */

#main .nday .showPdtName{
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right:20px;
  font-size:1.1rem
}


/*--------------------------------------------------------------
# 엔데어
--------------------------------------------------------------*/
#main .nthere .card{
  border:none;
  padding:0 10px;
}
#main .nthere .nther-img{
  overflow: hidden;
  border-radius: 10px;
}
#main .nthere .card img{
  object-fit: cover;
  transition: all 0.7s ease-in-out;
}
#main .nthere .card:hover img {
  transform: scale(1.05);
}
#main .nthere .card:hover .mbus-icon{
  transform: scale(1);
}

#main .nthere .card .memLabel{
  margin-bottom:5px;
  padding:2px 5px
}
#main .nthere .card .card-title {
  font-size: 1.2rem;
  line-height:25px;
  font-weight: 600;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
  height: 25px
}
#main .nthere .card:hover .card-title {
  color: #76993D;
}
#main .nthere .card-body .card-text {
  margin:0;
  font-size: 1rem;
  color:#bbb;
  height:40px
}

.membership-label {
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: flex-start; /* 수평 정렬 시작점에서 정렬 */
}

.memLabel {
  margin-right: 10px; /* 오른쪽 마진 추가 */
}


/*--------------------------------------------------------------
# 버라이어티팩
--------------------------------------------------------------*/
#main .section-title span {
  font-size: .95rem;
  color: #000;
}
#main .variety{
  text-align: center;
}
#main .variety .carousel{
  width:80%;
  position: relative;
}
@media (max-width:991px){
  #main .variety .carousel{
    width:95%;
  }
}

#main .variety .card-img-top{
  height: 650px;
  object-fit: fill;
}
@media (max-width:712px){
  #main .variety .card-img-top{
    height: 350px;
  }
}

#main .variety #vrImgage .vrBox {
  position: relative;
  overflow: hidden;
}
#main .variety #vrImage:hover img {
  transform: scale(1.05);
}
#main .variety #vrImage:hover .mbus-icon{
  transform: scale(1);
}
#main .variety #vrImage img{
  transition: transform 0.7s ease-in-out;
}


#main .variety .vtext{
  color:#fff;
  position:absolute;
  bottom:0;
  left:50%;
  transform: translateX(-50%);
  width:100%;
  padding:30px 5px;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(192, 187, 187, 0) );
}
#main .variety .vtext .vname{
  font-size:1.6rem;
  font-weight: 600;
  padding-bottom:1px
}
#main .variety .vtext .vprice{
  color:#fff;
  font-size:1.5rem;
}

/* 화살표 */
#main .variety .carousel-control-prev{
  left:-8%;
  opacity: 1;
}
#main .variety .carousel-control-next{
  right:-8%;
  opacity:1
}
@media (max-width:575px){
  #main .variety .carousel-control-prev{
    left:-7%;
  }
  #main .variety .carousel-control-next{
    right:-7%;
  }
  #main .variety .vtext .vname{
    font-size:1.3rem
  }
  #main .variety .vtext .vprice{
    font-size:1rem
  }
}
#main .variety .back{
  width:5rem;
  height:5rem;
  background-color:#000;
  opacity: 0.3;
  transition: all 0.3s ease-in-out 
}
#main .variety .back:hover {
  opacity: 0.8;
}
#main .variety .back .carousel-control-prev-icon, 
#main .variety .back .carousel-control-next-icon{
  margin-top:24px
}
@media (max-width:712px){
  #main .variety .back{
    width:3rem;
    height:3rem;
  }
  #main .variety .back .carousel-control-prev-icon, 
  #main .variety .back .carousel-control-next-icon{
    margin-top:8px;
  }
}

#main .variety #vrImgage .vrBox {
  display: block;
}

/* 버라이어티팩 이미지 전체표시를 위해 축소화 */
/* #main .variety #vrImage:hover img {
  width: 100%;
  height: 90%;
  transition: transform 0.9s ease;
  transform: translate(-50%, -50%);
  transform: scale(0.90);
}

#main .variety #vrImage img{
  transition: transform 0.7s ease-in-out;
} */



/*--------------------------------------------------------------
	# 배너
--------------------------------------------------------------*/
#main .banner1{
  background-color: #0065bd;
  width:100%;
  height: auto;
  margin:10rem 0;
}
#main .banner1 .pc{
  display: block;
}
#main .banner1 .pad{
  display: none;
}
#main .banner1 .mobile{
  display: none;
}
@media (max-width:699px){
  #main .banner1{
    margin:7rem 0;
  }
  #main .banner1 .pc{
    display: none;
  }
  #main .banner1 .pad{
    display: none;
  }
  #main .banner1 .mobile{
    display: block;
  }
}
@media (min-width:700px) and (max-width:1800px){
  #main .banner1 .pc{
    display: none;
  }
  #main .banner1 .pad{
    display: block;
  }
  #main .banner1 .mobile{
    display: none;
  }
}




/*--------------------------------------------------------------
# 베스트 아이템
--------------------------------------------------------------*/
#main .bestitem{
  margin-bottom:20rem
}
#main .bestitem .card{
  border:none;
  overflow: hidden;
  border-radius: 15px;
}
#main .bestitem .card img{
  width:auto;
  height:35rem;
  object-fit: cover;
  transition: all .7s ease-in-out;
}
#main .bestitem .card:hover img{
  transform: scale(1.1);
}
#main .bestitem .card:hover .mbus-icon{
  transform: scale(1);
}
@media (max-width:991px){
  #main .bestitem .card img{
    height:25rem;
  }
}

#main .bestitem .card-body{
  position:absolute;
  bottom:0%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,0.5), rgba(0,0,0,0) );
  width:100%;
}
#main .bestitem .card-title{
  color:#fff;
  height:9rem;
  font-weight: 600;
  font-size:1.3rem;
}
main .bestitem .card-title .b-height{
  height:3rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 뱃지 */
.badge {
  /* width: 120px; */
  text-align: center;
}

.badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
.badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

.badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
.badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}

/*************************************************
  엔데트상품 횡스크롤 
 *************************************************/
.scrolling-wrapper {
  overflow-x: hidden;
  /*overflow-x: scroll;*/  
  overflow-y: hidden;
  width: calc(100vw);
  position: relative;
}

.scrolling-content {
  display: block;
  white-space: nowrap;
  margin: 0;
  padding: 0 15px;
  overflow-x: hidden;
  /*overflow-x: scroll;*/
}

.col-12.col-sm-6.col-lg-4.px-3.mb-5 {
  display: inline-block;
  float: none;
  vertical-align: top;
  width: calc(100vw / 5 - 26px);
  box-sizing: border-box;
}


@media(max-width: 768px) {
  .col-12.col-sm-6.col-lg-4.px-3.mb-5 {
    display: inline-block;
    float: none;
    vertical-align: top;
    width: calc(100vw / 1 - 26px);
    box-sizing: border-box;
    animation: scroll 20s linear infinite;
  }
}

@media(max-width: 768px) {
  .scrolling-content {
    display: block;
    white-space: nowrap;
    margin: 0;
    padding: 0 15px;
    overflow-x: scroll;
  }
}



/*
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
*/

.scroll-btn {
  position: absolute;
  top: 200px;  /*버튼수직위치*/
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  font-size: 40px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  opacity: 0.7;
  transition: opacity 0.5s;
}

.scroll-btn:hover {
  opacity: 1;
}

.scroll-btn.left {
  left: 30px;  /* 버튼횡위치*/
}

.scroll-btn.right {
  right: 30px;  /* 버튼횡위치*/
}

@media (max-width:991px){
  .scroll-btn.left {
    left: 0;
  }
  .scroll-btn.right {
    right: 0; 
  }
}



/* 멤버십 아이콘 */
#main .mbus-icon{
  width:2.5rem !important;
  height: auto !important;
}
</style>
