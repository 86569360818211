<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>공지/이벤트 등록</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">분류/제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_ctCd" v-model="notice.ctCd">
              <option value="NT">공지</option>
              <option value="EV">이벤트</option>
              <option value="TR">여행관련</option>
              <option value="MO">항공선박</option>
              <option value="PR">홍보/안내</option>
            </select>          
            <input type="text" class="form-control w-75" ref="txt_nTitle" v-model="notice.nTitle">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지내용<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" v-model="notice.nContent"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <!-- <input type="text" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart"> -->
            <date-picker class="form-control" v-model="notice.nFrom" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <!-- <input type="text" class="form-control" v-model="productInfo.salesEnd"> -->
            <date-picker class="form-control" v-model="notice.nTo" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>

      <!-- 이미지 업로드 버튼 -->
      <div class="mb-3">
        <center>
          <button
            type="button"
            class="btn btn-md btn-success"
            style="width: 270px;"
            :disabled="activeUpload === 'video'"
            @click="toggleUpload('image')"
          >
            이미지첨부&nbsp;&nbsp;(최대 2MB jpg)
          </button>
        </center>
      </div>

      <!-- 이미지 업로드 필드 -->
      <div class="mb-3 row" v-if="activeUpload === 'image'">
        <label class="col-md-3 col-form-label">이미지<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="file" class="form-control" id="fileSelectorImage" accept="image/jpeg">
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <div>File 확장자: jpg</div>
          </div>
        </div>
      </div>

      <!-- 동영상 업로드 버튼 -->
      <div class="mb-3">
        <center>
          <button
            type="button"
            class="btn btn-md btn-info"
            style="width: 270px;"
            :disabled="activeUpload === 'image'"
            @click="toggleUpload('video')"
          >
            동영상첨부&nbsp;&nbsp;(최대 20MB mp4)
          </button>
        </center>
      </div>

      <!-- 동영상 업로드 필드 -->
      <div class="mb-3 row" v-if="activeUpload === 'video'">
        <label class="col-md-3 col-form-label">동영상<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="file" class="form-control" id="fileSelectorVideo" accept="video/mp4">
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <div>File 확장자: mp4</div>
          </div>
        </div>
      </div>


      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="noticeInsert">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">공지리스트</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
//import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg'; // FFmpeg 가져오기

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      notice: {
        nTitle: "",
        nContent: "",
        nFrom: "",
        nTo: "",
        insDate: "",
        insId: "",
        nUseFlag: "",             //공지상태 default: 비공지
        ctCd: "",                 //공지(NT), 이벤트(EV)
        noticeCode: "",           //공지코드
        addImageFlag: "N",        // 이미지첨부 호출여부
        addVideoFlag: "N",        // 동영상 첨부여부
      },
      //fileList: "" //선택된 이미지파일

      activeUpload: null,         // 'image' 또는 'video' 중 하나만 활성화
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    console.log("USER:",this.user);
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    goToList() {
      this.$router.push({path: '/noticeList'});
    },

    toggleUpload(type) {
      this.activeUpload = this.activeUpload === type ? null : type;

      //이미지와 동영상 택1
      if(type === "image")
      {
        this.notice.addImageFlag = (this.notice.addImageFlag === "N") ? "Y" : "N"
        this.notice.addVideoFlag = "N"
      }
      else if(type === "video")
      {
        this.notice.addVideoFlag = (this.notice.addVideoFlag === "N") ? "Y" : "N"
        this.notice.addImageFlag = "N"
      }
      else
      {
        this.notice.addImageFlag = "N"
        this.notice.addVideoFlag = "N"
      }
    },

    async noticeInsert() 
    {
      //오늘날짜추출
      const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      const time = new Date().toTimeString().split(" ")[0];
      let today = date + ' ' + time;
      let tStamp = "";
      tStamp = new Date().getTime();

      let noticeCode =  this.notice.ctCd + "-" + tStamp;
      //console.log("today:", date + ' ' + time);
      console.log("noticeCode:", noticeCode);

      if(!this.notice.nTitle) {
        this.$refs.txt_nTitle.focus();
        return this.$swal.fire("필수누락: 공지제목");
      }
      if(!this.notice.nContent) {
        this.$refs.txt_nContent.focus();
        return this.$swal.fire("필수누락: 공지내용");
      }
      if(!this.notice.nFrom) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("필수누락: 개시일");
      }
      if(!this.notice.nTo) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("필수누락: 종료일");
      }
      if(this.notice.nStart > this.notice.nEnd) {
        return this.$swal.fire("입력오류: 개시일&종료일");
      }
      // if(!this.productInfo.catCode) {
      //   this.$refs.sel_pdtCategoryt.focus();
      //   return this.$swal.fire("필수누락: 상품카테고리");
      // }
      this.notice.noticeCode = noticeCode;
      this.notice.nFrom = moment(this.notice.nFrom).format("YYYY-MM-DD");
      this.notice.nTo = moment(this.notice.nTo).format("YYYY-MM-DD");
      this.notice.insDate = today;
      this.notice.insId = this.user.handlerId;
      this.notice.nUseFlag = "N";
      //console.log("this.notice:", this.notice);

      // 이미지 업로드 처리
      // if (this.activeUpload === "image") {
      //   const imgFile = document.getElementById('fileSelectorImage').files;
        
      //   if (imgFile) 
      //   {
      //     await this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode, "image");
      //   }
      // }

      // 동영상 업로드 처리
      if (this.activeUpload === "video") {
        let videoFile = document.getElementById('fileSelectorVideo').files;
        
        if (videoFile && videoFile[0].size > 20 * 1024 * 1024 ) 
        {
          this.$swal.fire("","동영상 업로드는 20메가 이하로 제한됩니다", "warning")
          return false;
        }
      }

      let resIns_notice = await this.$api("/api/noticeInsert", {param: [this.notice]});
      if(Object.keys(resIns_notice)[0] === 'error') 
      {
        this.$swal.fire('','저장실패', 'error')
      }
      else
      {
        // 이미지 업로드 처리
        if (this.activeUpload === "image") {
          const imgFile = document.getElementById('fileSelectorImage').files;
          if (imgFile.length > 0) 
          {
            await this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode, "image");
          }
        }

        // 동영상 업로드 처리
        if (this.activeUpload === "video") {
          const videoFile = document.getElementById('fileSelectorVideo').files;
          if (videoFile.length > 0) 
          {
            await this.uploadFile(videoFile, this.notice.ctCd, this.notice.noticeCode, "video");
          }
        }

        this.$swal.fire('','저장완료', 'success');
        this.$router.push({path: '/noticeList'});

        // else
        // {
        //   this.$swal.fire('','저장완료', 'success');
        //   this.$router.push({path: '/noticeList'});
        // }
      }
    },

    async compressFile(file, fileType) 
    {
      // 이미지 크기한도: 2메가
      if (fileType === "image" && file.size > 2 * 1024 * 1024) 
      //if (fileType === "image" && file.size > 2000000)
      {
        console.log("이미지 압축 중...");
        return await this.compressImage(file);
      } 
      // 동영상 크기한도: 20메가
      else if (fileType === "video" && file.size > 20 * 1024 * 1024) 
      {
        // console.log("동영상 압축 중...");
        // return await this.compressVideo(file);           // 압축api의 초기화 불능으로 적용홀딩(2024-11-28)
        this.$swal.fire("","동영상 업로드는 20메가 미만으로 제한됩니다", "warning")
        return false;
      }
      return file; // 크기 초과하지 않으면 원본 파일 반환
    },

    async compressImage(file) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const maxDimension = 1920;
            let width = img.width;
            let height = img.height;

            if (width > maxDimension || height > maxDimension) {
              if (width > height) {
                height = Math.round((height * maxDimension) / width);
                width = maxDimension;
              } else {
                width = Math.round((width * maxDimension) / height);
                height = maxDimension;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                if (blob.size > 2 * 1024 * 1024) {
                  reject(new Error("이미지를 2MB 이하로 압축할 수 없습니다."));
                } else {
                  resolve(new File([blob], file.name, { type: "image/jpeg" }));
                }
              },
              "image/jpeg",
              0.7 // 품질 설정
            );
          };
          img.src = event.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    /** 이미지 or 동영상 사본등록 */
    async uploadFile(file, id, passNo, fileType) {
      // 파일사이즈 크기보정 펑션호출(파일크기한도: 이미지 2메가/동영상 20메가)
      // 동영상 크기한도: 20메가체크 => 압축 api의 초기화 불능으로 압축기능 적용은 연기(2024-11-28)
      let files = ""

      if (fileType === "video" && file[0].size > 20 * 1024 * 1024) 
      {
        this.$swal.fire("","동영상 업로드는 20메가 미만으로 제한됩니다", "warning")
        return false;
      }
      else
      {
        files = await this.compressFile(file[0], fileType)
      }
      
      let fileLength = 0
      let fileType_tmp = "";
      let licenseFile = "";
      //let uploadedFile = "";
      let data = null;

      if (files) {
        fileLength    = files.name.length;
        fileType_tmp  = files.name.lastIndexOf(".");
        let extension = files.name.substring(fileType_tmp + 1, fileLength).toLowerCase();
        //this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();

        // 확장자 검증
        if (fileType === "image" && (extension !== "jpg" && extension !== "jpeg")) 
        {
          return this.$swal("이미지 파일은 jpg, jpeg, png 형식만 지원합니다.");
        }
        else if (fileType === "video" && extension !== "mp4")
        {
          return this.$swal("동영상 파일은 mp4 형식만 지원합니다.");
        }


        //licenseFile = passNo+"."+this.fileType;
        licenseFile = `${passNo}.${extension}`;
        //uploadedFile = `${passNo}.${extension}`;
        data = await this.$base64(files);
      }

      console.log("licenseFile:", licenseFile)

      // 업로드 API 경로 설정
      // const endpoint = fileType === "image"
      //   ? `/upload/notice/${id}/${licenseFile}`
      //   : `/upload/video/${id}/${licenseFile}`;
      //const endpoint = `/upload/notice/${id}/${licenseFile}`

      const { error } = await this.$api(`/upload/notice/${id}/${licenseFile}`, { data });

      console.log(`/upload/notice/${id}/${licenseFile}`);
      //const { error } = await this.$api(endpoint, { data });
      
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      else{
        this.$swal("업로드 완료");
        this.$router.push({path: '/noticeList'});
      }
    },
        
    // async compressVideo(file) {
    //   //const ffmpeg = createFFmpeg({ log: true });
    //   const ffmpeg = createFFmpeg({
    //     log: true,
    //     corePath: "/path-to-your-core/ffmpeg-core.js",
    //   });

    //   if (!ffmpeg.isLoaded()) 
    //   {
    //     console.log("FFmpeg 로드 중...");
    //     try
    //     {
    //       await ffmpeg.load();
    //       console.log("FFmpeg 로드 완료");
    //     } 
    //     catch (error) 
    //     {
    //       console.error("FFmpeg 로드 실패:", error);
    //       throw new Error("FFmpeg 로드 실패");
    //     }
    //   }

    //   const inputName = file.name;
    //   const outputName = `compressed_${file.name}`;

    //   try 
    //   {
    //     console.log("FFmpeg 파일 쓰기...");
    //     ffmpeg.FS("writeFile", inputName, await fetchFile(file));

    //     console.log("FFmpeg 실행 중...");
    //     await ffmpeg.run("-i", inputName, "-b:v", "1M", "-s", "1280x720", outputName);

    //     console.log("FFmpeg 결과 읽기...");
    //     const data = ffmpeg.FS("readFile", outputName);

    //     const compressedFile = new Blob([data.buffer], { type: "video/mp4" });

    //     ffmpeg.FS("unlink", inputName);
    //     ffmpeg.FS("unlink", outputName);
    //     ffmpeg.exit();

    //     if (compressedFile.size > 10 * 1024 * 1024) {
    //       throw new Error("동영상을 10MB 이하로 압축할 수 없습니다.");
    //     }

    //     console.log("동영상 압축 완료:", compressedFile);
    //     return new File([compressedFile], file.name, { type: "video/mp4" });
    //   } 
    //   catch (error) 
    //   {
    //     console.error("동영상 압축 실패:", error);
    //     throw new Error("동영상 압축 실패");
    //   }
    // },

    // async uploadFile(files, id, passNo) {
    //   let fileLength = 0
    //   let fileType_tmp = "";
    //   let licenseFile = "";
    //   let data = null;

    //   if (files) {
    //     fileLength = files[0].length;
    //     fileType_tmp = files[0].name.lastIndexOf(".");
    //     this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
    //     licenseFile = passNo+"."+this.fileType;
    //     data = await this.$base64(files[0]);
    //   }
    //   const { error } = await this.$api(`/upload/notice/${id}/${licenseFile}`, { data });
    //   if (error) {
    //     return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
    //   }
    //   else{
    //     this.$swal("업로드 완료");
    //     this.$router.push({path: '/noticeList'});
    //   }
    // },
  }
}
</script>