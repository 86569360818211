<template>
  <div ref="tableContainer" id="repFrame">

    <!-- <div class="text-end print-hide">
      <button type="button" class="btn btn-outline-success btn-sm m-1" style="font-size: small; padding-right: 10px;" @click="printScreen()">
        Print <i class="bi bi-printer"></i></button>
    </div> -->

    <div class="row col-12" style="padding-left:50%;">
      <table class="table table-bordered border-secondary w-100 h-auto text-center fs-6">
        <tr>
          <td rowspan="2" style="width:10%; height: 90px; writing-mode: vertical-lr;"><center>결&nbsp;&nbsp;&nbsp;재</center></td>
          <td style="width:30%; height: 20px;">담&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;당</td>
          <td style="width:30%; height: 20px;">팀&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;장</td>
          <td style="width:30%; height: 20px;">대&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;표</td>
        </tr>
        <tr>
          <td style="height: 70px;">
            <button type="button" class="btn btn-outline-secondary btn-md" v-if="user.handlerGrade <= 9" @click="makeExpReport('lv1')">발의</button>
          </td>
          <td style="height: 70px;">
            <button type="button" class="btn btn-outline-primary btn-md" v-if="user.handlerGrade <= 3" @click="makeExpReport('lv5')">결재</button>
          </td>
          <td style="height: 70px;">
            <button type="button" class="btn btn-outline-danger btn-md" v-if="user.handlerGrade <= 2" @click="makeExpReport('lv9')">결재</button>
          </td>
        </tr>
      </table>
    </div>

    <table class="table table-bordered border-secondary w-100 h-auto text-center">
      <tr>
        <td colspan="7">
          <b class="fs-3">지&nbsp;출&nbsp;결&nbsp;의&nbsp;서</b>
          <p style="text-align: end; padding-right: 40px;">
            <!-- <b class="fs-5">엔타비</b> -->
          </p>
          
          <table class="w-100 h-auto mt-0 mb-3">
            <tr>
              <td style="border: 0;width: 70%; padding-left: 10px;">
                <div class="mt-0 mb-0 text-start fs-5" :key="i" v-for="(ex,i) in arr_expenseByCurrency">
                  일금 <b>{{ getNumberToKorean(ex.tot_dPrice) }}</b> 
                  <span class="fs-6 text-danger">( {{ ex.curName }}) </span>
                </div>
              </td>
              <td style="border: 0;width:30%; padding-right: 10px;">
                <div class="mt-0 mb-0 text-end fs-5" :key="i" v-for="(ex,i) in arr_expenseByCurrency">
                  <span class="text-danger">{{ ex.curSimbol }}</span> <b>{{ getCurrencyFormat(ex.tot_dPrice) }}</b>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:15%;">담당발의</td>
        <td style="vertical-align: middle; width:10%;"> {{ today }} </td>
        <td style="vertical-align: middle; width:10%;"> {{getTimeFormat(today) }}</td>
        <td style="vertical-align: middle; width:20%;">{{ checkerInfo.handlerNameKor }}</td>
        <td style="background-color:lightgray; vertical-align: middle; width:10%;">거래처</td>
        <td style="vertical-align: middle; width:25%;" class="text-primary fw-bold">
          {{ partnerName }} <span style="font-size: small; color:grey;">({{ ptCode }})</span>
        </td>
      </tr>
      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:15%;">팀장결재</td>
        <td style="vertical-align: middle; width:10%;"></td>
        <td style="vertical-align: middle; width:5%;"></td>
        <td style="vertical-align: middle; width:20%;"></td>
        <td style="background-color:lightgray; vertical-align: middle; width:10%;" colspan="2">계정과목</td>
      </tr>
      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:10%;">대표결재</td>
        <td style="vertical-align: middle; width:15%;"></td>
        <td style="vertical-align: middle; width:15%;"></td>
        <td style="vertical-align: middle; width:15%;"></td>

        <td style="vertical-align: middle; width:15%;" colspan="2" rowspan="2">
          <input type="text" class="form-control from-control-sm" style="font-size: small;" placeholder="필요시 계정과목을 입력하세요" v-model="accountSubject">
        </td>
      </tr>


      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:10%;">지출처리</td>
        <td style="vertical-align:center; width:15%;"></td>
        <td style="vertical-align: middle; width:15%;"></td>
        <td style="vertical-align: middle; width:15%;"></td>
      </tr>

      <tr>
        <td colspan="7"></td>
      </tr>

      <tr  class="border-secondary fs-5">
        <td class="text-center" id="subTitle" colspan="7"><b>내&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;역</b></td>
      </tr>
      <tr class="bgColor fs-6">
        <th colspan="1">적요</th>
        <th colspan="1">금액</th>
        <th colspan="1">환율</th>
        <th colspan="2">비고</th>
        <th colspan="1">증빙자료</th>
      </tr>
      <tr  class="border-secondary fs-6" :key="j" v-for="(e,j) in this.expenseInfo">
        <!-- 적요:항목명 -->
        <td colspan="1">
          {{ e.exName }}
        </td>

        <!-- 금액 -->
        <td colspan="1" style="text-align: end; padding-right: 10px;">
          <span class="text-danger">{{ e.curSimbol }}</span>{{ this.getCurrencyFormat(e.dPrice) }}
          <!-- <span class="text-secondary" style="font-size:small;">(₩{{ this.getCurrencyFormat(e.dPrice * e.dExRate) }})</span> -->
        </td>

        <!-- 환율 -->
        <td colspan="1" style="text-align: end; padding-right: 10px; color:darkgray;">
          {{ e.dExRate }}
        </td>

        <!-- 비고: 비고사항 -->
        <td colspan="2" style="text-align: start; padding-left: 5px; font-size: smaller;">
          {{ e.dDetailRemarks }}
        </td>

        <!-- 증빙자료 -->
        <td style="font-size: smaller;">
          <div :key="j" v-for="(r,j) in e.recNames" style="text-align: start;padding-top: 1px; padding-bottom: 1px;">
            <a href="javascript:void(0)" @click="downloadUrl(e.dealCode+'-'+e.dSubNo, r)"><i class="bi bi-paperclip text-secondary"></i>{{ r }}</a>
          </div>
        </td>
      </tr>

      <!-- 거래처계좌정보 -->
      <tr>
        <td colspan="6" class="fs-6">
          <div class="text-center">
            <!-- {{ pBankName }} : {{ pBankAccount }} ({{ pAccHolder }}) -->
            <div class="input-group input-group-sm" style="width:60%;margin:0 auto;">
              <select class="form-select form-select-sm text-center" v-model="pBankCode" style="font-size: smaller;" ref="finCd">
                <option :value="f.fCodeNo" :key="i" v-for="(f,i) in this.finBankingInfo">{{ f.fName }}</option>
              </select>              
              <input type="text" class="form-control form-sm text-center" placeholder="계좌번호" v-model="pBankAccount">
              <input type="text" class="form-control form-sm text-center" placeholder="예금주" v-model="pAccHolder">
            </div>              
          </div>
        </td>
      </tr>

      <tr class="fs-6">
        <td colspan="2">합&nbsp;&nbsp;&nbsp;계</td>
        <td colspan="4" class="fs-5 text-end text-danger fw-bold" style="padding-right: 20px;">₩{{ getCurrencyFormat(sumOfExpPrice) }}</td>
      </tr>

      <tr class="fs-6">
        <td colspan="6">
          <div>
            위 금액을 영수(청구)합니다.
          </div>
          <div>
            {{ today }}
          </div>
        </td>
      </tr>
    </table>
  </div>

</template>

<script>
//import * as XLSX from "xlsx"
import moment from 'moment';
import {genCode} from '../assets/js/genCode'

export default {
  data() {
    return {
      expenseInfo: [],            //지출품의작성용 출금정보
      sumOfExpPrice:[],           //통화별지출총액
      arr_expenseByCurrency:[],   //통화별지출정보
      checkerInfo: [],            //처리자인적정보

      reqCode: "",                //지출품의코드(RE-)
      dealCode: "",               //딜코드
      pBankCode: "",              //거래처은행코드
      pBankName: "",              //거래처은행명
      pBankAccount: "",           //거래처계좌번호
      pAccHolder: "",             //예금주
      partnerName: "",            //거래처명
      ptCode: "",                 //파트너코드
      exRate: 0.00,               //환율
      accountSubject: "",         //계정과목

      check1Date: "",             //발의일
      checker1Id: "",             //발의자
      check5Date: "",             //팀장처리일
      checker5Id: "",             //탐장
      check9Date: "",             //대표처리일
      checker9Id: "",             //대표

      payDate: "",                //지출일
      payerId: "",                //지출자

      finBankingInfo: [],         //은행정보
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.dealCode = this.$route.query.dCode;

    const today_tmp = moment();
    //let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today_tmp.format("YYYY-MM-DD HH:mm:ss")

    this.getHandlerInfo()
  },
  // watch: {
  //   sumOfConvMoney: "calVatPrice",
  //   expTotal: "calVatPrice"
  // },
  mounted() {
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
    document.getElementById("top").style.display = "none";

    //지출품의서 작성용 출금정보 확보 
    const storedData = localStorage.getItem('expenseInfo');
    if (storedData) {
      this.expenseInfo = JSON.parse(storedData);

      this.getExpenseInfo()
    }
    console.log("expenseInfo:", this.expenseInfo)
    localStorage.removeItem("expenseInfo")
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getTimeFormat(date)
    {
      return this.$timeFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },
    //숫자를 한글로 변환
    getNumberToKorean(value){
      return this.$numberToKorean(value)
    },

    async getHandlerInfo(){
      //처리자인적정보
      let checkerInfo = await this.$api("/api/handlerInfoById", {param: [this.user.handlerId]})
      if(checkerInfo.length > 0)
      {
        this.checkerInfo = checkerInfo[0]
      }
      console.log("checkerInfo:", this.checkerInfo)
    },

    //은행정보
    async getFinBankingInfo(){
      let finBankingInfo = await this.$api("/api/finBankingInfo", {param: [
        'Inst', 'Bank', 'Y'
      ]})

      if(finBankingInfo.length > 0)
      {
        this.finBankingInfo = finBankingInfo
      }
    },

    //지출정보 처리
    async getExpenseInfo(){
      this.getFinBankingInfo()

      //거래처정보
      this.partnerName = this.expenseInfo[0].paName
      this.pBankCode = this.expenseInfo[0].bankCode
      this.pBankName = this.expenseInfo[0].bankName
      this.pBankAccount = this.expenseInfo[0].bankAccount
      this.pAccHolder = this.expenseInfo[0].accHolder
      this.exRate = this.expenseInfo[0].dExRate
      this.dealCode = this.expenseInfo[0].dealCode
      this.ptCode = this.expenseInfo[0].dPartnerCd

      //품의액 합산계산
      let i = 0
      let sumOfExpPrice = 0            //통화별 품의액합산
      let expPrice = 0                 //통화별 품의액
      while(i < this.expenseInfo.length)
      {
        if(this.expenseInfo[i].dCurCd === "KRW")
        {
          expPrice = this.expenseInfo[i].dPrice
        }
        else
        {
          expPrice = this.expenseInfo[i].dPrice * this.expenseInfo[i].dExRate
        }
        sumOfExpPrice += expPrice
        console.log("sumOfExpPrice:", sumOfExpPrice)
        i++
      }
      this.sumOfExpPrice = sumOfExpPrice


      //통화별 지출액분류
      let arr_expenseByCurrency = this.expenseInfo.reduce((acc, {dCurCd, dPrice, curName, curSimbol, dExRate}) => {
        if(acc[dCurCd])
        {
          acc[dCurCd].tot_dPrice += dPrice
        }
        else
        {
          acc[dCurCd] = {
            tot_dPrice: dPrice, curName: curName, curSimbol: curSimbol, dExRate: dExRate
          }
        }
        return acc
      }, {})
      this.arr_expenseByCurrency = arr_expenseByCurrency
      console.log("arr_expenseByCurrency:", arr_expenseByCurrency)
    },
    //증빙파일다운로드
    downloadUrl(folder, fileName) {
      let encodedFolder = encodeURIComponent(folder);
      let encodedFilename = encodeURIComponent(fileName);

      //back end와 링크주소 일치해야 함
      //let url = `http://localhost:5000/filedownload/${encodedFolder}/${encodedFilename}`;
      let url = `https://ntabi.co.kr/filedownload/${encodedFolder}/${encodedFilename}`;
      //console.log("url:", url)

      window.location.href = url; 
    },

    async makeExpReport(grade){
      //처리자 레벨
      let checkerLv = grade

      let reqCode = ""
      //지출품의서 생성 or 업데이트
      let checkerId = this.user.handlerId
      let payerId = ""
      let updId = ""
      let delId = ""
      let ttlDate = ""


      if (grade === "lv1") {
        const { value } = await this.$swal.fire({
          input: "textarea",
          inputLabel: "결제시한",
          inputPlaceholder: "결제시한을 입력하세요(YYYY-MM-DD). 시한이 없는 경우, 미입력",
          inputAttributes: {
            "aria-label": "결제시한"
          },
          showCancelButton: true
        });

        ttlDate = value
        if (ttlDate) {
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD 형식 정규식

          // 정규식 검사
          if (!dateRegex.test(ttlDate)) {
            this.$swal.fire("", "입력시한의 형식을 체크해주세요(YYYY-MM-DD)", "warning");
            return false;
          }
          // this.$swal.fire(ttlDate);
        }

        //지출품의 코드생성
        let catCode = "RE-"
        reqCode = await this.genCode(catCode)
        this.reqCode = reqCode
      }
      else if(grade === "lv5")
      {
        updId = this.user.handlerId
      }
      else if(grade === "lv9")
      {
        updId = this.user.handlerId
      }
      else
      {
        this.$swal.fire("","처리권한이 불명확합니다. 재시도해주세요","warning")
        return false
      }

      let rUseFlag = "Y"

      //ttlDate 미입력 대응
      //ttlDate = (this.expenseInfo.ttlDate == null || this.expenseInfo.ttlDate == undefined) ? null : this.getDateFormat(this.expenseInfo.ttlDate)
      ttlDate = (ttlDate == null || ttlDate == undefined || ttlDate == "") ? null : this.getDateFormat(ttlDate)

      let res_createRequisition_m = await this.$api("/api/proc_createRequisition_m", {param: 
        [
          this.reqCode, this.dealCode, rUseFlag, checkerLv, checkerId, payerId, this.ptCode, this.pBankCode, this.pBankAccount, this.pAccHolder,
          this.accountSubject, this.user.handlerId, updId, delId, grade, ttlDate
      ]});

      if(res_createRequisition_m.affectedRows < 0)
      {
        this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>지출결의서 처리 중 문제가 발생했습니다.('+ res_createRequisition_m.error.sqlMessage +')', 'warning')
        return false;
      }
      else
      {
        let promises = []
        let i = 0
        while(i < this.expenseInfo.length)
        {
          let res_createRequisition_d = await this.$api("/api/proc_createRequisition_d", {param: 
            [
              this.reqCode, i, this.expenseInfo[i].exName, this.expenseInfo[i].dPrice, this.expenseInfo[i].dCurCd, this.expenseInfo[i].dExRate, this.expenseInfo[i].dDetailRemarks,
              this.dealCode, checkerLv, this.expenseInfo[i].dSubNo
            ]
          });

          let k = 0
          while(k < this.expenseInfo[i].recSubNo.length)
          {
            let rUseFlag = "Y"
            let dUseFlag = "Y"

            let res_createRequisition_receipt = await this.$api("/api/proc_createRequisition_receipt", {param: 
              [
                // this.reqCode, k, this.expenseInfo[i].recCode, this.dealCode, this.expenseInfo[i].dSubNo, this.expenseInfo[i].recNames[k], dUseFlag,
                // rUseFlag

                this.reqCode, i, this.expenseInfo[i].recCode, this.dealCode, this.expenseInfo[i].dSubNo, this.expenseInfo[i].recNames[k], dUseFlag,
                rUseFlag                
              ]
            });
            //console.log("res_createRequisition_receipt:", res_createRequisition_receipt.toString())

            if(res_createRequisition_receipt.affectedRows < 0)
            {
              this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>증빙자료 처리 중 문제가 발생했습니다.('+ res_createRequisition_receipt.error.sqlMessage +')<br>관리자에게 문의하여 주십시오.', 'warning')
            return false;
            }
            promises.push(res_createRequisition_receipt)            
            k++
          }
          // let receiptPromises = this.expenseInfo[i].recSubNo.map((_, k) => {   //_은 무시하고 인덱스인 k만 사용하여 map함수처리
          //   return this.$api("/api/proc_createRequisition_receipt", {
          //     param: [
          //       this.reqCode, k, this.expenseInfo.recCode, this.dealCode, this.expenseInfo[i].dSubNo, this.expenseInfo[i].recNames[k], "Y", "Y"
          //     ]
          //   }).then(res_createRequisition_receipt => {
          //     if (res_createRequisition_receipt.affectedRows < 0) {
          //       throw new Error(`증빙자료 처리 중 문제가 발생했습니다. (${res_createRequisition_receipt.error.sqlMessage})`);
          //     }
          //   });
          // });

          
          if(res_createRequisition_d.affectedRows < 0)
          {
            this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>적요정보 처리 중 문제가 발생했습니다.('+ res_createRequisition_d.error.sqlMessage + +')<br>관리자에게 문의하여 주십시오.', 'warning')
            return false;
          }

          promises.push(res_createRequisition_d)
          //promises.push(res_createRequisition_receipt)
          i++
        }
        Promise.all(promises).then(() => {
          this.$swal.fire("", "지출품의가 진행됩니다", "info"). then(() => {
          this.$router.push({path: '/expenseReportView', query: {rCode: this.reqCode}});
          })
        })
      }
    },

    //지품코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","예약번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },


    // 지정영역 화면인쇄
    printScreen()
    {
      //화면전체
      window.print()
    },

    //화면인쇄(정산서 영역)
    printScreen_excel(){
      //인쇄화면에서 버튼들 삭제
      // Get the table container
      const tableContainer = this.$refs.tableContainer;

      // Clone the table container to avoid altering the original content
      const clonedTableContainer = tableContainer.cloneNode(true);

      // Remove the buttons from the cloned table container
      const buttons = clonedTableContainer.querySelectorAll('button');
      buttons.forEach(button => button.remove());

      // Get the HTML content of the cloned table container without buttons
      const printContent = clonedTableContainer.innerHTML;

      // Define styles for the table border and background color
      const printStyles = `
          <style>
            table {
              width: 100%;
              border: 1px solid black;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;-
            }
            td[style*="background-color:lightgray"] {
              background-color: lightgray !important;
            }
          </style>`;

      // Print the content with styles
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write('<html><head><title>Print</title>' + printStyles + '</head><body>' + printContent + '</body></html>');
      printWindow.document.close();
      printWindow.print();
    
      //지정영역 인쇄
      // const printContent = this.$refs.tableContainer.innerHTML;
      // const printWindow = window.open('', '_blank');
      // printWindow.document.open();
      // printWindow.document.write('<html><head><title>Print</title></head><body>' + printContent + '</body></html>');
      // printWindow.document.close();
      // printWindow.print();

      //화면전체
      //window.print()
    },

    goBack(){
      //history.back(-1)
      //this.$router.go(-1);
      this.$router.push({path:'/businessInfo', query: {bFlag: 'B'}})
    },

    // //엑셀다운로드
    // exportToExcel() {
    //   // Get the table element
    //   let tableContainer = this.$refs.tableContainer;
    //   let tables = tableContainer.querySelectorAll('table');

    //   // Transfer many tables to one table
    //   const tempTable = document.createElement('table');
    //   tables.forEach((table) => {
    //     const rows = table.querySelectorAll('tr');
    //     rows.forEach((row) => {
    //       tempTable.appendChild(row.cloneNode(true));
    //     });
    //   });

    //   // Convert tempTable to workbook
    //   const workbook = XLSX.utils.table_to_book(tempTable);

    //   // Get the first worksheet (assuming only one)
    //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    //   // Function to convert RGB color to Hex
    //   function rgbToHex(rgb) {
    //     const rgbValues = rgb.match(/\d+/g);
    //     const hexColor = rgbValues
    //       ? '#' + rgbValues.map((x) => parseInt(x).toString(16).padStart(2, '0')).join('')
    //       : rgb;
    //     return hexColor.replace('#', ''); // Hex format without #
    //   }

    //   // Iterate through the rows of the tempTable
    //   tempTable.querySelectorAll('tr').forEach((row, rowIndex) => {
    //     row.querySelectorAll('td').forEach((cell, colIndex) => {
    //       // Get the background color of the HTML cell
    //       const bgColor = window.getComputedStyle(cell).backgroundColor;

    //       // Convert the color to a format that can be used in Excel
    //       const excelColor = rgbToHex(bgColor);

    //       // Get the corresponding Excel cell reference
    //       const cellRef = XLSX.utils.encode_cell({c: colIndex, r: rowIndex});
    //       const excelCell = worksheet[cellRef];

    //       if (excelCell) { // Check if Excel cell is defined
    //         // Set the background color in Excel
    //         excelCell.s = {
    //           ...excelCell.s, // Keep existing styles
    //           fill: {
    //             fgColor: { rgb: excelColor }
    //           },
    //           font: {
    //             sz: 14 // Font size in points
    //           },
    //           border: {
    //             top: {style: "thin"},
    //             bottom: {style: "thin"},
    //             left: {style: "thin"},
    //             right: {style: "thin"},
    //           }
    //         };
    //       }
    //     });
    //   });

    //   // Apply styles to header (assuming header is in the first row)
    //   const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    //   for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    //     const cellRef = XLSX.utils.encode_cell({c: col, r: headerRange.s.r});
    //     const cell = worksheet[cellRef];

    //     if (cell) {
    //       cell.s = {
    //         font: {
    //           bold: true,
    //           sz: 12, // Font size in points
    //         },
    //         border: {
    //           top: {style: "thin"},
    //           bottom: {style: "thin"},
    //           left: {style: "thin"},
    //           right: {style: "thin"},
    //         }
    //       };
    //     }
    //   }


    //   // Create Excel file
    //   const excelFile = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    //   // Create a Blob with the Excel file
    //   const blob = new Blob([this.s2ab(excelFile)], { type: 'application/octet-stream' });

    //   // Create a download link
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   //a.download = '정산서.xlsx'; // Filename
    //   a.download = this.dealCode +'.xlsx'; // Filename
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // },
    // s2ab(s) {
    //   const buf = new ArrayBuffer(s.length);
    //   const view = new Uint8Array(buf);
    //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    //   return buf;
    // },    
  }
}
</script>

<style>
table{
  border-collapse: collapse;
}

th, td{
  padding: 5px;
}

.table_sup td {
  padding: 0
}

/*인쇄화면에서 엑셀과 프린트 버튼은 보이지 않도록*/
@media print {
  .print-hide {
    display:none;
  }
  .bgColor {
    background-color: lightgray;
  }
}
</style>