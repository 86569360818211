export async function getAvailPoint(api, email) {
  //유저당 유형별 포인트합계 현황
  let totalPoint = await api("/api/userTotalPoint", { param: [email] });
  //console.log("totalPoint:", totalPoint)

  //기사용 포인트
  let unavailPoint = await api("/api/userUnavailablePoint", { param: [email] });

  let availPoint = 0;
  if (totalPoint.length > 0) {
    availPoint = (totalPoint[0].totPoint * 1) - (unavailPoint[0].unavilpoint * 1);

    //적립
    let sumA = totalPoint.filter((itme) => { return itme.useFlag === 'A' })
    sumA = (sumA.length > 0) ? sumA[0].total_A : 0;

    //사용
    let sumU = totalPoint.filter((itme) => { return itme.useFlag === 'U' })
    sumU = (sumU.length > 0) ? sumU[0].total_U : 0;

    //사용대기
    let sumS = totalPoint.filter((itme) => { return itme.useFlag === 'S' })
    sumS = (sumS.length > 0) ? sumS[0].total_S : 0;

    //적립대기
    let sumW = totalPoint.filter((itme) => { return itme.useFlag === 'W' })
    sumW = (sumW.length > 0) ? sumW[0].total_W : 0;
    //console.log(sumA, "/", sumU, "/", sumS, "/", sumW)

    //조회시점 가용포인트
    availPoint = (sumA * 1 + sumW * 1) - (sumU * 1 + sumS * 1);
  }
  else {
    availPoint = 0;
  }
  return availPoint;
}


export async function generateRandomString(api, rCode) {
  //인증번호생성(4자리)
  //let characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let characters = '0123456789';
  let length = 4;
  let randomString = ''
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  //인증번호 저장
  let ins_certiNumber = await api("/api/ins_certiNumber", { param: [randomString, rCode] });

  if (ins_certiNumber.affectedRows > 0) {
    return randomString;
  }
  else {
    alert("인증번호 생성에 오류가 발생했습니다. 재요청을 해주세요.")
    return false;
  }
  //console.log("ins_certiNumber:", ins_certiNumber)
  //return randomString;
}

export async function checkMember(api, uEmail) {
  //회원자격유지여부
  let check = await api("/api/checkMember", { param: [uEmail] });
  let checkRes = check[0].useFlag
  //console.log("checkMember:",checkRes)

  if (checkRes == 'N') {
    return 'N'
  }
  else if (checkRes == undefined || checkRes == 'Y') {
    return 'Y'
  }
}

export async function generateRandomCode(api, char, gNo) {
  let characters = char;
  let length = gNo;
  let randomString = ''
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}

////딤당자연락처정보
export async function handlerInfo(api) {
  let handTel = []
  handTel = await api("/api/handlerForAlim", {});

  return handTel
}

///파일삭제
export async function deleteFile(api, path, fileName){
  //console.log("commonFunc:>>>", path, "/", fileName)
  let retValue = "";

  try 
  {
    const response = await api("/api/deleteFile", { path, fileName }); // API 호출
    console.log("응답 데이터:", response);
    
    if (response.success)
    {
      console.log("CommonFunction 서버파일 삭제 성공");
      retValue = "OK";
    } 
    else 
    {
      console.log(`CommonFunction 서버파일 삭제 실패: ${response.data.error || 'Unknown error'}`);
      retValue = "Fail";
    }
  } 
  catch (error) 
  {
    console.error("CommonFunction Error during file deletion:", error); // 에러를 콘솔에 출력
    retValue = "Fail";
  }

  return retValue
}


//결제상세정보
// export async function getPayDetailInfo(api, orderId)
// {
//   let payDetailInfo = []
//   let payDetailInfo_tmp = await api("/api/payDetailInfo", {param: [orderId]});
//   if(payDetailInfo.length > 0)
//   {
//     payDetailInfo = payDetailInfo_tmp[0];
//   }
//   console.log("commpayDetailInfo:",payDetailInfo)
//   return payDetailInfo;
// }

